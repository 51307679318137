import {
  Box,
  Button,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Payment = () => {
  let location = useLocation();

  const [paymentLink] = useState("");
  const [paymentText] = useState("Enrol in Problem Solving Training");
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const urlQuery = new URLSearchParams(location.search);
    const query = urlQuery.get("student_number");
    if (query) {
      // createPaymentLink({
      //   variables: {
      //     student_number: query
      //   }
      // });
    }
  }, [location.search]); // Only depend on location.search
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box sx={{ margin: 0 }}>
        <Typography variant="h4">
          What is the SolverEdge Problem Solving Training?
        </Typography>
        <br />
        <Typography variant="body1">
          SolverEdge Problem Solving Training is an individualised intervention
          program which aims to improve problem solving ability.
        </Typography>
        <br />
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Button
            disabled={!paymentLink || disable}
            variant="contained"
            onClick={() => {
              setDisable(true);
              window.location.replace(paymentLink);
            }}
            sx={{ mb: 5 }}
          >
            {paymentText}
          </Button>
        </Stack>
        <Typography variant="body1">
          <strong>Benefits:</strong>
        </Typography>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Table sx={{ maxWidth: 800 }}>
            <TableRow sx={{ height: 100, overflow: "hidden" }}>
              <TableCell
                sx={{
                  width: "30%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                  height: 100,
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    alt="light bulb in a circle"
                    src="https://mcusercontent.com/e40dfd6504142d840325b4973/images/6e52f65e-cd8b-d35d-253e-c65cc4f55391.png"
                    style={{ maxWidth: 100 }}
                  />
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  width: "70%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                  height: 100,
                }}
              >
                <strong>Problem Solving Training</strong>
                <br />
                Access individualised videos to improve problem solving skills
              </TableCell>
            </TableRow>
            <TableRow sx={{ height: 100, overflow: "hidden" }}>
              <TableCell
                sx={{
                  width: "30%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                  height: 100,
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    alt="pc monitor with a checklist"
                    src="https://mcusercontent.com/e40dfd6504142d840325b4973/images/a8970a6d-df07-0b54-3b0e-82cd009e026f.png"
                    style={{ maxWidth: 100 }}
                  />
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  width: "70%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                }}
              >
                <strong>Practice and Assessment</strong>
                <br />
                Build confidence by completing training exercises
                <br />
                Complete an assessment to confirm mastery of topic
              </TableCell>
            </TableRow>
            <TableRow sx={{ height: 100, overflow: "hidden" }}>
              <TableCell
                sx={{
                  width: "30%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                  height: 100,
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    alt="magnifiying glass"
                    src="https://mcusercontent.com/e40dfd6504142d840325b4973/images/d759777a-d09d-6df8-b993-eccd017f94c5.png"
                    style={{ maxWidth: 100 }}
                  />
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  width: "70%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                }}
              >
                <strong>Review</strong>
                <br />
                Review incorrectly answered questions in Assessment
              </TableCell>
            </TableRow>
            <TableRow sx={{ height: 100, overflow: "hidden" }}>
              <TableCell
                sx={{
                  width: "30%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                  height: 100,
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    alt="graph going up"
                    src="https://mcusercontent.com/e40dfd6504142d840325b4973/images/3fe37422-7675-9ce3-557e-9a537096b361.png"
                    style={{ maxWidth: 100 }}
                  />
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  width: "70%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                }}
              >
                <strong>Feedback</strong>
                <br />
                Instant feedback on progress by topic
              </TableCell>
            </TableRow>
            <TableRow sx={{ height: 100, overflow: "hidden" }}>
              <TableCell
                sx={{
                  width: "30%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                  height: 100,
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    alt="cusotmer service icon"
                    src="https://mcusercontent.com/e40dfd6504142d840325b4973/images/0cd9ffa3-e885-dbbb-6984-45d40609f9ff.png"
                    style={{ maxWidth: 100 }}
                  />
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  width: "70%",
                  padding: 1,
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 1,
                }}
              >
                <strong>Free Online Help</strong>
                <br />
                Contact tutor for 1-on-1 assistance at:
                <br />
                Thursday: 4pm - 6pm AEDT
                <br />
                Friday: 4pm - 6pm AEDT
                <br />
                Saturday: 2pm - 5pm AEDT
              </TableCell>
            </TableRow>
          </Table>
        </Stack>
        <br />
        <Typography variant="body1">
          <strong>Cost:</strong>
        </Typography>
        <br />
        <Typography variant="body1">
          $90 per quarter. This includes a 3 month subscription to our Problem
          Solving training. This starts one day after we receive your payment
          and expires 3 months later.
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>Terms and Conditions:</strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Click{" "}
          <a
            href={"https://www.north-shore.com.au/faqs/"}
            target={"_blank"}
            rel="noreferrer"
          >
            here
          </a>{" "}
          and scroll to "What are your Terms of Payment for the Problem Solving
          Training?".
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>More questions:</strong>
        </Typography>
        <br />
        <Typography variant="body1">
          Should you have more questions, email us at
          problemsolving@north-shore.com.au
        </Typography>
        <br />
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Button
            disabled={!paymentLink || disable}
            variant="contained"
            onClick={() => {
              setDisable(true);
              window.location.replace(paymentLink);
            }}
            sx={{ mb: 5 }}
          >
            {paymentText}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Payment;
