import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  OutlinedInput,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GET_MODULE_DETAILS } from "../graphql/queries";
import { UPDATE_MODULE } from "../graphql/mutations";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import { AuthenticatedContext } from "../App";

const ModuleUpdateDetails = ({ moduleId }) => {
  const { userRole } = useContext(AuthenticatedContext);
  const { enqueueSnackbar } = useSnackbar();
  const [module, setModule] = useState({});
  const schema = yup
    .object({
      moduleName: yup.string(),
      isActive: yup.bool(),
    })
    .required();
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      moduleName: "",
      isActive: false,
    },
  });

  const location = useLocation();

  const [getModuleDetails] = useLazyQuery(GET_MODULE_DETAILS, {
    onCompleted: (returnModuleDetails) => {
      setValue("moduleName", returnModuleDetails.getModuleDetails.name);
      setValue("isActive", returnModuleDetails.getModuleDetails.isActive);
      setModule(returnModuleDetails.getModuleDetails);
    },
    fetchPolicy: "no-cache",
  });

  const [updateModule] = useMutation(UPDATE_MODULE, {
    onCompleted: ({ updateModule }) => {
      if (updateModule) {
        enqueueSnackbar(`Module successfully updated`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          `There was a problem with updating the module, please try again.`,
          {
            variant: "warning",
          }
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
    fetchPolicy: "network-only",
  });
  const re = useMemo(() => new RegExp("testmanagement"), []);

  useEffect(() => {
    if (re.test(location.pathname)) {
      getModuleDetails({
        variables: { module_id: moduleId, module_type: "Test" },
      });
    } else {
      getModuleDetails({
        variables: { module_id: moduleId, module_type: "Exercise" },
      });
    }
  }, [location.pathname, moduleId, re]);

  const onSubmit = ({ moduleName, isActive }) => {
    updateModule({
      variables: {
        ...(re.test(location.pathname)
          ? { module_type: "Test" }
          : { module_type: "Exercise" }),
        name: moduleName,
        module_id: moduleId,
        is_active: isActive,
      },
    });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel>Name</InputLabel>
          <Controller
            name="moduleName"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.moduleName ? true : false}
                disabled={!["Admin", "Education Consultant"].includes(userRole)}
              >
                <OutlinedInput {...field} />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.name?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        {module.duration && (
          <Grid item xs={4}>
            <InputLabel>Duration</InputLabel>
            <TextField value={module.duration || 0} disabled fullWidth />
          </Grid>
        )}
        <Grid item xs={4}>
          <InputLabel>Total Questions</InputLabel>
          <TextField value={module.totalQuestions || 0} disabled fullWidth />
        </Grid>
        {module.firstGroupQuestions && (
          <Grid item xs={4}>
            <InputLabel>Questions in 1st Group</InputLabel>
            <TextField value={module.firstGroupQuestions || 0} disabled fullWidth />
          </Grid>
        )}
        {module.Test?.totalQuestions && (
          <Grid item xs={4}>
            <InputLabel>Total Questions (Assessment)</InputLabel>
            <TextField value={module.Test.totalQuestions || 0} disabled fullWidth />
          </Grid>
        )}
        {module.isAdaptive && (
          <Grid item xs={4}>
            <InputLabel>Is Adaptive?</InputLabel>
            <Checkbox value={module.isAdaptive || 0} disabled fullWidth />
          </Grid>
        )}
        {/* { re.test(location.pathname) && (
          <>
            <Grid
              item
              xs={4}
            >
              <Controller
                name="availableFrom"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    required
                    error={errors.availableFrom ? true : false}
                  >
                    <DateTimePicker 
                      renderInput={(params) => <TextField {...params} />} 
                      label="Available From"
                      inputFormat="dd/MM/yyyy hh:mm"
                      maxDateTime={watch("availableTo") || moment().add(1, "year")}
                      minDateTime={moment().subtract(1, "year")}
                      disabled={!["Admin", "Education Consultant"].includes(userRole)}
                      {...field}
                    />
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.availableFrom?.message}
                    </FormHelperText>
                  </FormControl>
                )} />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <Controller
                name="availableTo"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    required
                    error={errors.availableTo ? true : false}
                  >
                    <DateTimePicker 
                      renderInput={(params) => <TextField {...params} />}
                      label="Available To"
                      inputFormat="dd/MM/yyyy hh:mm"
                      disabled={!["Admin", "Education Consultant"].includes(userRole)}  
                      minDateTime={watch("availableFrom") || moment().subtract(1, "year")}
                      maxDateTime={moment().add(1, "year")}
                      {...field}
                    />
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.availableTo?.message}
                    </FormHelperText>
                  </FormControl>
                )} />
            </Grid>
          </>
        )} */}
        <Grid item xs={4}>
          <InputLabel>Created At</InputLabel>
          <DateTimePicker
            inputFormat="dd/MM/yyyy hh:mm"
            value={module.createdAt}
            onChange={(event) => {}}
            renderInput={(params) => <TextField {...params} fullWidth />}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Updated At</InputLabel>
          <DateTimePicker
            inputFormat="dd/MM/yyyy hh:mm"
            value={module.updatedAt}
            onChange={(event) => {}}
            renderInput={(params) => <TextField {...params} fullWidth />}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          {!re.test(location.pathname) && (
            <>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    required
                    error={errors.isActive ? true : false}
                  >
                    <FormControlLabel
                      {...field}
                      label="Active?"
                      labelPlacement="start"
                      control={
                        <Switch
                          {...field}
                          checked={watch("isActive")}
                          disabled={
                            !["Admin", "Education Consultant"].includes(
                              userRole
                            )
                          }
                        />
                      }
                    />
                  </FormControl>
                )}
              />
            </>
          )}
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4}>
          {["Admin", "Education Consultant"].includes(userRole) && (
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModuleUpdateDetails;
