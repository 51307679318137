import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { GET_REPORT_STUDENTS } from "../graphql/queries";
import { CREATE_NOTIFICATIONS, CREATE_SUMMARY_REPORT } from "../graphql/mutations";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TestReport from "./TestReport";
import { useSnackbar } from "notistack";
// import PlacementReport from "./PlacementReport";

const ReportStudents = ({ test_id, program_id }) => {
  const { preferences, setPreferences } = React.useContext(PreferenceContext);
  const { userRole } = React.useContext(AuthenticatedContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [reportDialog, setReportDialog] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(false);
  const [selectedRow] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [numTests, setNumTests] = useState(0);
  const [createDialog, setCreateDialog] = useState(false);

  const stuPref = { ...preferences.reportStudent };
  const [pageSize, setPageSize] = useState(stuPref.pageSize || 10);
  // const stuPref = useMemo(() => {
  //   return { ...preferences.reportStudent };
  // }, [preferences.reportStudent]); // Dependency array

  // useEffect(() => {
  //   console.log("stuPref changed:", stuPref);
  // }, []);

  const [getReportStudents, { loading: studentsLoading }] = useLazyQuery(
    GET_REPORT_STUDENTS,
    {
      onCompleted: ({ getReportStudents }) => {
        if (getReportStudents.students.length > 0) {
          setRows(getReportStudents.students);
        }
        if (getReportStudents.numTests) {
          setNumTests(getReportStudents.numTests);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      // notifyOnNetworkStatusChange: true
    }
  );

  const [createNotifications] = useMutation(CREATE_NOTIFICATIONS, {
    onCompleted: (notifs) => {
      setSelectedRows([]);
      if (notifs.createNotifications > 0) {
        if (test_id) {
          getReportStudents({
            variables: {
              test_id,
            },
          });
        } else if (program_id) {
          getReportStudents({
            variables: {
              program_id,
            },
          });
        }
      }
    },
    onError: (error) => {
      console.log(`create notification error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [createSummaryReport] = useMutation(CREATE_SUMMARY_REPORT, {
    onCompleted: ({ createSummaryReport }) => {
      if (createSummaryReport) {
        enqueueSnackbar(
          `Reports successfully generated.`,
          {
            variant: "success",
          }
        );
        if (test_id) {
          getReportStudents({
            variables: {
              test_id,
            },
          });
        } else if (program_id) {
          getReportStudents({
            variables: {
              program_id,
            },
          });
        }
      }
    },
    onError: (error) => {
      console.log(`create notification error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const callReportStudents = useCallback(() => {
    if (test_id) {
      getReportStudents({
        variables: {
          test_id,
        },
      });
    } else if (program_id) {
      getReportStudents({
        variables: {
          program_id,
        },
      });
    }
  }, []);

  useEffect(() => {
    callReportStudents();
  }, [program_id, test_id]);

  useEffect(() => {
    if (rows.length > 0) {
      if (!!rows[0].count) {
        stuPref.visible.count = true;
      } else {
        stuPref.visible.count = false;
      }
      setPreferences({
        ...preferences,
        reportStudent: {
          ...stuPref,
        },
      });
    }
  }, [rows]);

  const handleColumnWidthChange = (params) => {
    stuPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      reportStudent: {
        ...stuPref,
      },
    });
  };

  const handleColumnOrderChange = (params) => {
    stuPref.columns.splice(params.oldIndex, 1);
    stuPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      reportStudent: {
        ...stuPref,
      },
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    stuPref.visible = { ...model };
    setPreferences({
      ...preferences,
      reportStudent: {
        ...stuPref,
      },
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    stuPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      reportStudent: {
        ...stuPref,
      },
    });
  };

  function getStudentName(params) {
    return params.row.Student.givenname + " " + params.row.Student.surname;
  }

  function getStudentNumber(params) {
    return params.row.Student.login;
  }

  function getIsSent(params) {
    return params.row.Student.Notifications?.length > 0;
  }

  function getIsRead(params) {
    return params.row.Student.Notifications?.length > 0
      ? params.row.Student.Notifications[0]?.isRead
        ? true
        : false
      : null;
  }

  function getCreatedAt(params) {
    return params.row.Student.Notifications?.length > 0
      ? params.row.Student.Notifications[0]?.createdAt
      : null;
  }

  function getCampus(params) {
    return params.row.Student.Centre?.name;
  }

  function getIsGenerated(params) {
    if (params.row.Student.Results?.length === 0) return false;
    return (
      [...new Set(params.row.Student.Results.map((r) => r.psType))].length === 6
    );
  }

  function getIsRecommended(params) {
    return params.row.Student.Trainings?.length > 0;
  }

  const isDisabled = useCallback(
    (params) => {
      if (test_id) return false;
      return (
        params.row.count !== numTests ||
        [...new Set(params.row.Student.Results.map((r) => r.psType))].length !==
          6
      );
    },
    [test_id, numTests]
  ); // Add dependencies here

  function getTestCount(params) {
    return params.row.count;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const viewReport = useCallback(
    (params) => {
      // setSelectedRow(params.row);
      // setReportDialog(true);
      history.push(`/report/${program_id}/${params.row.Student?.id}`);
    },
    [history, program_id] // Dependencies
  );

  const sendNotifications = () => {
    createNotifications({
      variables: {
        ...(test_id ? { test_id } : {}),
        ...(program_id ? { program_id } : {}),
        ...(selectedRows.length > 0
          ? { send_all: false, student_ids: selectedRows }
          : { send_all: true }),
      },
    });
    setNotifyDialog(false);
  };

  const createReports = () => {
    createSummaryReport({
      variables: {
        program_id
      }
    });
    setCreateDialog(false);
  };

  const columns = useMemo(() => {
    let options = [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: stuPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<PictureAsPdfIcon />}
            label="View Report"
            color="primary"
            onClick={() => viewReport(params)}
            disabled={isDisabled(params)}
          />,
        ],
      },
      {
        field: "studentNumber",
        headerName: "Student Number",
        width: stuPref?.widths?.studentNumber,
        headerAlign: "center",
        align: "center",
        hide: !stuPref?.visible?.studentNumber,
        getApplyQuickFilterFn: undefined,
        valueGetter: getStudentNumber,
      },
      {
        field: "studentName",
        headerName: "Student Name",
        width: stuPref?.widths?.studentName,
        headerAlign: "center",
        align: "center",
        hide: !stuPref?.visible?.studentName,
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getStudentName,
      },
      {
        field: "campus",
        headerName: "Campus",
        width: stuPref?.widths?.campus,
        headerAlign: "center",
        align: "center",
        hide: !stuPref?.visible?.campus,
        getApplyQuickFilterFn: undefined,
        valueGetter: getCampus,
      },
    ];
    if (program_id) {
      options.push(
        {
          field: "countTest",
          headerName: "Completed Tests",
          width: stuPref?.widths?.countTest,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.countTest,
          getApplyQuickFilterFn: undefined,
          type: "number",
          valueGetter: getTestCount,
        },
        {
          field: "reports",
          headerName: "Results Generated?",
          width: stuPref?.widths?.reports,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.reports,
          getApplyQuickFilterFn: undefined,
          valueGetter: getIsGenerated,
          type: "boolean",
        },
        {
          field: "trainings",
          headerName: "Trainings Recommended?",
          width: stuPref?.widths?.trainings,
          headerAlign: "center",
          align: "center",
          hide: !stuPref?.visible?.trainings,
          getApplyQuickFilterFn: undefined,
          valueGetter: getIsRecommended,
          type: "boolean",
        }
      );
    }
    options.push(
      {
        field: "isSent",
        headerName: "Notification Sent",
        width: stuPref?.widths?.isSent,
        headerAlign: "center",
        align: "center",
        hide: !stuPref?.visible?.isSent,
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsSent,
        type: "boolean",
      },
      {
        field: "isRead",
        headerName: "Notification Read",
        width: stuPref?.widths?.isRead,
        headerAlign: "center",
        align: "center",
        hide: !stuPref?.visible?.isRead,
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsRead,
        type: "boolean",
      },
      {
        field: "createdAt",
        headerName: "Notification Created At",
        width: stuPref?.widths?.createdAt,
        headerAlign: "center",
        hide: !stuPref?.visible?.createdAt,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        valueGetter: getCreatedAt,
        align: "center",
      }
    );
    return options;
  }, [
    // stuPref?.widths?.actions,
    // stuPref?.widths?.studentNumber,
    // stuPref?.widths?.studentName,
    // stuPref?.widths?.campus,
    // stuPref?.widths?.isSent,
    // stuPref?.widths?.isRead,
    // stuPref?.widths?.createdAt,
    // stuPref?.widths?.countTest,
    // stuPref?.widths?.reports,
    // stuPref?.widths?.trainings,
    // stuPref?.visible?.studentNumber,
    // stuPref?.visible?.studentName,
    // stuPref?.visible?.campus,
    // stuPref?.visible?.isSent,
    // stuPref?.visible?.isRead,
    // stuPref?.visible?.createdAt,
    // stuPref?.visible?.countTest,
    // stuPref?.visible?.reports,
    // stuPref?.visible?.trainings,
    program_id,
    isDisabled,
    viewReport,
  ]);
  columns.sort(
    (a, b) =>
      stuPref?.columns?.indexOf(a.field) - stuPref?.columns?.indexOf(b.field)
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={notifyDialog}>
        <DialogTitle>Send Notifications</DialogTitle>
        <DialogContent>
          Are you sure you want to send notifications to{" "}
          {selectedRows.length > 0 ? selectedRows.length : "ALL"} students?
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => setNotifyDialog(false)}>Cancel</Button>
          <Button onClick={() => sendNotifications()}>
            Send Notifications
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={createDialog}>
        <DialogTitle>Create Reports</DialogTitle>
        <DialogContent>
          Any student who has not opened their report will have their results re-generated. Proceed?<br />
          After clicking 'Create Reports', results may not be generated for up to 15 minutes.
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => setCreateDialog(false)}>Cancel</Button>
          <Button onClick={() => createReports()}>
            Create Reports
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={reportDialog} fullScreen>
        {rows.length > 0 && !!rows[0].count ? (
          // <PlacementReport openFn={setReportDialog} program_id={program_id} student_id={selectedRow.Student?.id} student_name={selectedRow.Student?.givenname + " " + selectedRow.Student?.surname} student_number={selectedRow.Student?.login} />
          <Typography>Hi</Typography>
        ) : (
          <TestReport
            openFn={setReportDialog}
            test_id={test_id}
            student_id={selectedRow.Student?.id}
            student_name={
              selectedRow.Student?.givenname +
              " " +
              selectedRow.Student?.surname
            }
          />
        )}
      </Dialog>
      <Paper elevation={0} sx={{ p: 3 }}>
        {!["Teacher", "Student"].includes(userRole) && (
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box />
            <Button
              color="primary"
              gutterBottom
              variant="contained"
              sx={{ mb: 0, margin: "0 12px 0 0", cursor: "pointer" }}
              onClick={() => setCreateDialog(true)}
            >
              Generate Reports
            </Button>
            <Button
              color="primary"
              gutterBottom
              variant="contained"
              sx={{ mb: 0, cursor: "pointer" }}
              onClick={() => setNotifyDialog(true)}
            >
              Send Reports
            </Button>
          </Box>
        )}
        <DataGridPro
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={studentsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{ left: ["actions"] }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          getRowId={(row) => row.Student.id}
          checkboxSelection={true}
          onSelectionModelChange={(newSelectedRows) => {
            setSelectedRows(newSelectedRows);
          }}
          selectionModel={selectedRows}
          isRowSelectable={(params) =>
            params.row.Student.Trainings?.length > 0 &&
            params.row.Student.Notifications?.length === 0
          }
        />
      </Paper>
    </Container>
  );
};

export default ReportStudents;
