import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Typography } from "@mui/material";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ModuleContext } from "../../App";

const DragDropIntoBoxType = ({
  currentQuestion: {
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution,
  },
}) => {
  const [items, setItems] = useState({});
  const moduleValue = React.useContext(ModuleContext);

  useEffect(() => {
    let items = [];
    answer1 && items.push({ name: answer1, id: "1" });
    answer2 && items.push({ name: answer2, id: "2" });
    answer3 && items.push({ name: answer3, id: "3" });
    answer4 && items.push({ name: answer4, id: "4" });
    answer5 && items.push({ name: answer5, id: "5" });
    answer6 && items.push({ name: answer6, id: "6" });
    answer7 && items.push({ name: answer7, id: "7" });
    answer8 && items.push({ name: answer8, id: "8" });
    answer9 && items.push({ name: answer9, id: "9" });
    answer10 && items.push({ name: answer10, id: "10" });
    let selected = [];
    if (moduleValue.moduleSettings.selectedAnswer) {
      let index = items.indexOf(
        (i) => i.id === moduleValue.moduleSettings.selectedAnswer
      );
      selected.push(items[index]);
      items.splice(index, 1);
    }
    setItems({ items, selected: [] });
  }, [
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution,
    moduleValue.moduleSettings.selectedAnswer,
  ]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    // const [destRemoved] = destClone.splice(
    //   droppableDestination.index,
    //   0,
    //   removed
    // );
    if (destClone.length > 0) {
      const destRemoved = destClone.pop();
      destClone.push(removed);
      sourceClone.splice(droppableSource.index, 0, destRemoved);
    } else {
      destClone.push(removed);
    }
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 3,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",
    color: "black",
    fontSize: `${grid * 3}px`,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver, isEmpty) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    display: "flex",
    padding: grid,
    minHeight: isEmpty ? `${grid * 13}px` : "NaN",
    overflow: "auto",
  });

  const id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  const getList = (id) => items[id2List[id]];

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const lists = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items: lists };

      if (source.droppableId === "droppable2") {
        state = { selected: lists };
      }

      setItems({ ...items, ...state });
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      // console.log(result);
      // if (result.droppable) {
      //   setItems({ items: result.droppable, selected: result.droppable2 });
      // }
      moduleValue.setModuleSettings({
        ...moduleValue.moduleSettings,
        selectedAnswer: result.droppable2[0].id,
      });

      setItems({ items: result.droppable, selected: result.droppable2 });
    }
  };

  const getCorrectAnswer = (correctAns) => {
    switch (parseInt(correctAns)) {
      case 1:
        return answer1 ? parse(answer1) : "";
      case 2:
        return answer2 ? parse(answer2) : "";
      case 3:
        return answer3 ? parse(answer3) : "";
      case 4:
        return answer4 ? parse(answer4) : "";
      case 5:
        return answer5 ? parse(answer5) : "";
      case 6:
        return answer6 ? parse(answer6) : "";
      case 7:
        return answer7 ? parse(answer7) : "";
      case 8:
        return answer8 ? parse(answer8) : "";
      case 9:
        return answer9 ? parse(answer9) : "";
      case 10:
        return answer10 ? parse(answer10) : "";
      default:
        return null;
    }
  };

  return (
    <Box sx={{ pb: 2 }}>
      {src && <Typography variant="h6">{parse(src)}</Typography>}
      {content && <Typography variant="h6">{parse(content)}</Typography>}

      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items?.items?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
            Drag the right answer into bottom box
          </Typography>
          <Droppable droppableId="droppable2" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver, true)}
              >
                {items?.selected?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {correctAns && (
        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
          <strong>Correct answer:</strong> {getCorrectAnswer(correctAns)}
        </Alert>
      )}

      {hint && (
        <Alert
          severity="info"
          iconMapping={{
            info: <WbIncandescentIcon fontSize="inherit" />,
          }}
          sx={{ mt: 2 }}
        >
          <strong>
            Hint: <br></br>
          </strong>
          {parse(hint)}
        </Alert>
      )}
      {solution && (
        <Alert
          severity="warning"
          iconMapping={{
            warning: <RadioButtonCheckedIcon fontSize="inherit" />,
          }}
          sx={{ mt: 2 }}
        >
          <strong>
            Solution: <br></br>
          </strong>
          {parse(solution)}
        </Alert>
      )}
    </Box>
  );
};

export default DragDropIntoBoxType;
