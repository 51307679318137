import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import { Split } from "@geoffcox/react-splitter";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const MultipleTrueOrFalseType = ({
  currentQuestion: {
    Question,
    Answer1,
    Answer2,
    Answer3,
    Answer4,
    Answer5,
    Answer6,
    Answer7,
    Answer8,
    Answer9,
    Answer10,
    CorrectAnswer,
    Hint,
    VideoSrc,
    PassageSrc,
    Solution,
  },
}) => {
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const [chosenAnswers, setchosenAnswers] = useState({
    Answer1: null,
    Answer2: null,
    Answer3: null,
    Answer4: null,
    Answer5: null,
    Answer6: null,
    Answer7: null,
    Answer8: null,
    Answer9: null,
    Answer10: null,
  });

  const handleRadioChange = ({ target }) => {
    setchosenAnswers({ ...chosenAnswers, [target.name]: target.value });
  };

  //Get answers value then compares to CorrectAnswer
  // console.log(
  //   Object.values(chosenAnswers).filter(Boolean).toString()===CorrectAnswer
  // );

  // const [Answers, setAnswers] = useState([]);

  // useEffect(() => {
  //   let Answers = [];
  //   Answer1 && Answers.push(Answer1);
  //   Answer2 && Answers.push(Answer2);
  //   Answer3 && Answers.push(Answer3);
  //   Answer4 && Answers.push(Answer4);
  //   Answer5 && Answers.push(Answer5);
  //   Answer6 && Answers.push(Answer6);
  //   Answer7 && Answers.push(Answer7);
  //   Answer8 && Answers.push(Answer8);
  //   Answer9 && Answers.push(Answer9);
  //   Answer10 && Answers.push(Answer10);
  //   setAnswers([...Answers]);
  // }, []);

  return (
    <Split
      minPrimarySize="15%"
      minSecondarySize="15%"
      initialPrimarySize="50%"
      resetOnDoubleClick
      splitterSize="48px"
      defaultSplitterColors={splitterColors}
    >
      <Box>
        {VideoSrc && (
          <>
            <ReactPlayer
              url={VideoSrc}
              controls={true}
              width="95%"
              height="95%"
            />
            <ReactAudioPlayer src={VideoSrc} controls />
          </>
        )}
        {PassageSrc && (
          <Typography variant="h6">{parse(PassageSrc)}</Typography>
        )}
        {!PassageSrc && !VideoSrc && Question && (
          <Typography variant="h6">{parse(Question)}</Typography>
        )}
      </Box>
      <Box sx={{ pb: 2 }}>
        {(PassageSrc || VideoSrc) && Question && (
          <Typography variant="h6">{parse(Question)}</Typography>
        )}
        {Answer1 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer1)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer1"
                      value={chosenAnswers.Answer1}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer2 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer2)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer2"
                      value={chosenAnswers.Answer2}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer3 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer3)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer3"
                      value={chosenAnswers.Answer3}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer4 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer4)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer4"
                      value={chosenAnswers.Answer4}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer5 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer5)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer5"
                      value={chosenAnswers.Answer5}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer6 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer6)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer6"
                      value={chosenAnswers.Answer6}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer7 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer7)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer7"
                      value={chosenAnswers.Answer7}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer8 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer8)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer8"
                      value={chosenAnswers.Answer8}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer9 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer9)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer9"
                      value={chosenAnswers.Answer9}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}
        {Answer10 && (
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={4}>
              <>
                <Grid item xs={5}>
                  <Typography variant="h6">{parse(Answer10)}</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      name="Answer10"
                      value={chosenAnswers.Answer10}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">True</Typography>}
                        sx={{ mr: 8 }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio size="large" />}
                        label={<Typography variant="h6">False</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
          </Box>
        )}

        {CorrectAnswer && (
          <Alert severity="success" sx={{ mt: 3 }}>
            <div style={{ marginBottom: "8px" }}>
              <strong>Correct answer:</strong>
            </div>
            {parse(CorrectAnswer)}
          </Alert>
        )}
        {Hint && (
          <Alert
            severity="info"
            iconMapping={{
              info: <WbIncandescentIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Hint: <br></br>
            </strong>
            {parse(Hint)}
          </Alert>
        )}
        {Solution && (
          <Alert
            severity="warning"
            iconMapping={{
              warning: <RadioButtonCheckedIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Solution: <br></br>
            </strong>
            {parse(Solution)}
          </Alert>
        )}
      </Box>
    </Split>
  );
};

export default MultipleTrueOrFalseType;
