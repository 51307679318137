import { gql } from "@apollo/client";

const GET_DASHBOARD = gql`
  query getDashboard($user_id: Int!, $role: String!) {
    getDashboard(user_id: $user_id, role: $role) {
      placements {
        id
        name
        duration
        isActive
        totalQuestions
        mean
        variance
        rules
        outcome
        isAdaptive
        isDiagnostic
        Sittings {
          id
          isCompleted
          upTo
        }
        DiagSittings {
          id
          isCompleted
        }
        Programs {
          Program {
            id
            name
          }
        }
      }
      assessments {
        id
        name
        duration
        isActive
        totalQuestions
        isAdaptive
        dueDate
        Sittings {
          id
          isCompleted
          upTo
          completedDate
        }
        order
      }
      trainings {
        order
        id
        name
        assessmentId
        totalQuestions
        firstGroupQuestions
        timeOfFirstGroupQuestions
        totalCorrectScoreSectionOne
        totalCorrectScoreSectionTwo
        isActive
        rules
        outcome
        createdAt
        updatedAt
        dueDate
        isInteractive
        TrainingSittings {
          id
          isCompleted
          isRedo
          upTo
          completedDate
        }
        Medias {
          trainingId
          mediaId
          Media {
            id
            source
          }
        }
      }
      totalMedals
      redeemableMedals
      programs {
        id
        hasDiagnostic
        name
        DiagnosticSittings {
          id
          isCompleted
          completedDate
        }
      }
    }
  }
`;

const GET_SITTING = gql`
  query getSitting($module_id: Int, $module_type: String!, $student_id: Int, $program_id: Int) {
    getSitting(module_id: $module_id, module_type: $module_type, student_id: $student_id, program_id: $program_id) {
      testSitting {
        id
        studentId
        testId
        upTo
        timeLeft
        createdAt
      }
      trainingSitting {
        id
        studentId
        trainingId
        upTo
        isCompleted
        isRedo
        scoreSection1
        timeUsedSection1
        scoreSection2
        timeUsedSection2
        logoutTime
        totalLogoutTime
        completedDate
        currentSection
        createdAt
        updatedAt
        partOneAllCorrect
        sectionOnePass
        sectionTwoPass
      }
      questions {
        testId
        trainingId
        id
        difficulty
        strand
        passageId
        originalQuestionId
        nodeId
        order
        lowest
        # answer1
        # answer2
        # answer3
        # answer4
        # answer5
        # answer6
        # answer7
        # answer8
        # answer9
        # answer10
        # hint
        # solution
        # correctAns
        # content
        # questionType
      }
      currentQuestion {
        id
        correctAns
        difficulty
        content
        answer1
        answer2
        answer3
        answer4
        answer5
        answer6
        answer7
        answer8
        answer9
        answer10
        hint
        # solution
        passageId
        questionType
        strand
        nodeId
        originalQuestionId
        attempts
        order
        lowest
      }
      testAnswers {
        id
        studentTestSittingId
        questionId
        questionNumber
        isFlagged
        reviewingTime
        workingTime
        chosen
        attempt
        isCorrect
      }
      trainingAnswers {
        id
        studentTrainingSittingId
        questionId
        questionNumber
        isFlagged
        reviewingTime
        workingTime
        chosen
        isCorrect
        isResultShown
        readOnly
      }
      route {
        id
        strand
        passageId
        lowest
      }
      strands {
        id
        name
        numQuestions
      }
      zetas {
        strand
        a
        b
        c
      }
      marks {
        strand
        mark
      }
      numStart
      reviews {
        answerId
        sittingId
        questionId
        chosen
        attempt
        correctAns
        buttonNumber
        isCorrect
        section
        sectionNumber
      }
      passages {
        passageId
        numQuestions
        difficulty
      }
      diagTree {
        id
        originalQuestionId
        diagnosticQuestionId
        node
        parent
        isCorrect
      }
      diagSitting {
        id
        studentId
        testId
        upTo
        timeUsed
        createdAt
        route
        correctIds
      }
      diagAnswers {
        id
        studentDiagnosticSittingId
        diagnosticQuestionId
        questionNumber
        workingTime
        chosen
        written
        isMarked
        markedBy
      }
      lowest {
        id
        name
        strand
      }
      maxOrder
      numSections
    }
  }
`;

const GET_FORMATTED_PASSAGE = gql`
  query getFormattedPassage($passage_id: Int!) {
    getFormattedPassage(passage_id: $passage_id)
  }
`;

const GET_FORMATTED_QUESTION = gql`
  query getFormattedQuestion($question_id: Int!, $is_review: Boolean!, $test_id: Int, $is_adaptive: Boolean) {
    getFormattedQuestion(question_id: $question_id, is_review: $is_review, test_id: $test_id, is_adaptive: $is_adaptive) {
      id
      difficulty
      content
      answer1
      answer2
      answer3
      answer4
      answer5
      answer6
      answer7
      answer8
      answer9
      answer10
      passageId
      questionType
      strand
      hint
      # solution
      correctAns
      order
      lowest
      originalQuestionId
    }
  }
`;

const GET_SITTINGS = gql`
  query getSittings(
    $module_id: Int!
    $module_type: String!
    $skip: Int
    $take: Int
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getSittings(
      module_id: $module_id
      module_type: $module_type
      skip: $skip
      take: $take
      filters: $filters
      sort: $sort
    ) {
      sittings {
        id
        studentId
        givenname
        surname
        upTo
        isCompleted
        createdAt
        completedDate
        totalQuestions
        campus
        studentNumber
      }
      total
    }
  }
`;

const GET_STUDENTS = gql`
  query getStudents(
    $skip: Int!
    $take: Int!
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getStudents(skip: $skip, take: $take, filters: $filters, sort: $sort) {
      students {
        id
        gradeId
        surname
        givenname
        isActivated
        createdAt
        login
        campus
      }
      total
    }
  }
`;

const GET_ALL_TESTS = gql`
  query getAllTests(
    $skip: Int!
    $take: Int!
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getAllTests(skip: $skip, take: $take, filters: $filters, sort: $sort) {
      tests {
        id
        name
        isActive
        totalQuestions
        moduleTypeId
        isAdaptive
        createdAt
        updatedAt
        availableFrom
        availableTo
        isDiagnostic
      }
      total
    }
  }
`;

const GET_REGISTERED_STUDENTS = gql`
  query getRegisteredStudents(
    $module_id: Int!
    $module_type: String!
    $skip: Int!
    $take: Int!
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getRegisteredStudents(
      module_id: $module_id
      module_type: $module_type
      skip: $skip
      take: $take
      filters: $filters
      sort: $sort
    ) {
      students {
        id
        login
        gradeId
        surname
        givenname
        isCompleted
        createdAt
        campus
        registrationId
      }
      total
    }
  }
`;

const GET_GRADES = gql`
  query getGrades {
    getGrades {
      id
      name
    }
  }
`;

const GET_MY_STAFF_DETAILS = gql`
  query getMyStaffDetails {
    getMyStaffDetails {
      staffLogin
      staffGivenname
      staffSurname
    }
  }
`;

const GET_STUDENT_DETAILS = gql`
  query getStudentDetails($id: Int!) {
    getStudentDetails(id: $id) {
      login
      givenname
      surname
      phone
      email
    }
  }
`;

const GET_REGISTERED_TESTS = gql`
  query getRegisteredTests(
    $student_id: Int!
    $skip: Int!
    $take: Int!
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getRegisteredTests(
      student_id: $student_id
      skip: $skip
      take: $take
      filters: $filters
      sort: $sort
    ) {
      tests {
        id
        name
        isCompleted
        createdAt
      }
      total
    }
  }
`;

const GET_UNREGISTERED_PLACEMENTS = gql`
  query getUnregisteredPlacements($student_id: Int!) {
    getUnregisteredPlacements(student_id: $student_id) {
      id
      name
    }
  }
`;

const GET_FORMATTED_DIAG_QUESTION = gql`
  query getFormattedDiagQuestion($question_id: Int!) {
    getFormattedDiagQuestion(question_id: $question_id) {
      id
      content
      answer1
      answer2
      answer3
      answer4
      answer5
      answer6
      answer7
      answer8
      answer9
      answer10
      passageId
      questionType
      nodeId
      originalQuestionId
      attempts
      order
    }
  }
`;

const GET_ALL_TRAININGS = gql`
  query getAllTrainings(
    $skip: Int!
    $take: Int!
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getAllTrainings(skip: $skip, take: $take, filters: $filters, sort: $sort) {
      trainings {
        id
        name
        assessmentId
        totalQuestions
        firstGroupQuestions
        timeOfFirstGroupQuestions
        totalCorrectScoreSectionOne
        totalCorrectScoreSectionTwo
        isActive
        createdAt
        updatedAt
        numEnrolled
        numActive
      }
      total
    }
  }
`;

const GET_ALL_TRAINING_NAMES = gql`
  query getAllTrainingNames($course_id: Int) {
    getAllTrainingNames(course_id: $course_id) {
      id
      name
    }
  }
`;

const GET_MODULE_DETAILS = gql`
  query getModuleDetails($module_id: Int!, $module_type: String!) {
    getModuleDetails(module_id: $module_id, module_type: $module_type) {
      id
      name
      duration
      isActive
      moduleTypeId
      totalQuestions
      isAdaptive
      availableFrom
      availableTo
      createdAt
      updatedAt
      firstGroupQuestions
      Test {
        totalQuestions
      }
    }
  }
`;

const GET_REGISTERED_TRAININGS = gql`
  query getRegisteredTrainings(
    $student_id: Int!
    $skip: Int!
    $take: Int!
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getRegisteredTrainings(
      student_id: $student_id
      skip: $skip
      take: $take
      filters: $filters
      sort: $sort
    ) {
      trainings {
        id
        name
        isCompleted
        createdAt
        courseName
      }
      total
    }
  }
`;

const GET_UNREGISTERED_TRAININGS = gql`
  query getUnregisteredTrainings($student_id: Int!) {
    getUnregisteredTrainings(student_id: $student_id) {
      id
      name
    }
  }
`;

const GET_REPORTS = gql`
  query getReports($skip: Int, $take: Int, $filters: FilterInput, $sort: [SortItem]) {
    getReports(skip: $skip, take: $take, filters: $filters, sort: $sort) {
      reports {
        id
        name
        reportType
        isViewable
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      total
     
    }
  }
`;

const GET_ALL_REPORT_TYPES = gql`
  query getAllReportTypes {
    getAllReportTypes {
      id
      name
    }
  }
`;

const GET_REPORT_TESTS = gql`
  query getReportTests($report_id: Int!) {
    getReportTests(report_id: $report_id) {
      reportId
      testId
      psType
      createdBy
      createdAt
      updatedBy
      updatedAt
      Test {
        name
      }
    }
  }
`;

const GET_UNADDED_TESTS = gql`
  query getUnaddedTests($report_id: Int!) {
    getUnaddedTests(report_id: $report_id) {
      id
      name
    }
  }
`;

const GET_REPORT_STUDENTS = gql`
  query getReportStudents($test_id: Int, $program_id: Int) {
    getReportStudents(test_id: $test_id, program_id: $program_id) {
      students {
        count
        Student {
          id
          givenname
          surname
          login
          Notifications {
            id
            isRead
            createdAt
          }
          Centre {
            name
          }
          Results {
            psType
          }
          Trainings {
            id
            registrationId
          }
        }
      }
      numTests
    }
  }
`;

const GET_REPORT = gql`
  query getReport($test_id: Int, $program_id: Int, $student_id: Int!) {
    getReport(test_id: $test_id, program_id: $program_id, student_id: $student_id) {
      testName
      studentRanks {
        Overall
        strands {
          name
          score
        }
      }
      questionData {
        name
        questions {
          id
          questionNumber
          result
          topic
        }
      }
      passageData {
        id
        name
        sittingData {
          studentId
          speed
          correct
        }
      }
      ps_results {
        id
        programId
        psType
        studentId
        strand
        score
        isDefault
      }
      trainings
      Student {
        id
        login
        givenname
        surname
      }
    }
  }
`;

const GET_NOTIFICATIONS = gql`
  query getNotifications($student_id: Int!) {
    getNotifications(student_id: $student_id) {
      id
      contentId
      isRead
      name
      isSummary
    }
  }
`;

const GET_ALL_TEACHERS = gql`
  query getAllTeachers($region_id: Int, $centre_id: Int) {
    getAllTeachers(region_id: $region_id, centre_id: $centre_id) {
      id
      givenname
      surname
      regionId
      login
      Classes {
        Class {
          id
          name
        }
      }
    }
  }
`;

const GET_STUDENTS_BY_QUERY = gql`
  query getStudentsByQuery($student_name: String, $student_id: String, $grade_id: Int, $training_module: Int, $test_module: Int, $context: String, $centre_id: Int, $class_id: Int, $curriculum_id: Int) {
    getStudentsByQuery(student_name: $student_name, student_id: $student_id, grade_id: $grade_id, training_module: $training_module, test_module: $test_module, context: $context, centre_id: $centre_id, class_id: $class_id, curriculum_id: $curriculum_id) {
      id
      surname
      givenname
      login
      campus
      region
      grade
      Trainings {
        id
        name
      }
      curriculum
    }
  }
`;

const GET_ALLOCATED_STUDENTS_BY_TEACHER = gql`
  query getAllocatedStudentsByTeacher($teacher_id: Int!, $class_id: Int!) {
    getAllocatedStudentsByTeacher(teacher_id: $teacher_id, class_id: $class_id) {
      id
      surname
      givenname
      login
      raiseHand
      campus
      grade
      Trainings {
        id
        order
        name
        assessmentId
        isCompleted
        createdAt
        totalQuestions
        firstGroupQuestions
        assessmentAttempt
        trainingAttempt
        TrainingSittings {
          id
          isCompleted
          upTo
          scoreSection1
          scoreSection2
          scoreSection3
          isRedo
          completedDate
          createdAt
        }
        dueDate
        isInteractive
      }
      Assessments {
        id
        name
        isCompleted
        createdAt
        totalQuestions
        order
        Sittings {
          id
          isCompleted
          upTo
          score
          completedDate
          createdAt
        }
        dueDate
      }
    }
  }
`;

const GET_RAISE_HAND = gql`
  query getRaiseHand($student_id: Int!) {
    getRaiseHand(student_id: $student_id) 
  }
`;

const GET_TRAINING_SUMMARY = gql`
  query getTrainingSummary($student_id: Int!, $training_module: Int!) {
    getTrainingSummary(student_id: $student_id, training_module: $training_module) {
      assessment {
        first {
          name
          value
          score
          max
        }
        median {
          name
          value
          score
          max
        }
      }
      training {
        name
        time
      }
      attempts {
        attempt
        trainingScore
        trainingTime
        trainingDate
        assessmentScore
        assessmentTime
        assessmentDate
        medianAssessmentTime
      }
    }
  }
`;

const GET_ALL_COURSES = gql`
  query getAllCourses(
    $skip: Int
    $take: Int
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getAllCourses(skip: $skip, take: $take, filters: $filters, sort: $sort) {
      courses {
        id
        name
        availableFrom
        availableTo
        createdAt
        updatedAt
      }
      total
    }
  }
`;

const GET_PACKAGE_DETAILS = gql`
  query getPackageDetails($package_id: Int!, $package_type: String!) {
    getPackageDetails(package_id: $package_id, package_type: $package_type) {
      id
      name
      availableFrom
      availableTo
      createdAt
      updatedAt
      courseId
      hasDiagnostic
      CreatedByStaff {
        surname
        givenname
      }
      UpdatedByStaff {
        surname
        givenname
      }
    }
  }
`;

const GET_ALLOCATED_TRAININGS_BY_COURSE = gql`
  query getAllocatedTrainingsByCourse($course_id: Int!) {
    getAllocatedTrainingsByCourse(course_id: $course_id) {
      id
      name
    }
  }
`;

const GET_TRAININGS_BY_QUERY = gql`
  query getTrainingsByQuery($training_id: Int, $training_name: String) {
    getTrainingsByQuery(training_id: $training_id, training_name: $training_name) {
      id
      name
      isActive
    }
  }
`;

const GET_ALL_PROGRAMS = gql`
  query getAllPrograms(
    $skip: Int!
    $take: Int!
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getAllPrograms(skip: $skip, take: $take, filters: $filters, sort: $sort) {
      programs {
        id
        name
        availableFrom
        availableTo
        course
        createdAt
        updatedAt
        hasDiagnostic
      }
      total
    }
  }
`;

const GET_ALLOCATED_TESTS_BY_PROGRAM = gql`
  query getAllocatedTestsByProgram($program_id: Int!) {
    getAllocatedTestsByProgram(program_id: $program_id) {
      id
      name
    }
  }
`;

const GET_TESTS_BY_QUERY = gql`
  query getTestsByQuery($test_id: Int, $test_name: String) {
    getTestsByQuery(test_id: $test_id, test_name: $test_name) {
      id
      name
    }
  }
`;

const GET_ALLOCATED_STUDENTS_BY_TEST = gql`
  query getAllocatedStudentsByTest($test_id: Int!) {
    getAllocatedStudentsByTest(test_id: $test_id) {
      id
      surname
      givenname
      login
      campus
      region
      grade
    }
  }
`;

const GET_ALL_TEST_NAMES = gql`
  query getAllTestNames {
    getAllTestNames {
      id
      name
    }
  }
`;

const GET_ALL_CAMPUS_NAMES = gql`
  query getAllCampusNames($region_id: Int) {
    getAllCampusNames(region_id: $region_id) {
      id
      name
      regionId
    }
  }
`;

const GET_RECOMMENDATION_STUDENTS = gql`
  query getRecommendationStudents($program_id: Int!) {
    getRecommendationStudents(program_id: $program_id) {
      id
      login
      givenname
      surname
      Centre {
        id
        name
      }
      Trainings {
        trainingId
        isPaid
        registrationId
      }
      Results {
        psType
      }
    }
  }
`;

const GET_COURSE_STUDENTS = gql`
  query getCourseStudents($course_id: Int!) {
    getCourseStudents(course_id: $course_id) {
      students {
        id
        login
        givenname
        surname
        Grade {
          name
        }
        Centre {
          name
        }
        Trainings {
          trainingId
          registrationId
        }
        Classes {
          Class {
            id
            name
          }
        }
      }
      payments {
        id
        paymentMadeAt
        studentId
        courseId
      }
    }
  }
`;

const GET_ALLOCATED_TRAININGS_BY_STUDENT = gql`
  query getAllocatedTrainingsByStudent($student_id: Int!) {
    getAllocatedTrainingsByStudent(student_id: $student_id) {
      id
      name
    }
  }
`;

const GET_MEDAL_LEADERBOARD = gql`
  query getMedalLeaderboard($student_id: Int!) {
    getMedalLeaderboard(student_id: $student_id) {
      givenname
      totalMedals
    }
  }
`;

const GET_ACTIVITY_REPORT = gql`
  query getActivityReport($student_id: Int!, $training_module: Int!) {
    getActivityReport(student_id: $student_id, training_module: $training_module) {
      givenname
      totalTime
      attempts
      trainingName
    }
  }
`;

const GET_TRAINING_ATTEMPTS = gql`
  query getTrainingAttempts($training_id: Int!, $student_id: Int!, $training_type: String!) {
    getTrainingAttempts(training_id: $training_id, student_id: $student_id, training_type: $training_type) {
      attempt
      trainingScore
      trainingTime
      trainingDate
      assessmentScore
      assessmentTime
      assessmentDate
    }
  }
`;

const GET_STUDENT_SITTINGS = gql`
  query getStudentSittings($student_id: Int!) {
    getStudentSittings(student_id: $student_id) {
      testSittings {
        id
        name
        isCompleted
        upTo
        createdAt
        completedDate
        moduleId
      }
      trainingSittings {
        id
        name
        isCompleted
        upTo
        createdAt
        completedDate
        moduleId
      }
      assessmentSittings {
        id
        name
        isCompleted
        upTo
        createdAt
        completedDate
        moduleId
      }
    }
  }
`;

const GET_STAFFS = gql`
  query getStaffs {
    getStaffs {
      id
      surname
      givenname
      login
      regionId
      easyEmail
      createdAt
      updatedAt
      CreatedByStaff {
        id
        surname
        givenname
      }
      UpdatedByStaff {
        id
        surname
        givenname
      }
      Role {
        id
        name
      }
      Region {
        id
        name
      }
      regionId
      Centres {
        Centre {
          id
          name
        }
      }
    }
  }
`;

const GET_REGIONS = gql`
  query getRegions {
    getRegions {
      id
      name
    }
  }
`;

const GET_PROGRAM_REGISTRATIONS = gql`
  query getProgramRegistrations($program_id: Int!) {
    getProgramRegistrations(program_id: $program_id) {
      id
      programId
      easyCourseId
      enrolFrom
      enrolTo
      availableFrom
      availableTo
      gradeId
      termId
      regionId
      termName
      gradeName
      easyCourseName
      centreId
      Region {
        id
        name
      }
      Centre {
        id
        name
      }
    }
  }
`;

const GET_EASY_COURSES = gql`
  query getEasyCourses(
    $regionId: Int!
  ) {
    getEasyCourses(
      regionId: $regionId
    ) {
      id
      name
    }
  }
`;

const GET_EASY_TERMS = gql`
  query getEasyTerms($regionId: Int!) {
    getEasyTerms(regionId: $regionId) {
      id
      name
    }
  }
`;

const GET_NSDC_GRADES = gql`
  query getNsdcGrades($regionId: Int!) {
    getNsdcGrades(regionId: $regionId) {
      Region_yearID
      FullName
    }
  }
`;

const GET_CENTRES = gql`
  query getCentres($regionId: Int!) {
    getCentres(regionId: $regionId) {
      id
      name
    }
  }
`;

const GET_TRAINING_CLASSES = gql`
  query getTrainingClasses {
    getTrainingClasses {
      id
      name
      regionId
      courseId
      centreId
      Region {
        id
        name
      }
      Centre {
        id
        name
      }
      Course {
        id
        name
      }
      Students {
        studentId
      }
      CreatedByStaff {
        surname
        givenname
      }
      UpdatedByStaff {
        surname
        givenname
      }
    }
  }
`;

const GET_COURSE_REGISTRATIONS = gql`
  query getCourseRegistrations($course_id: Int!) {
    getCourseRegistrations(course_id: $course_id) {
      id
      courseId
      easyCourseId
      enrolFrom
      enrolTo
      availableFrom
      availableTo
      gradeId
      termId
      regionId
      termName
      gradeName
      easyCourseName
      centreId
      Region {
        id
        name
      }
      Centre {
        id
        name
      }
    }
  }
`;

const GET_COURSE_CURRICULA = gql`
  query getCourseCurricula($course_id: Int, $program_id: Int) {
    getCourseCurricula(course_id: $course_id, program_id: $program_id) {
      id
      courseId
      gradeId
      Grade {
        id
        name
      }
      Trainings {
        curriculumId
        trainingId
      }
      numWeeks
      Course {
        id
        name
      }
    }
  }
`;

const GET_CURRICULUM_TRAININGS = gql`
  query getCurriculumTrainings($curriculumId: Int!) {
    getCurriculumTrainings(curriculumId: $curriculumId) {
      curriculumId
      trainingId
      percentile
      order
      name
    }
  }
`;

const GET_ALLOCATED_STUDENTS_BY_CLASS = gql`
  query getAllocatedStudentsByClass($class_id: Int!) {
    getAllocatedStudentsByClass(class_id: $class_id) {
      id
      surname
      givenname
      login
      raiseHand
      campus
      grade
      Trainings {
        id
        name
        assessmentId
        isCompleted
        createdAt
        totalQuestions
        firstGroupQuestions
        assessmentAttempt
        trainingAttempt
        TrainingSittings {
          id
          isCompleted
          upTo
          scoreSection1
          scoreSection2
          scoreSection3
          isRedo
          completedDate
          createdAt
        }
        dueDate
        isInteractive
      }
      Assessments {
        id
        name
        isCompleted
        createdAt
        totalQuestions
        Sittings {
          id
          isCompleted
          upTo
          score
          completedDate
          createdAt
        }
        dueDate
      }
      curriculum
    }
  }
`;

const GET_ALLOCATED_TEACHERS_BY_CLASS = gql`
  query getAllocatedTeachersByClass($class_id: Int!) {
    getAllocatedTeachersByClass(class_id: $class_id) {
      id
      givenname
      surname
      Classes {
        Class {
          id
          name
        }
      }
    }
  }
`;

const GET_TEACHER_CLASSES = gql`
  query getTeacherClasses($teacher_id: Int!) {
    getTeacherClasses(teacher_id: $teacher_id) {
      id
      name
      courseId
      currentWeek
    }
  }
`;

const GET_SURVEY_QUESTIONS = gql`
  query getSurveyQuestions($usage: String!) {
    getSurveyQuestions(usage: $usage) {
      id
      content
      usage
      responseType
      responseOptions
    }
  }
`;

const GET_DIAGNOSTIC_SITTINGS = gql`
  query getDiagnosticSittings(
    $program_id: Int!
    $skip: Int
    $take: Int
    $filters: FilterInput
    $sort: [SortItem]
  ) {
    getDiagnosticSittings(
      program_id: $program_id
      skip: $skip
      take: $take
      filters: $filters
      sort: $sort
    ) {
      sittings {
        id
        studentId
        givenname
        surname
        upTo
        route
        isCompleted
        createdAt
        completedDate
        totalQuestions
        campus
        studentNumber
      }
      total
    }
  }
`;

const GET_CLASS_TRAININGS = gql`
  query getClassTrainings($class_id: Int!) {
    getClassTrainings(class_id: $class_id) {
      order
      id
      name
      numAssigned
      numCompleted
    }
  }
`;

const GET_CLASS_TRAINING_DATA = gql`
  query getClassTrainingData($class_id: Int!, $training_id: Int!) {
    getClassTrainingData(class_id: $class_id, training_id: $training_id) {
      className
      trainingName
      isActivity
      isAssessment
      studentData {
        id
        login
        name
        sittingId
        upTo
        totalQuestions
        attempts
      }
      questionData {
        id
        questionNumber
        percentCorrect
      }
    }
  }
`;

export {
  GET_DASHBOARD,
  GET_SITTING,
  GET_FORMATTED_PASSAGE,
  GET_FORMATTED_QUESTION,
  GET_SITTINGS,
  GET_STUDENTS,
  GET_ALL_TESTS,
  GET_REGISTERED_STUDENTS,
  GET_GRADES,
  GET_MY_STAFF_DETAILS,
  GET_STUDENT_DETAILS,
  GET_REGISTERED_TESTS,
  GET_UNREGISTERED_PLACEMENTS,
  GET_FORMATTED_DIAG_QUESTION,
  GET_ALL_TRAININGS,
  GET_MODULE_DETAILS,
  GET_REGISTERED_TRAININGS,
  GET_UNREGISTERED_TRAININGS,
  GET_REPORTS,
  GET_ALL_REPORT_TYPES,
  GET_REPORT_TESTS,
  GET_UNADDED_TESTS,
  GET_REPORT_STUDENTS,
  GET_REPORT,
  GET_NOTIFICATIONS,
  GET_ALL_TEACHERS,
  GET_ALL_TRAINING_NAMES,
  GET_STUDENTS_BY_QUERY,
  GET_ALLOCATED_STUDENTS_BY_TEACHER,
  GET_RAISE_HAND,
  GET_TRAINING_SUMMARY,
  GET_ALL_COURSES,
  GET_PACKAGE_DETAILS,
  GET_ALLOCATED_TRAININGS_BY_COURSE,
  GET_TRAININGS_BY_QUERY,
  GET_ALL_PROGRAMS,
  GET_ALLOCATED_TESTS_BY_PROGRAM,
  GET_TESTS_BY_QUERY,
  GET_ALLOCATED_STUDENTS_BY_TEST,
  GET_ALL_TEST_NAMES,
  GET_ALL_CAMPUS_NAMES,
  GET_RECOMMENDATION_STUDENTS,
  GET_COURSE_STUDENTS,
  GET_ALLOCATED_TRAININGS_BY_STUDENT,
  GET_MEDAL_LEADERBOARD,
  GET_ACTIVITY_REPORT,
  GET_TRAINING_ATTEMPTS,
  GET_STUDENT_SITTINGS,
  GET_STAFFS,
  GET_REGIONS,
  GET_PROGRAM_REGISTRATIONS,
  GET_EASY_COURSES,
  GET_EASY_TERMS,
  GET_NSDC_GRADES,
  GET_CENTRES,
  GET_TRAINING_CLASSES,
  GET_COURSE_REGISTRATIONS,
  GET_COURSE_CURRICULA,
  GET_CURRICULUM_TRAININGS,
  GET_ALLOCATED_STUDENTS_BY_CLASS,
  GET_ALLOCATED_TEACHERS_BY_CLASS,
  GET_TEACHER_CLASSES,
  GET_SURVEY_QUESTIONS,
  GET_DIAGNOSTIC_SITTINGS,
  GET_CLASS_TRAININGS,
  GET_CLASS_TRAINING_DATA
};
