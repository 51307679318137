import { gql } from "@apollo/client";

const DELETE_SITTING = gql`
  mutation deleteSitting($module_id: Int!, $module_type: String!, $student_id: Int!) {
    deleteSitting(module_id: $module_id, module_type: $module_type, student_id: $student_id)
  }
`;

const LOGIN_STAFF = gql`
  mutation staff_login($staff_login: String!, $staff_password: String!) {
    staff_login(staff_login: $staff_login, staff_password: $staff_password) {
      status_id
      token
    }
  }
`;

const LOGIN_STUDENT = gql`
  mutation student_login($student_login: String!, $student_password: String!) {
    student_login(student_login: $student_login, student_password: $student_password) {
      status_id
      token
    }
  }
`;

const DELETE_TEST = gql`
  mutation deleteTest($id: Int!, $module_type_id: Int!) {
    deleteTest(id: $id, module_type_id: $module_type_id)
  }
`;

const REGISTER_MOCK_STUDENTS = gql`
  mutation registerMockStudents($id: Int!, $module_type_id: Int!) {
    registerMockStudents(id: $id, module_type_id: $module_type_id)
  }
`;

const UPDATE_SITTING = gql`
  mutation updateSitting(
    $module_type: String!
    $sitting_id: Int!
    $time_taken: Int
    $total_logout_time: Int
    $up_to: String
    $score: String
    $current_section: Int
    $part_one_all_correct: Boolean
    $section_one_pass: Boolean
    $section_two_pass: Boolean
    $is_redo: Boolean
  ) {
    updateSitting(
      module_type: $module_type
      sitting_id: $sitting_id
      time_taken: $time_taken
      total_logout_time: $total_logout_time
      up_to: $up_to
      score: $score
      current_section: $current_section
      part_one_all_correct: $part_one_all_correct
      section_one_pass: $section_one_pass
      section_two_pass: $section_two_pass
      is_redo: $is_redo
    )
  }
`;

const REGISTER_GRADE = gql`
  mutation registerGrade(
    $module_id: Int!
    $module_type: String!
    $gradeId: Int!
  ) {
    registerGrade(
      module_id: $module_id
      module_type: $module_type
      gradeId: $gradeId
    )
  }
`;

const UPDATE_STUDENT = gql`
  mutation updateStudent(
    $id: Int!
    $givenname: String
    $surname: String
    $phone: String
    $email: String
  ) {
    updateStudent(
      id: $id
      givenname: $givenname
      surname: $surname
      phone: $phone
      email: $email
    )
  }
`;

const REGISTER_MODULE = gql`
  mutation registerModule(
    $module_id: Int!
    $module_type: String!
    $student_id: Int
    $due_date: Date
    $student_ids: [Int]
  ) {
    registerModule(
      module_id: $module_id
      module_type: $module_type
      student_id: $student_id
      due_date: $due_date
      student_ids: $student_ids
    )
  }
`;

const DELETE_REGISTRATION = gql`
  mutation deleteRegistration(
    $module_id: Int!
    $module_type: String!
    $student_id: Int!
  ) {
    deleteRegistration(
      module_id: $module_id
      module_type: $module_type
      student_id: $student_id
    )
  }
`;

const CREATE_SITTING = gql`
  mutation createSitting($module_id: Int, $module_type: String!, $program_id: Int) {
    createSitting(module_id: $module_id, module_type: $module_type, program_id: $program_id)
  }
`;

const SAVE_ANSWER = gql`
  mutation saveAnswer(
    $sitting_id: Int!
    $module_type: String!
    $question_id: Int
    $question_number: Int
    $is_flagged: Boolean
    $working_time: Int
    $reviewing_time: Int
    $chosen: String
    $answer_id: Int
    $current_section: Int
    $is_result_shown: Boolean
    $read_only: Boolean
    $attempt: Int
  ) {
    saveAnswer(
      sitting_id: $sitting_id
      module_type: $module_type
      question_id: $question_id
      question_number: $question_number
      is_flagged: $is_flagged
      working_time: $working_time
      reviewing_time: $reviewing_time
      chosen: $chosen
      answer_id: $answer_id
      current_section: $current_section
      is_result_shown: $is_result_shown
      read_only: $read_only
      attempt: $attempt
    ) {
      testAnswer {
        id
        studentTestSittingId
        questionId
        questionNumber
        isFlagged
        reviewingTime
        workingTime
        chosen
        attempt
        isCorrect
      }
      diagPath
      diagAnswer {
        id
        studentDiagnosticSittingId
        diagnosticQuestionId
        questionNumber
        workingTime
        chosen
        written
        isMarked
        markedBy
      }
      trainingAnswer {
        id
        studentTrainingSittingId
        questionId
        questionNumber
        isFlagged
        reviewingTime
        workingTime
        chosen
        isCorrect
        current_section
        isResultShown
        readOnly
      }
    }
  }
`;

const FINISH_SITTING = gql`
  mutation finishSitting(
    $module_id: Int!
    $module_type: String!
    $sitting_id: Int!
    $score: String
  ) {
    finishSitting(
      module_id: $module_id
      module_type: $module_type
      sitting_id: $sitting_id
      score: $score
    )
  }
`;

const DELETE_TRAINING_ANSWERS = gql`
  mutation deleteTrainingAnswers($sitting_id: Int!, $current_section: [Int]!) {
    deleteTrainingAnswers(
      sitting_id: $sitting_id
      current_section: $current_section
    )
  }
`;

const DELETE_TRAINING = gql`
  mutation deleteTraining($id: Int!) {
    deleteTraining(id: $id)
  }
`;

const UPDATE_MODULE = gql`
  mutation updateModule($module_id: Int!, $module_type: String!, $name: String, $available_from: Date!, $available_to: Date!, $is_active: Boolean) {
    updateModule(module_id: $module_id, module_type: $module_type, name: $name, available_from: $available_from, available_to: $available_to, is_active: $is_active)
  }
`;

const CREATE_REPORT = gql`
  mutation createReport($name: String!, $report_type_id: Int!) {
    createReport(name: $name, report_type_id: $report_type_id)
  }
`;

const ADD_REPORT_TEST = gql`
  mutation addReportTest($report_id: Int!, $test_id: Int!, $test_type: String) {
    addReportTest(report_id: $report_id, test_id: $test_id, test_type: $test_type)
  }
`;

const DELETE_REPORT_TEST = gql`
  mutation deleteReportTest($report_id: Int!, $test_id: Int!) {
    deleteReportTest(report_id: $report_id, test_id: $test_id)
  }
`;

const CREATE_NOTIFICATIONS = gql`
  mutation createNotifications($test_id: Int, $program_id: Int, $send_all: Boolean!, $student_ids: [Int]) {
    createNotifications(test_id: $test_id, program_id: $program_id, send_all: $send_all, student_ids: $student_ids)
  }
`;

const SET_NOTIFICATION_READ = gql`
  mutation setNotificationRead($notification_id: Int!) {
    setNotificationRead(notification_id: $notification_id)
  }
`;

const REALLOCATE_STUDENTS = gql`
  mutation reallocateStudents($original_teacher_id: Int!, $new_teacher_id: Int!) {
    reallocateStudents(original_teacher_id: $original_teacher_id, new_teacher_id: $new_teacher_id)
  }
`;

const DEALLOCATE_STUDENTS = gql`
  mutation deallocateStudent($class_id: Int!, $student_id: Int!) {
    deallocateStudent(class_id: $class_id, student_id: $student_id)
  }
`;

const ALLOCATE_STUDENT_TO_CLASS = gql`
  mutation allocateStudentToClass($class_id: Int!, $student_ids: [Int]!) {
    allocateStudentToClass(class_id: $class_id, student_ids: $student_ids)
  }
`;

const TOGGLE_RAISE_HAND = gql`
  mutation toggleRaiseHand($student_id: Int!) {
    toggleRaiseHand(student_id: $student_id)
  }
`;

const CREATE_COURSE = gql`
  mutation createCourse($name: String!, $availableFrom: Date, $availableTo: Date) {
    createCourse(name: $name, availableFrom: $availableFrom, availableTo: $availableTo)
  }
`;

const DELETE_COURSE = gql`
  mutation deleteCourse($id: Int!) {
    deleteCourse(id: $id)
  }
`;

const UPDATE_PACKAGE = gql`
  mutation updatePackage($package_id: Int!, $package_type: String!, $name: String, $available_from: Date, $available_to: Date, $course_id: Int, $has_diagnostic: Boolean) {
    updatePackage(package_id: $package_id, package_type: $package_type, name: $name, available_from: $available_from, available_to: $available_to, course_id: $course_id, has_diagnostic: $has_diagnostic)
  }
`;

const DEALLOCATE_TRAINING = gql`
  mutation deallocateTraining($course_id: Int!, $training_id: Int!) {
    deallocateTraining(course_id: $course_id, training_id: $training_id)
  }
`;

const ALLOCATE_TRAININGS_TO_COURSE = gql`
  mutation allocateTrainingsToCourse($course_id: Int!, $training_ids: [Int]!) {
    allocateTrainingsToCourse(course_id: $course_id, training_ids: $training_ids)
  }
`;

const CREATE_PROGRAM = gql`
  mutation createProgram($name: String!, $availableFrom: Date, $availableTo: Date) {
    createProgram(name: $name, availableFrom: $availableFrom, availableTo: $availableTo)
  }
`;

const DELETE_PROGRAM = gql`
  mutation deleteProgram($id: Int!) {
    deleteProgram(id: $id)
  }
`;

const DEALLOCATE_TEST = gql`
  mutation deallocateTest($program_id: Int!, $test_id: Int!) {
    deallocateTest(program_id: $program_id, test_id: $test_id)
  }
`;

const ALLOCATE_TESTS_TO_PROGRAM = gql`
  mutation allocateTestsToProgram($program_id: Int!, $test_ids: [Int]!) {
    allocateTestsToProgram(program_id: $program_id, test_ids: $test_ids)
  }
`;

const GENERATE_TRAINING_RECOMMENDATIONS = gql`
  mutation generateTrainingRecommendations($program_id: Int!, $generate_ids: [Int]!, $curriculum_id: Int!) {
    generateTrainingRecommendations(program_id: $program_id, generate_ids: $generate_ids, curriculum_id: $curriculum_id)
  }
`;

const CREATE_PAYMENT_LINK = gql`
  mutation createPaymentLink($student_number: String!) {
    createPaymentLink(student_number: $student_number)
  }
`;

const DEACTIVATE_TRAINING = gql`
  mutation deactivateTraining($student_id: Int!, $course_id: Int!) {
    deactivateTraining(student_id: $student_id, course_id: $course_id)
  }
`;

const DEALLOCATE_STUDENT_TRAINING = gql`
  mutation deallocateStudentTraining($student_id: Int!, $training_id: Int!) {
    deallocateStudentTraining(student_id: $student_id, training_id: $training_id)
  }
`;

const ALLOCATE_TRAININGS_TO_STUDENT = gql`
  mutation allocateTrainingsToStudent($student_id: Int!, $training_ids: [Int]!, $due_date: Date!) {
    allocateTrainingsToStudent(student_id: $student_id, training_ids: $training_ids, due_date: $due_date)
  }
`;

const CREATE_TRAINING_INTERACTION = gql`
  mutation createTrainingInteraction($student_training_sitting_id: Int!, $question_number: Int!, $event: String!, $time_taken: Int!) {
    createTrainingInteraction(student_training_sitting_id: $student_training_sitting_id, question_number: $question_number, event: $event, time_taken: $time_taken)
  }
`;

const UPDATE_STAFF = gql`
  mutation updateStaff(
    $id: Int!
    $surname: String!
    $givenname: String!
    $roleId: Int!
    $regionId: Int
    $email: String!
  ) {
    updateStaff(
      id: $id
      surname: $surname
      givenname: $givenname
      roleId: $roleId
      regionId: $regionId
      email: $email
    )
  }
`;

const CREATE_STAFF = gql`
  mutation createStaff(
    $surname: String!
    $givenname: String!
    $roleId: Int!
    $regionId: Int
    $email: String!
  ) {
    createStaff(
      surname: $surname
      givenname: $givenname
      roleId: $roleId
      regionId: $regionId
      email: $email
    )
  }
`;

const CREATE_PROGRAM_REGISTRATION = gql`
  mutation createProgramRegistration(
    $programId: Int!
    $easyCourseId: Int
    $enrolFrom: Date
    $enrolTo: Date
    $availableFrom: Date
    $availableTo: Date
    $gradeId: Int
    $termId: Int
    $regionId: Int!
    $termName: String
    $gradeName: String
    $easyCourseName: String
    $centreId: Int
  ) {
    createProgramRegistration(
      programId: $programId
      easyCourseId: $easyCourseId
      enrolFrom: $enrolFrom
      enrolTo: $enrolTo
      availableFrom: $availableFrom
      availableTo: $availableTo
      gradeId: $gradeId
      termId: $termId
      regionId: $regionId
      termName: $termName
      gradeName: $gradeName
      easyCourseName: $easyCourseName
      centreId: $centreId
    )
  }
`;

const DELETE_PROGRAM_REGISTRATION = gql`
  mutation deleteProgramRegistration($registrationId: Int!) {
    deleteProgramRegistration(registrationId: $registrationId)
  }
`;

const CREATE_TRAINING_CLASS = gql`
  mutation createTrainingClass($name: String!, $courseId: Int!, $regionId: Int!, $centreId: Int) {
    createTrainingClass(name: $name, courseId: $courseId, regionId: $regionId, centreId: $centreId)
  }
`;

const DELETE_TRAINING_CLASS = gql`
  mutation deleteTrainingClass($classId: Int!) {
    deleteTrainingClass(classId: $classId)
  }
`;

const CREATE_COURSE_REGISTRATION = gql`
  mutation createCourseRegistration(
    $courseId: Int!
    $easyCourseId: Int
    $enrolFrom: Date
    $enrolTo: Date
    $availableFrom: Date
    $availableTo: Date
    $gradeId: Int
    $termId: Int
    $regionId: Int!
    $termName: String
    $gradeName: String
    $easyCourseName: String
    $centreId: Int
  ) {
    createCourseRegistration(
      courseId: $courseId
      easyCourseId: $easyCourseId
      enrolFrom: $enrolFrom
      enrolTo: $enrolTo
      availableFrom: $availableFrom
      availableTo: $availableTo
      gradeId: $gradeId
      termId: $termId
      regionId: $regionId
      termName: $termName
      gradeName: $gradeName
      easyCourseName: $easyCourseName
      centreId: $centreId
    )
  }
`;

const DELETE_COURSE_REGISTRATION = gql`
  mutation deleteCourseRegistration($registrationId: Int!) {
    deleteCourseRegistration(registrationId: $registrationId)
  }
`;

const CREATE_COURSE_CURRICULUM = gql`
  mutation createCourseCurriculum($courseId: Int!, $gradeId: Int!, $numWeeks: Int!) {
    createCourseCurriculum(courseId: $courseId, gradeId: $gradeId, numWeeks: $numWeeks)
  }
`;

const DELETE_COURSE_CURRICULUM = gql`
  mutation deleteCourseCurriculum($curriculumId: Int!) {
    deleteCourseCurriculum(curriculumId: $curriculumId)
  }
`;

const MAP_CURRICULUM_TRAINING = gql`
  mutation mapCurriculumTraining($curriculumId: Int!, $trainings: [CurriculumTrainingInput!]!, $order: Int!) {
    mapCurriculumTraining(curriculumId: $curriculumId, trainings: $trainings, order: $order)
  }
`;

const DEALLOCATE_TEACHER = gql`
  mutation deallocateTeacher($class_id: Int!, $teacher_id: Int!) {
    deallocateTeacher(class_id: $class_id, teacher_id: $teacher_id)
  }
`;

const ALLOCATE_TEACHER_TO_CLASS = gql`
  mutation allocateTeacherToClass($class_id: Int!, $teacher_ids: [Int]!) {
    allocateTeacherToClass(class_id: $class_id, teacher_ids: $teacher_ids)
  }
`;

const ADD_TRAINING_TO_CLASS = gql`
  mutation addTrainingToClass($class_id: Int!, $training_id: Int!, $order: Int!, $student_ids: [Int]) {
    addTrainingToClass(class_id: $class_id, training_id: $training_id, order: $order, student_ids: $student_ids)
  }
`;

const SET_CURRENT_WEEK = gql`
  mutation setCurrentWeek($class_id: Int!, $week: Int!) {
    setCurrentWeek(class_id: $class_id, week: $week)
  }
`;

const CREATE_STUDENT_FEEDBACK = gql`
  mutation createStudentFeedback($responses: [StudentFeedbackInput]) {
    createStudentFeedback(responses: $responses)
  }
`;

const CREATE_STAFF_FEEDBACK = gql`
  mutation createStaffFeedback($type: String!, $subject: String!, $feedback: String!, $staffId: Int!) {
    createStaffFeedback(type: $type, subject: $subject, feedback: $feedback, staffId: $staffId)
  }
`;

const DELETE_DIAGNOSTIC_SITTING = gql`
  mutation deleteDiagnosticSitting($program_id: Int!, $student_id: Int!) {
    deleteDiagnosticSitting(program_id: $program_id, student_id: $student_id)
  }
`;

const SUBMIT_CLASS_TRAININGS = gql`
  mutation submitClassTrainings($class_id: Int!, $week: Int!) {
    submitClassTrainings(class_id: $class_id, week: $week)
  }
`;

const CREATE_SUMMARY_REPORT = gql`
  mutation createSummaryReport($program_id: Int!) {
    createSummaryReport(program_id: $program_id)
  }
`;

export {
  DELETE_SITTING,
  LOGIN_STAFF,
  LOGIN_STUDENT,
  DELETE_TEST,
  REGISTER_MOCK_STUDENTS,
  UPDATE_SITTING,
  REGISTER_GRADE,
  UPDATE_STAFF,
  UPDATE_STUDENT,
  REGISTER_MODULE,
  DELETE_REGISTRATION,
  CREATE_SITTING,
  SAVE_ANSWER,
  FINISH_SITTING,
  UPDATE_MODULE,
  DELETE_TRAINING_ANSWERS,
  DELETE_TRAINING,
  CREATE_REPORT,
  ADD_REPORT_TEST,
  DELETE_REPORT_TEST,
  CREATE_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
  REALLOCATE_STUDENTS,
  DEALLOCATE_STUDENTS,
  ALLOCATE_STUDENT_TO_CLASS,
  TOGGLE_RAISE_HAND,
  CREATE_COURSE,
  DELETE_COURSE,
  UPDATE_PACKAGE,
  DEALLOCATE_TRAINING,
  ALLOCATE_TRAININGS_TO_COURSE,
  CREATE_PROGRAM,
  DELETE_PROGRAM,
  DEALLOCATE_TEST,
  ALLOCATE_TESTS_TO_PROGRAM,
  GENERATE_TRAINING_RECOMMENDATIONS,
  CREATE_PAYMENT_LINK,
  DEACTIVATE_TRAINING,
  DEALLOCATE_STUDENT_TRAINING,
  ALLOCATE_TRAININGS_TO_STUDENT,
  CREATE_TRAINING_INTERACTION,
  CREATE_STAFF,
  CREATE_PROGRAM_REGISTRATION,
  DELETE_PROGRAM_REGISTRATION,
  CREATE_TRAINING_CLASS,
  DELETE_TRAINING_CLASS,
  CREATE_COURSE_REGISTRATION,
  DELETE_COURSE_REGISTRATION,
  CREATE_COURSE_CURRICULUM,
  DELETE_COURSE_CURRICULUM,
  MAP_CURRICULUM_TRAINING,
  DEALLOCATE_TEACHER,
  ALLOCATE_TEACHER_TO_CLASS,
  ADD_TRAINING_TO_CLASS,
  SET_CURRENT_WEEK,
  CREATE_STAFF_FEEDBACK,
  CREATE_STUDENT_FEEDBACK,
  DELETE_DIAGNOSTIC_SITTING,
  SUBMIT_CLASS_TRAININGS,
  CREATE_SUMMARY_REPORT
};
