import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Backdrop, CircularProgress, CssBaseline, Fade } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { SnackbarProvider } from "notistack";
import Footer from "./components/Footer";
import Layout from "./components/Layout";
import Signin from "./pages/Signin";
import Dashboard from "./pages/Dashboard";
import { createContext, useEffect, useState } from "react";
import Tests from "./pages/Tests";
import Exercises from "./pages/Exercises";
import ProgressReport from "./pages/ProgressReport";
import InstructionsPage from "./pages/InstructionsPage";
import UpdateDetails from "./pages/UpdateDetails";
import { useLazyQuery, useMutation } from "@apollo/client";
import Question from "./pages/Question";
import { estimateAbilityEAP } from "irt-js";
import TestReviewSection from "./components/TestReviewSection";
import StudentManagement from "./pages/StudentManagement";
import { LicenseInfo } from "@mui/x-license-pro";
import StudentDetailManagement from "./pages/StudentDetailManagement";
import TestManagement from "./pages/TestManagement";
import TestDetailManagement from "./pages/TestDetailManagement";
import {
  CREATE_SITTING,
  SAVE_ANSWER,
  FINISH_SITTING,
  UPDATE_SITTING,
  DELETE_TRAINING_ANSWERS,
  DELETE_SITTING,
} from "./graphql/mutations";
import {
  GET_FORMATTED_QUESTION,
  GET_FORMATTED_PASSAGE,
  GET_FORMATTED_DIAG_QUESTION,
} from "./graphql/queries";
import StudentTraining from "./pages/StudentTraining";
import TrainingManagement from "./pages/TrainingManagement";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import TrainingDetailManagement from "./pages/TrainingDetailManagement";
import ReportManagement from "./pages/ReportManagement";
import ReportDetailManagement from "./pages/ReportDetailManagement";
import ClassManagement from "./pages/ClassManagement";
import ClassDetailManagement from "./pages/ClassDetailManagement";
import ClassManagementTeacher from "./pages/ClassManagementTeacher";
import IndividualStudentMonitor from "./pages/IndividualStudentMonitor";
import CourseManagement from "./pages/CourseManagement";
import CourseDetailManagement from "./pages/CourseDetailManagement";
import ProgramManagement from "./pages/ProgramManagement";
import ProgramDetailManagement from "./pages/ProgramDetailManagement";
import { ExternalRoute } from "./components/ExternalRoute";
import Payment from "./pages/Payment";
import PlacementReport from "./pages/PlacementReport";
import UserManagement from "./pages/UserManagement";
import Curriculum from "./pages/Curriculum";
import ClassTrainingView from "./pages/ClassTrainingView";
export const AuthenticatedContext = createContext({});
export const ModuleContext = createContext({});
export const TimerContext = createContext({});
export const PreferenceContext = createContext({});
export const StudentMonitoringContext = createContext({});
var moment = require("moment");

// LicenseInfo.setLicenseKey("b9099582b5b89786bcd5b97a6d4bf708Tz01MjA4OSxFPTE2OTY3NDQyMjE5NjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");
LicenseInfo.setLicenseKey(
  "db20bdb6213a77575d2d6fb8b7c527cbTz0xMDY4MzUsRT0xNzcwNTk1MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);
const theme = createTheme({
  palette: {
    primary: {
      main: "#213162",
    },
    secondary: {
      main: "#abd7fe",
    },
    success: {
      main: "#22D160",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

function App() {
  const [defaults] = useState({
    lastUpdate: "8/12/2024",
    studentManagement: {
      widths: {
        actions: 150,
        login: 210,
        name: 400,
        campus: 200,
        gradeId: 220,
        isActivated: 120,
        createdAt: 190,
      },
      pageSize: 10,
      columns: [
        "id",
        "actions",
        "login",
        "name",
        "campus",
        "gradeId",
        "isActivated",
        "createdAt",
      ],
      visible: {
        id: false,
        actions: true,
        login: true,
        name: true,
        campus: true,
        isActivated: true,
        gradeId: true,
        createdAt: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    reportManagement: {
      widths: {
        actions: 150,
        name: 400,
        reportType: 220,
        isViewable: 120,
        createdAt: 190,
        updatedAt: 190,
      },
      pageSize: 10,
      columns: [
        "id",
        "actions",
        "name",
        "reportType",
        "isViewable",
        "createdAt",
        "updatedAt",
      ],
      visible: {
        id: false,
        actions: true,
        name: true,
        isViewable: true,
        reportType: true,
        createdAt: true,
        updatedAt: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    registrations: {
      widths: {
        id: 75,
        actions: 150,
        login: 220,
        name: 400,
        campus: 150,
        gradeId: 220,
        isCompleted: 220,
        createdAt: 190,
        hasPaid: 200,
      },
      pageSize: 10,
      columns: [
        "id",
        "actions",
        "login",
        "name",
        "campus",
        "gradeId",
        "hasPaid",
        "isCompleted",
        "createdAt",
      ],
      visible: {
        id: false,
        login: true,
        actions: true,
        name: true,
        campus: true,
        gradeId: true,
        isCompleted: true,
        createdAt: true,
        hasPaid: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    testReg: {
      widths: {
        actions: 150,
        id: 100,
        name: 400,
        isCompleted: 220,
        createdAt: 190,
      },
      pageSize: 10,
      columns: ["id", "actions", "name", "isCompleted", "createdAt"],
      visible: {
        id: true,
        actions: true,
        name: true,
        isCompleted: true,
        createdAt: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    trainReg: {
      widths: {
        actions: 150,
        id: 100,
        name: 400,
        isCompleted: 400,
        createdAt: 190,
        course: 200,
      },
      pageSize: 10,
      columns: ["id", "actions", "name", "course", "isCompleted", "createdAt"],
      visible: {
        id: true,
        actions: true,
        name: true,
        isCompleted: true,
        createdAt: true,
        course: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    sittings: {
      widths: {
        actions: 75,
        id: 100,
        studentId: 100,
        studentNumber: 150,
        name: 350,
        isCompleted: 200,
        upTo: 100,
        createdAt: 190,
        completedDate: 190,
        campus: 190,
      },
      pageSize: 10,
      columns: [
        "actions",
        "id",
        "studentId",
        "studentNumber",
        "name",
        "campus",
        "isCompleted",
        "upTo",
        "createdAt",
        "completedDate",
      ],
      visible: {
        id: true,
        actions: true,
        name: true,
        campus: true,
        isCompleted: true,
        createdAt: true,
        studentId: true,
        upTo: true,
        completedDate: true,
        studentNumber: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    testMgmt: {
      widths: {
        actions: 150,
        id: 100,
        name: 350,
        totalQuestions: 220,
        isAdaptive: 100,
        isActive: 100,
        availableFrom: 190,
        availableTo: 190,
        createdAt: 190,
        updatedAt: 190,
      },
      pageSize: 10,
      columns: [
        "actions",
        "id",
        "name",
        "totalQuestions",
        "isAdaptive",
        "isActive",
        "availableFrom",
        "availableTo",
        "createdAt",
        "updatedAt",
      ],
      visible: {
        actions: true,
        id: true,
        name: true,
        totalQuestions: true,
        isAdaptive: true,
        isActive: true,
        availableFrom: true,
        availableTo: true,
        createdAt: true,
        updatedAt: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    studentTraining: {
      widths: {
        actions: 350,
        id: 100,
        originId: 100,
        name: 300,
        type: 150,
        status: 150,
        progress: 120,
        completionDate: 150,
        dueDate: 150,
      },
      pageSize: 10,
      columns: [
        "actions",
        "id",
        "originId",
        "name",
        "type",
        "status",
        "progress",
        "completionDate",
        "dueDate",
      ],
      visible: {
        actions: true,
        id: false,
        originId: false,
        name: true,
        type: true,
        status: true,
        progress: true,
        completionDate: true,
        dueDate: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    classManagement: {
      widths: {
        actions: 125,
        id: 100,
        name: 200,
        course: 300,
        numOfStudents: 130,
        region: 100,
        centre: 200,
        createdBy: 200,
        updatedBy: 200,
      },
      pageSize: 10,
      columns: [
        "actions",
        "id",
        "name",
        "course",
        "region",
        "centre",
        "numOfStudents",
        "createdBy",
        "updatedBy",
      ],
      visible: {
        actions: true,
        id: false,
        name: true,
        course: true,
        numOfStudents: true,
        region: true,
        centre: true,
        createdBy: true,
        updatedBy: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    trainingMgmt: {
      widths: {
        actions: 150,
        id: 150,
        numEnrolled: 150,
        numActive: 150,
        name: 350,
        assessmentId: 150,
        totalQuestions: 180,
        firstGroupQuestions: 230,
        timeOfFirstGroupQuestions: 250,
        totalCorrectScoreSectionOne: 250,
        totalCorrectScoreSectionTwo: 250,
        isActive: 100,
        createdAt: 130,
        updatedAt: 130,
      },
      pageSize: 10,
      columns: [
        "id",
        "name",
        "numEnrolled",
        "numActive",
        "assessmentId",
        "totalQuestions",
        "firstGroupQuestions",
        "timeOfFirstGroupQuestions",
        "totalCorrectScoreSectionOne",
        "totalCorrectScoreSectionTwo",
        "isActive",
        "createdAt",
        "updatedAt",
      ],
      visible: {
        actions: true,
        id: true,
        numEnrolled: true,
        numActive: true,
        name: true,
        assessmentId: true,
        totalQuestions: true,
        firstGroupQuestions: true,
        timeOfFirstGroupQuestions: true,
        totalCorrectScoreSectionOne: true,
        totalCorrectScoreSectionTwo: true,
        isActive: true,
        createdAt: true,
        updatedAt: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
    reportTest: {
      widths: {
        actions: 150,
        testId: 100,
        testName: 400,
        psType: 200,
        createdAt: 190,
        updatedAt: 190,
      },
      columns: [
        "testId",
        "actions",
        "testName",
        "psType",
        "createdAt",
        "updatedAt",
      ],
      visible: {
        testId: true,
        actions: true,
        testName: true,
        psType: true,
        createdAt: true,
        updatedAt: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
      pageSize: 10,
    },
    reportStudent: {
      widths: {
        actions: 80,
        studentNumber: 150,
        studentName: 400,
        campus: 200,
        countTest: 150,
        reports: 200,
        trainings: 200,
        isSent: 200,
        isRead: 200,
        createdAt: 190,
      },
      columns: [
        "studentNumber",
        "actions",
        "studentName",
        "campus",
        "countTest",
        "reports",
        "trainings",
        "isSent",
        "isRead",
        "createdAt",
      ],
      visible: {
        studentNumber: true,
        actions: true,
        studentName: true,
        campus: true,
        countTest: true,
        reports: true,
        trainings: true,
        createdAt: true,
        isSent: true,
        isRead: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
      pageSize: 10,
    },
    courseMgmt: {
      widths: {
        actions: 80,
        id: 80,
        name: 350,
        availableFrom: 300,
        availableTo: 300,
        createdAt: 190,
        updatedAt: 190,
      },
      columns: [
        "actions",
        "id",
        "name",
        "availableFrom",
        "availableTo",
        "createdAt",
        "updatedAt",
      ],
      visible: {
        actions: true,
        id: true,
        name: true,
        availableFrom: true,
        availableTo: true,
        createdAt: true,
        updatedAt: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
      pageSize: 10,
    },
    programMgmt: {
      widths: {
        actions: 80,
        id: 80,
        name: 350,
        availableFrom: 300,
        availableTo: 300,
        createdAt: 190,
        updatedAt: 190,
        course: 350,
        hasDiagnostic: 190,
      },
      columns: [
        "actions",
        "id",
        "name",
        "availableFrom",
        "availableTo",
        "course",
        "hasDiagnostic",
        "createdAt",
        "updatedAt",
      ],
      visible: {
        actions: true,
        id: true,
        name: true,
        availableFrom: true,
        availableTo: true,
        course: true,
        createdAt: true,
        updatedAt: true,
        hasDiagnostic: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
      pageSize: 10,
    },
    diagSittings: {
      widths: {
        actions: 75,
        id: 100,
        studentId: 100,
        studentNumber: 150,
        name: 350,
        isCompleted: 200,
        upTo: 100,
        createdAt: 190,
        completedDate: 190,
        campus: 190,
        route: 200,
      },
      pageSize: 10,
      columns: [
        "actions",
        "id",
        "studentId",
        "studentNumber",
        "name",
        "campus",
        "isCompleted",
        "upTo",
        "route",
        "createdAt",
        "completedDate",
      ],
      visible: {
        id: true,
        actions: true,
        name: true,
        campus: true,
        isCompleted: true,
        createdAt: true,
        studentId: true,
        upTo: true,
        completedDate: true,
        studentNumber: true,
        route: true,
      },
      filters: {
        items: [],
        linkOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
        quickFilterExcludeHiddenColumns: true,
      },
      sort: [{ field: "id", sort: "asc" }],
    },
  });
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("auth") === "true"
  );
  const [userToken, setUserToken] = useState(sessionStorage.getItem("token"));
  const [userRole, setUserRole] = useState(
    sessionStorage.getItem("role") || ""
  );
  const [user, setUser] = useState(sessionStorage.getItem("user") || "");
  const [loading, setLoading] = useState(false);
  const [placementsDone, setPlacementsDone] = useState(
    sessionStorage.getItem("placementsDone") === "true"
  );
  const [studentMonitoringData, setStudentMonitoringData] = useState(
    JSON.parse(sessionStorage.getItem("studentMonitoringData")) || null
  );

  const [moduleSettings, setModuleSettings] = useState(
    JSON.parse(sessionStorage.getItem("moduleSettings")) || {
      selectedModule: null,
      moduleType: "",
      currentSitting: null,
      currentQuestion: null,
      answers: [],
      flags: [],
      reviews: [],
      questions: [],
      reviewQuestions: [],
      marks: null,
      currentQuestionNumber: 0,
      zetas: null,
      route: [],
      abilities: null,
      totalQuestionNumber: 0,
      questionsPicked: null,
      strands: [],
      passages: [],
      isPassage: false,
      passageLeft: 0,
      isComprehension: false,
      numStart: 0,
      loadedPassages: {},
      selectedAnswer: "",
      open: false,
      openReview: false,
      initialAnswer: "",
      startTime: null,
      endTime: null,
      totalLogoutTime: null,
      countdown: false,
      incorrect: [],
      incorrectIndex: 0,
      diagTree: [],
      attempt: null,
      maxOrder: 1,
      isTrainingAnswerCorrect: false,
      isAssessmentAnswerCorrect: false,
      isResultShown: false,
      readOnly: false,
      currentPart: 1,
      partOneAllCorrect: false,
      sectionOnePass: false,
      sectionTwoPass: false,
      totalScorePartOne: "",
      totalScorePartTwo: "",
      totalScorePartThree: "",
      totalTimeTakenPartOne: 0,
      totalTimeTakenPartTwo: 0,
      totalTimeTakenPartThree: 0,
      lowest: [],
      limit: {},
      openDiag: false,
      totalParts: 0,
    }
  );

  const [preferences, setPreferences] = useState({});

  const [timeLeft, setTimeLeft] = useState(0);
  const [timeUp, setTimeUp] = useState(false);
  const timeSettings = {
    timeLeft,
    setTimeLeft,
    timeUp,
    setTimeUp,
  };

  const exitSitting = () => {
    // setTimeLeft(0);
    setTimeUp(false);
    setModuleSettings({
      selectedModule: null,
      moduleType: "",
      currentSitting: null,
      currentQuestion: null,
      answers: [],
      flags: [],
      reviews: [],
      questions: [],
      reviewQuestions: [],
      marks: null,
      currentQuestionNumber: null,
      zetas: null,
      route: [],
      abilities: null,
      totalQuestionNumber: null,
      questionsPicked: null,
      strands: [],
      passages: [],
      isPassage: false,
      passageLeft: 0,
      isComprehension: false,
      numStart: 0,
      loadedPassages: {},
      selectedAnswer: "",
      open: false,
      openReview: false,
      initialAnswer: "",
      startTime: null,
      endTime: null,
      totalLogoutTime: null,
      countdown: false,
      incorrect: [],
      incorrectIndex: 0,
      diagTree: [],
      attempt: null,
      maxOrder: 1,
      isTrainingAnswerCorrect: false,
      isAssessmentAnswerCorrect: false,
      isResultShown: false,
      readOnly: false,
      currentPart: 1,
      partOneAllCorrect: false,
      sectionOnePass: false,
      sectionTwoPass: false,
      totalScorePartOne: "",
      totalScorePartTwo: "",
      totalScorePartThree: "",
      totalTimeTakenPartOne: 0,
      totalTimeTakenPartTwo: 0,
      totalTimeTakenPartThree: 0,
      lowest: [],
      limit: {},
      openDiag: false,
      totalParts: 0,
    });
  };

  const loginSuccess = (role, user, token) => {
    setIsAuthenticated(true);
    setUserRole(role);
    setUser(JSON.stringify(user));
    setUserToken(token);
  };

  const logoutSuccess = () => {
    setIsAuthenticated(false);
    setUserRole("");
    setUser("");
    setUserToken(null);
    setStudentMonitoringData(null);
    exitSitting();
  };

  const globalSettings = {
    isAuthenticated,
    userRole,
    user,
    userToken,
    loading,
    placementsDone,
    loginSuccess,
    logoutSuccess,
    setLoading,
    setPlacementsDone,
  };

  const [createSitting] = useMutation(CREATE_SITTING, {
    onError: (error) => {
      console.log(`create sitting error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [deleteSitting] = useMutation(DELETE_SITTING, {
    onError: (error) => {
      console.log(`delete sitting error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [saveAnswer] = useMutation(SAVE_ANSWER, {
    onError: (error) => {
      console.log(`save answer error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [getFormattedQuestion] = useLazyQuery(GET_FORMATTED_QUESTION, {
    fetchPolicy: "no-cache",
  });

  const [getFormattedPassage] = useLazyQuery(GET_FORMATTED_PASSAGE, {
    fetchPolicy: "no-cache",
  });

  const [finishSitting] = useMutation(FINISH_SITTING, {
    onError: (error) => {
      console.log(`finish sitting error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [updateSitting] = useMutation(UPDATE_SITTING, {
    onError: (error) => {
      console.log(`update sitting error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [getFormattedDiagQuestion] = useLazyQuery(GET_FORMATTED_DIAG_QUESTION, {
    fetchPolicy: "no-cache",
  });

  const [deleteTrainingAnswers] = useMutation(DELETE_TRAINING_ANSWERS, {
    onError: (error) => {
      console.log(`delete training answers error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      moduleSettings.moduleType !== "Training" &&
      moduleSettings.currentSitting?.createdAt &&
      moduleSettings.selectedModule?.duration
    ) {
      setTimeLeft(
        moduleSettings.currentSitting?.createdAt +
          moduleSettings.selectedModule?.duration * 60 * 1000
      );
    }
  }, [moduleSettings.currentSitting, moduleSettings.selectedModule]);

  useEffect(() => {
    if (
      moduleSettings.selectedModule &&
      moduleSettings.currentSitting &&
      moduleSettings.moduleType === "Training"
    ) {
      if (moduleSettings.totalLogoutTime !== null) {
        setTimeLeft(
          moduleSettings.currentSitting?.createdAt +
            moduleSettings.selectedModule?.timeOfFirstGroupQuestions *
              60 *
              1000 +
            moduleSettings.totalLogoutTime
        );
      } else {
        setTimeLeft(
          moduleSettings.currentSitting?.createdAt +
            moduleSettings.selectedModule?.timeOfFirstGroupQuestions * 60 * 1000
        );
      }
    }
  }, [moduleSettings.selectedModule, moduleSettings.currentSitting]);

  useEffect(() => {
    sessionStorage.setItem("placementsDone", placementsDone);
  }, [placementsDone]);

  const markQuestion = (answers) => {
    const answer = answers[moduleSettings.currentQuestionNumber - 1];
    let numAnswers = 0;
    for (let i = 1; i <= 10; i++) {
      if (moduleSettings.currentQuestion[`answer${i}`]) {
        numAnswers++;
      } else {
        break;
      }
    }
    let c;
    switch (moduleSettings.currentQuestion.questionType) {
      case "MultipleChoiceType":
      case "DragDropIntoBoxType":
      case "InlineSelectType":
      case "SingleInlineSelectType":
        c = 1 / numAnswers;
        break;
      case "SortOrderType":
      case "HorizontalSortType":
        let horPoss = 1;
        for (let i = 2; i <= numAnswers; i++) {
          horPoss *= i;
        }
        c = 1 / horPoss;
        break;
      case "MultipleCheckBoxType":
        let factorials = [1];
        for (let i = 2; i <= numAnswers; i++) {
          factorials.push(factorials[i - 2] * i);
        }
        let mcbPoss = 1;
        for (let i = 0; i < numAnswers; i++) {
          mcbPoss +=
            factorials[numAnswers - 1] / factorials[numAnswers - i - 1];
        }
        c = 1 / mcbPoss;
        break;
      case "MultipleTrueOrFalseType":
        c = 1 / Math.pow(2, numAnswers);
        break;
      case "MultipleInlineSelectType":
        let milPoss = 1;
        for (let i = 1; i <= numAnswers; i++) {
          milPoss *=
            moduleSettings.currentQuestion[`answer${i}`].split(",").length;
        }
        c = 1 / milPoss;
        break;
      default:
        c = 0;
        break;
    }

    const newZeta = {
      a: 1,
      b:
        (moduleSettings.currentQuestion.difficulty -
          moduleSettings.selectedModule.mean) *
        (3 / moduleSettings.selectedModule.variance),
      c,
    };

    let newStrandMarks = [
      ...moduleSettings.marks[`${moduleSettings.currentQuestion.strand}`],
      answer.isCorrect ? 1 : 0,
    ];
    let newAllMarks = [...moduleSettings.marks.All, answer.isCorrect ? 1 : 0];
    let newStrandZetas = [
      ...moduleSettings.zetas[`${moduleSettings.currentQuestion.strand}`],
      newZeta,
    ];
    let newAllZetas = [...moduleSettings.zetas.All, newZeta];

    const overallAbility = estimateAbilityEAP(newAllMarks, newAllZetas);
    const strandAbility = estimateAbilityEAP(newStrandMarks, newStrandZetas);

    let newMarks = { ...moduleSettings.marks };
    newMarks[`${moduleSettings.currentQuestion.strand}`] = newStrandMarks;
    newMarks.All = newAllMarks;
    let newZetas = { ...moduleSettings.zetas };
    newZetas[`${moduleSettings.currentQuestion.strand}`] = newStrandZetas;
    newZetas.All = newAllZetas;
    let newAbilities = { ...moduleSettings.abilities };
    newAbilities[`${moduleSettings.currentQuestion.strand}`] = strandAbility;
    newAbilities.All = overallAbility;

    return {
      newMarks,
      newZetas,
      newAbilities,
    };
  };

  const moduleFunctions = {
    createSitting: async (id, type) => {
      if (moduleSettings.moduleType === "Diagnostic") {
        const result = await createSitting({
          variables: {
            program_id: moduleSettings.selectedModule.id,
            module_type: moduleSettings.moduleType,
          },
        });
        if (result && result.data) return;
      } else {
        const result = await createSitting({
          variables: {
            module_id: id || moduleSettings.selectedModule.id,
            module_type: type || moduleSettings.moduleType,
          },
        });
        if (result && result.data) return;
      }
    },
    mfSaveAnswer: async (attempt) => {
      let answer;
      if (moduleSettings.moduleType === "Diagnostic") {
        answer = moduleSettings.answers.find(
          (a) => a.diagnosticQuestionId === moduleSettings.currentQuestion.id
        );
      } else if (moduleSettings.moduleType === "Training") {
        answer = moduleSettings.answers.find(
          (a) => a.questionNumber === moduleSettings.currentQuestion.order
        );
      } else {
        answer = moduleSettings.answers.find(
          (a) => a.questionId === moduleSettings.currentQuestion.id
        );
      }

      let result;
      let timeTaken = moduleSettings.startTime
        ? moment() - moment(moduleSettings.startTime)
        : null;
      let timeTakenTraining =
        moduleSettings.startTime && moduleSettings.endTime
          ? moment(moduleSettings.endTime) - moment(moduleSettings.startTime)
          : null;
      let timeReviewingTakenTraining = moduleSettings.endTime
        ? moment() - moment(moduleSettings.endTime)
        : null;

      if (answer) {
        if (
          moduleSettings.moduleType === "Diagnostic" ||
          (moduleSettings.initialAnswer === "" &&
            moduleSettings.selectedAnswer === "") ||
          moduleSettings.initialAnswer !== moduleSettings.selectedAnswer
        ) {
          result = await saveAnswer({
            variables: {
              sitting_id: moduleSettings.currentSitting.id,
              module_type: moduleSettings.moduleType,
              question_id: moduleSettings.currentQuestion.id,
              question_number: moduleSettings.currentQuestionNumber,
              is_flagged: !!moduleSettings.flags.find(
                (id) => id === moduleSettings.currentQuestion.id
              ),
              working_time:
                moduleSettings.moduleType === "Training" &&
                !moduleSettings.isResultShown &&
                !moduleSettings.readOnly
                  ? timeTakenTraining
                  : timeTaken,
              reviewing_time:
                moduleSettings.moduleType === "Training" &&
                moduleSettings.isResultShown &&
                moduleSettings.readOnly
                  ? timeReviewingTakenTraining
                  : 0,
              chosen: moduleSettings.selectedAnswer,
              answer_id: answer.id,
              current_section: moduleSettings.currentPart,
              is_result_shown:
                moduleSettings.isResultShown === true ? true : false,
              read_only: moduleSettings.readOnly === true ? true : false,
              attempt:
                attempt &&
                moduleSettings.moduleType === "Assessment" &&
                attempt,
            },
          });
        } else {
          result = await saveAnswer({
            variables: {
              sitting_id: moduleSettings.currentSitting.id,
              module_type: moduleSettings.moduleType,
              question_id: moduleSettings.currentQuestion.id,
              question_number: moduleSettings.currentQuestionNumber,
              is_flagged: !!moduleSettings.flags.find(
                (id) => id === moduleSettings.currentQuestion.id
              ),
              working_time:
                moduleSettings.moduleType === "Training" &&
                !moduleSettings.isResultShown &&
                !moduleSettings.readOnly
                  ? timeTakenTraining
                  : 0,
              reviewing_time:
                moduleSettings.moduleType === "Training" &&
                moduleSettings.isResultShown &&
                moduleSettings.readOnly
                  ? timeReviewingTakenTraining
                  : timeTaken,
              chosen: moduleSettings.selectedAnswer,
              answer_id: answer.id,
              current_section: moduleSettings.currentPart,
              is_result_shown:
                moduleSettings.isResultShown === true ? true : false,
              read_only: moduleSettings.readOnly === true ? true : false,
              attempt:
                attempt &&
                moduleSettings.moduleType === "Assessment" &&
                attempt,
            },
          });
        }
      } else {
        result = await saveAnswer({
          variables: {
            sitting_id: moduleSettings.currentSitting.id,
            module_type: moduleSettings.moduleType,
            question_id: moduleSettings.currentQuestion.id,
            question_number: moduleSettings.currentQuestionNumber,
            is_flagged: !!moduleSettings.flags.find(
              (id) => id === moduleSettings.currentQuestion.id
            ),
            working_time:
              moduleSettings.moduleType === "Training" &&
              moduleSettings.isResultShown &&
              moduleSettings.readOnly
                ? timeTakenTraining
                : timeTaken,
            reviewing_time:
              moduleSettings.moduleType === "Training" &&
              moduleSettings.isResultShown &&
              moduleSettings.readOnly
                ? timeReviewingTakenTraining
                : 0,
            chosen: moduleSettings.selectedAnswer,
            current_section: moduleSettings.currentPart,
            is_result_shown:
              moduleSettings.isResultShown === true ? true : false,
            read_only: moduleSettings.readOnly === true ? true : false,
          },
        });
      }

      if (result && result.data) {
        if (
          moduleSettings.moduleType === "Placement" ||
          moduleSettings.moduleType === "Test" ||
          moduleSettings.moduleType === "Assessment"
        ) {
          answer = result.data.saveAnswer.testAnswer;
        } else if (moduleSettings.moduleType === "Diagnostic") {
          answer = {
            ...result.data.saveAnswer.diagAnswer,
            isCorrect: result.data.saveAnswer.diagPath,
          };
        } else if (
          moduleSettings.moduleType === "Training" ||
          moduleSettings.moduleType === "Interactive"
        ) {
          answer = result.data.saveAnswer.trainingAnswer;
        }
        let index =
          moduleSettings.moduleType === "Assessment"
            ? moduleSettings.answers.findIndex(
                (a) => a.questionId === answer.questionId
              )
            : moduleSettings.answers.findIndex(
                (a) => a.questionNumber === answer.questionNumber
              );
        if (index >= 0) {
          let newAnswers = [...moduleSettings.answers];
          newAnswers.splice(index, 1, answer);
          return newAnswers;
        } else {
          return [...moduleSettings.answers, answer];
        }
      }
    },
    goToQuestion: async (questionNumber, isSkip) => {
      setLoading(true);
      if (moduleSettings.moduleType === "Diagnostic") {
        const answers = await moduleFunctions.mfSaveAnswer();
        let nextQuestion;
        let newMaxOrder = moduleSettings.maxOrder;
        let addedPassage = false;
        let newPassages = { ...moduleSettings.loadedPassages };
        if (
          moduleSettings.currentQuestionNumber ===
            moduleSettings.totalQuestionNumber &&
          questionNumber === moduleSettings.currentQuestionNumber + 1
        ) {
          let currTree = moduleSettings.diagTree.filter(
            (dt) =>
              dt.node === moduleSettings.currentQuestion.nodeId &&
              dt.originalQuestionId ===
                moduleSettings.currentQuestion.originalQuestionId
          );
          let questions = moduleSettings.questions.filter(
            (q) =>
              q.nodeId ===
                moduleSettings.diagTree.find(
                  (dt) =>
                    dt.parent === moduleSettings.currentQuestion.nodeId &&
                    dt.isCorrect === answers[answers.length - 1].isCorrect
                )?.node &&
              q.originalQuestionId ===
                moduleSettings.currentQuestion.originalQuestionId
          );

          if (
            questions.length === 0 ||
            (currTree[0].parent === "node-0" &&
              moduleSettings.currentSitting.correctIds
                ?.split(",")
                .findIndex(
                  (id) => id === currTree[0].originalQuestionId.toString()
                ) > -1) ||
            isSkip
          ) {
            let index = moduleSettings.currentSitting.route
              .split(",")
              .findIndex(
                (qId) =>
                  qId ===
                  moduleSettings.currentQuestion.originalQuestionId.toString()
              );
            if (
              index ===
              moduleSettings.currentSitting.route.split(",").length - 1
            ) {
              setModuleSettings({
                ...moduleSettings,
                openDiag: true,
              });
              setLoading(false);
              return;
            }
            let firstQuestion = moduleSettings.diagTree.find(
              (dt) =>
                dt.originalQuestionId ===
                  Number(
                    moduleSettings.currentSitting.route.split(",")[index + 1]
                  ) && dt.parent === "node-0"
            );
            const pickedQuestions = moduleSettings.questions.filter(
              (q) =>
                q.originalQuestionId ===
                  Number(
                    moduleSettings.currentSitting.route.split(",")[index + 1]
                  ) && q.nodeId === firstQuestion.node
            );
            if (pickedQuestions.length > 1) {
              nextQuestion = pickedQuestions.find((q) => q.order === 1);
            } else {
              nextQuestion = pickedQuestions[0];
            }
            newMaxOrder = 1;
          } else if (questions.length > 1) {
            nextQuestion = questions.find((q) => q.order === newMaxOrder);
            let answer = answers.find(
              (a) => a.diagnosticQuestionId === nextQuestion.id
            );
            if (answer) {
              newMaxOrder++;
              nextQuestion = questions.find((q) => q.order === newMaxOrder);
              if (!nextQuestion) {
                let index = moduleSettings.currentSitting.route
                  .split(",")
                  .findIndex(
                    (qId) =>
                      qId ===
                      moduleSettings.currentQuestion.originalQuestionId.toString()
                  );
                if (
                  index ===
                  moduleSettings.currentSitting.route.split(",").length - 1
                ) {
                  setModuleSettings({
                    ...moduleSettings,
                    openDiag: true,
                  });
                  setLoading(false);
                  return;
                }
                let firstQuestion = moduleSettings.diagTree.find(
                  (dt) =>
                    dt.originalQuestionId ===
                      Number(
                        moduleSettings.currentSitting.route.split(",")[
                          index + 1
                        ]
                      ) && dt.parent === "node-0"
                );
                const pickedQuestions = moduleSettings.questions.filter(
                  (q) =>
                    q.originalQuestionId ===
                      Number(
                        moduleSettings.currentSitting.route.split(",")[
                          index + 1
                        ]
                      ) && q.nodeId === firstQuestion.node
                );
                newMaxOrder = 1;
                if (pickedQuestions.length > 1) {
                  nextQuestion = pickedQuestions.find((q) => q.order === 1);
                } else {
                  nextQuestion = pickedQuestions[0];
                }
              }
            }
          } else {
            nextQuestion = questions[0];
          }
          const result = await getFormattedDiagQuestion({
            variables: { question_id: nextQuestion.id },
          });
          if (result && result.data.getFormattedDiagQuestion) {
            nextQuestion = result.data.getFormattedDiagQuestion;
            if (nextQuestion.passageId) {
              if (moduleSettings.loadedPassages[nextQuestion.passageId]) {
                nextQuestion.src =
                  moduleSettings.loadedPassages[nextQuestion.passageId];
              } else {
                const passageResult = await getFormattedPassage({
                  variables: { passage_id: nextQuestion.passageId },
                });
                if (passageResult && passageResult.data.getFormattedPassage) {
                  nextQuestion.src = passageResult.data.getFormattedPassage;
                  newPassages[nextQuestion.passageId] =
                    passageResult.data.getFormattedPassage;
                  addedPassage = true;
                }
              }
            }
          }
        }
        await updateSitting({
          variables: {
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
            up_to: nextQuestion.id.toString(),
            time_taken: answers[answers.length - 1].workingTime,
          },
        });
        setModuleSettings({
          ...moduleSettings,
          currentQuestionNumber: questionNumber,
          currentQuestion: nextQuestion,
          selectedAnswer: "",
          answers: [...answers],
          totalQuestionNumber: questionNumber,
          initialAnswer: "",
          startTime: moment(),
          maxOrder: newMaxOrder,
          attempt: 1,
          ...(addedPassage ? { loadedPassages: { ...newPassages } } : {}),
        });
        setLoading(false);
        return;
      } else if (moduleSettings.reviews?.length > 0) {
        let newQuestions = [...moduleSettings.questions];
        let changedQuestions = false;
        let newPassages = { ...moduleSettings.loadedPassages };
        let addedPassage = false;
        let review = moduleSettings.reviews.find(
          (r) => r.buttonNumber === questionNumber
        );
        let nextQuestion = newQuestions.find((q) => q.id === review.questionId);
        if (!nextQuestion.content) {
          const result = await getFormattedQuestion({
            variables: { question_id: nextQuestion.id, is_review: true },
          });
          if (result && result.data.getFormattedQuestion) {
            nextQuestion = result.data.getFormattedQuestion;
            if (nextQuestion.passageId) {
              if (moduleSettings.loadedPassages[nextQuestion.passageId]) {
                nextQuestion.src =
                  moduleSettings.loadedPassages[nextQuestion.passageId];
              } else {
                const passageResult = await getFormattedPassage({
                  variables: { passage_id: nextQuestion.passageId },
                });
                if (passageResult && passageResult.data.getFormattedPassage) {
                  nextQuestion.src = passageResult.data.getFormattedPassage;
                  newPassages[nextQuestion.passageId] =
                    passageResult.data.getFormattedPassage;
                  addedPassage = true;
                }
              }
            }
            const newQuestionIndex = newQuestions.findIndex(
              (q) => q.id === nextQuestion.id
            );
            newQuestions.splice(newQuestionIndex, 1, nextQuestion);
            changedQuestions = true;
          }
        }
        setModuleSettings({
          ...moduleSettings,
          currentQuestion: nextQuestion,
          selectedAnswer: review.chosen,
          ...(changedQuestions ? { questions: newQuestions } : {}),
          ...(addedPassage ? { loadedPassages: newPassages } : {}),
          openReview: false,
          currentQuestionNumber: questionNumber,
          incorrectIndex: moduleSettings.incorrect.findIndex(
            (i) => i === questionNumber
          ),
        });
        setLoading(false);
        return;
      } else if (moduleSettings.moduleType === "Training") {
        let answers = await moduleFunctions.mfSaveAnswer();

        const currentQIndex = moduleSettings.questions.findIndex(
          (q) => q.order === questionNumber
        );
        let currentQuestion = moduleSettings.questions[currentQIndex];

        let newAnswers = answers;
        let score;

        if (moduleSettings.currentPart === 1) {
          let numCorrect = newAnswers.filter((a) => a.isCorrect).length;
          score = `${
            (numCorrect * 100) /
            moduleSettings.selectedModule.firstGroupQuestions
          }`;
        } else if (moduleSettings.currentPart === 2) {
          let numCorrect = newAnswers
            .slice(moduleSettings.selectedModule.firstGroupQuestions)
            .filter((a) => a.isCorrect).length;
          score = `${
            (numCorrect * 100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
          }`;
        } else {
          let numCorrect = moduleSettings.partOneAllCorrect
            ? newAnswers
                .slice(moduleSettings.selectedModule.firstGroupQuestions)
                .filter((a) => a.isCorrect).length
            : newAnswers
                .slice(moduleSettings.selectedModule.totalQuestions)
                .filter((a) => a.isCorrect).length;
          score = `${
            (numCorrect * 100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
          }`;
        }

        await updateSitting({
          variables: {
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
            up_to: questionNumber.toString(),
            time_taken: answers[answers.length - 1].workingTime,
            current_section: moduleSettings.currentPart,
            score,
            part_one_all_correct:
              moduleSettings.partOneAllCorrect === true ? true : false,
            section_one_pass:
              moduleSettings.sectionOnePass === true ? true : false,
            section_two_pass:
              moduleSettings.sectionTwoPass === true ? true : false,
          },
        });

        let newAnswer;
        const answerIndex = moduleSettings.answers.findIndex(
          (a) => a.questionNumber === currentQuestion.order
        );

        if (answerIndex >= 0) {
          newAnswer = moduleSettings.answers[answerIndex].chosen;
        } else {
          newAnswer = "";
        }
        let newQuestions = [...moduleSettings.questions];

        let changedQuestions = false;
        let newPassages = { ...moduleSettings.loadedPassages };
        let addedPassage = false;
        if (!currentQuestion.content) {
          const result = await getFormattedQuestion({
            variables: {
              question_id: currentQuestion.id,
              is_review: true,
            },
          });
          if (result && result.data.getFormattedQuestion) {
            let nextQuestion = result.data.getFormattedQuestion;

            nextQuestion.order = questionNumber;

            if (nextQuestion.passageId) {
              if (moduleSettings.loadedPassages[nextQuestion.passageId]) {
                nextQuestion.src =
                  moduleSettings.loadedPassages[nextQuestion.passageId];
              } else {
                const passageResult = await getFormattedPassage({
                  variables: { passage_id: nextQuestion.passageId },
                });
                if (passageResult && passageResult.data.getFormattedPassage) {
                  nextQuestion.src = passageResult.data.getFormattedPassage;
                  newPassages[nextQuestion.passageId] =
                    passageResult.data.getFormattedPassage;
                  addedPassage = true;
                }
              }
            }

            currentQuestion = nextQuestion;

            newQuestions.splice(currentQIndex, 1, currentQuestion);
            changedQuestions = true;
          }
        }

        setModuleSettings({
          ...moduleSettings,
          currentQuestionNumber: questionNumber,
          currentQuestion,
          selectedAnswer: newAnswer,
          answers: [...answers],
          ...(changedQuestions
            ? {
                questions: newQuestions,
              }
            : {}),
          ...(addedPassage ? { loadedPassages: newPassages } : {}),
          initialAnswer: newAnswer,
          startTime: moment(),
          endTime: null,
          openReview: false,
          isTrainingAnswerCorrect: false,
          isResultShown: newAnswer.is_result_shown
            ? newAnswer.is_result_shown
            : false,
          readOnly: newAnswer.read_only ? newAnswer.read_only : false,
          totalTimeTakenPartOne:
            questionNumber <=
            moduleSettings.selectedModule.firstGroupQuestions + 1
              ? answers
                  .map((a) => a.workingTime)
                  .reduce((acc, curr) => acc + curr, 0)
              : moduleSettings.totalTimeTakenPartOne,
          totalTimeTakenPartTwo:
            questionNumber >
              moduleSettings.selectedModule.firstGroupQuestions + 1 &&
            questionNumber <= moduleSettings.selectedModule.totalQuestions + 1
              ? answers
                  .map((a) => a.workingTime)
                  .reduce((acc, curr) => acc + curr, 0)
              : moduleSettings.totalTimeTakenPartTwo,
          totalTimeTakenPartThree:
            questionNumber > moduleSettings.selectedModule.totalQuestions + 1
              ? moduleSettings.partOneAllCorrect
                ? answers
                    .slice(moduleSettings.selectedModule.firstGroupQuestions)
                    .map((a) => a.workingTime)
                    .reduce((acc, curr) => acc + curr, 0)
                : answers
                    .slice(moduleSettings.selectedModule.totalQuestions)
                    .map((a) => a.workingTime)
                    .reduce((acc, curr) => acc + curr, 0)
              : moduleSettings.totalTimeTakenPartThree,
          totalScorePartOne:
            questionNumber <=
            moduleSettings.selectedModule.firstGroupQuestions + 1
              ? Math.ceil(
                  (answers.filter((a) => a.isCorrect).length * 100) /
                    moduleSettings.selectedModule.firstGroupQuestions
                )
              : moduleSettings.totalScorePartOne,
          totalScorePartTwo:
            questionNumber >
              moduleSettings.selectedModule.firstGroupQuestions + 1 &&
            questionNumber <= moduleSettings.selectedModule.totalQuestions + 1
              ? Math.ceil(
                  (answers
                    .slice(moduleSettings.selectedModule.firstGroupQuestions)
                    .filter((a) => a.isCorrect).length *
                    100) /
                    (moduleSettings.selectedModule.totalQuestions -
                      moduleSettings.selectedModule.firstGroupQuestions)
                )
              : moduleSettings.totalScorePartTwo,
          totalScorePartThree:
            questionNumber > moduleSettings.selectedModule.totalQuestions + 1
              ? moduleSettings.currentSitting.isRedo
                ? Math.ceil(
                    (answers
                      .slice(moduleSettings.selectedModule.totalQuestions)
                      .filter((a) => a.isCorrect).length *
                      100) /
                      (moduleSettings.selectedModule.totalQuestions -
                        moduleSettings.selectedModule.firstGroupQuestions)
                  )
                : moduleSettings.partOneAllCorrect
                ? Math.ceil(
                    (answers
                      .slice(moduleSettings.selectedModule.firstGroupQuestions)
                      .filter((a) => a.isCorrect).length *
                      100) /
                      (moduleSettings.selectedModule.totalQuestions -
                        moduleSettings.selectedModule.firstGroupQuestions)
                  )
                : Math.ceil(
                    (answers
                      .slice(moduleSettings.selectedModule.totalQuestions)
                      .filter((a) => a.isCorrect).length *
                      100) /
                      (moduleSettings.selectedModule.totalQuestions -
                        moduleSettings.selectedModule.firstGroupQuestions)
                  )
              : moduleSettings.totalScorePartThree,
        });
        setLoading(false);
        return;
      } else if (!moduleSettings.selectedModule.isAdaptive) {
        let answers = await moduleFunctions.mfSaveAnswer();

        let currentQuestion = moduleSettings.questions[questionNumber - 1];

        await updateSitting({
          variables: {
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
            up_to: questionNumber.toString(),
          },
        });

        let newAnswer;
        const answerIndex = moduleSettings.answers.findIndex(
          (a) => a.questionId === currentQuestion.id
        );

        if (answerIndex >= 0) {
          newAnswer = moduleSettings.answers[answerIndex].chosen;
        } else {
          newAnswer = "";
        }
        let newQuestions = [...moduleSettings.questions];

        let changedQuestions = false;
        let newPassages = { ...moduleSettings.loadedPassages };
        let addedPassage = false;
        if (!currentQuestion.content) {
          const result = await getFormattedQuestion({
            variables: {
              question_id: currentQuestion.id,
              is_review: false,
            },
          });
          if (result && result.data.getFormattedQuestion) {
            let nextQuestion = result.data.getFormattedQuestion;

            if (nextQuestion.passageId) {
              if (moduleSettings.loadedPassages[nextQuestion.passageId]) {
                nextQuestion.src =
                  moduleSettings.loadedPassages[nextQuestion.passageId];
              } else {
                const passageResult = await getFormattedPassage({
                  variables: { passage_id: nextQuestion.passageId },
                });
                if (passageResult && passageResult.data.getFormattedPassage) {
                  nextQuestion.src = passageResult.data.getFormattedPassage;
                  newPassages[nextQuestion.passageId] =
                    passageResult.data.getFormattedPassage;
                  addedPassage = true;
                }
              }
            }
            currentQuestion = nextQuestion;
            const newQuestionIndex = newQuestions.findIndex(
              (q) => q.id === currentQuestion.id
            );

            newQuestions.splice(newQuestionIndex, 1, currentQuestion);
            changedQuestions = true;
          }
        }
        setModuleSettings({
          ...moduleSettings,
          currentQuestionNumber: questionNumber,
          currentQuestion,
          selectedAnswer: newAnswer,
          answers: [...answers],
          ...(changedQuestions ? { questions: newQuestions } : {}),
          ...(addedPassage ? { loadedPassages: newPassages } : {}),
          initialAnswer: newAnswer,
          startTime: moment(),
          openReview: false,
        });
        setLoading(false);
        return;
      }
      const answers = await moduleFunctions.mfSaveAnswer();
      let newAnswer;
      if (moduleSettings.answers[questionNumber - 1]) {
        newAnswer = moduleSettings.answers[questionNumber - 1].chosen;
      } else {
        newAnswer = "";
      }
      let nextQuestion;
      let incrementTotal = false;
      let newQuestions = [...moduleSettings.questions];
      let changedQuestions = false;
      let newPassages = { ...moduleSettings.loadedPassages };
      let addedPassage = false;
      let nextPassageLeft = -1;
      let updatedPicked = { ...moduleSettings.questionsPicked };
      let updatePicked = false;
      let newSitting = { ...moduleSettings.currentSitting };
      if (
        moduleSettings.currentQuestionNumber ===
          moduleSettings.totalQuestionNumber &&
        questionNumber === moduleSettings.currentQuestionNumber + 1
      ) {
        const answer = answers[moduleSettings.currentQuestionNumber - 1];
        await updateSitting({
          variables: {
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
            up_to: questionNumber.toString(),
          },
        });
        incrementTotal = true;
        let newRoute = [];

        let newMarks;
        let newZetas;
        let newAbilities;
        if (answer.isCorrect !== null) {
          let markedReturn = markQuestion(answers);
          newMarks = markedReturn.newMarks;
          newZetas = markedReturn.newZetas;
          newAbilities = markedReturn.newAbilities;
        }

        if (moduleSettings.totalQuestionNumber < moduleSettings.route.length) {
          const nextQuestionId = moduleSettings.route[questionNumber - 1].id;
          const foundQuestion = moduleSettings.questions.find(
            (q) => q.id === nextQuestionId
          );

          if (foundQuestion.content) {
            nextQuestion = foundQuestion;
          } else {
            const result = await getFormattedQuestion({
              variables: {
                question_id: nextQuestionId,
                is_review:
                  moduleSettings.moduleType === "Training" ? true : false,
                test_id: moduleSettings.selectedModule.id,
                is_adaptive: true,
              },
            });
            if (result && result.data.getFormattedQuestion) {
              nextQuestion = result.data.getFormattedQuestion;
              if (nextQuestion.passageId) {
                if (moduleSettings.loadedPassages[nextQuestion.passageId]) {
                  nextQuestion.src =
                    moduleSettings.loadedPassages[nextQuestion.passageId];
                } else {
                  const passageResult = await getFormattedPassage({
                    variables: { passage_id: nextQuestion.passageId },
                  });
                  if (passageResult && passageResult.data.getFormattedPassage) {
                    nextQuestion.src = passageResult.data.getFormattedPassage;
                    newPassages[nextQuestion.passageId] =
                      passageResult.data.getFormattedPassage;
                    addedPassage = true;
                  }
                }
              }
              const newQuestionIndex = newQuestions.findIndex(
                (q) => q.id === nextQuestion.id
              );
              newQuestions.splice(newQuestionIndex, 1, nextQuestion);
              changedQuestions = true;
            }
          }
        } else {
          let nextLowest;
          while (true) {
            nextLowest =
              moduleSettings.lowest[
                Math.floor(Math.random() * moduleSettings.lowest.length)
              ];
            if (
              moduleSettings.questionsPicked[`${nextLowest.name}`] <
              moduleSettings.limit[`${nextLowest.name}`]
            ) {
              break;
            }
          }

          let questionList = [];
          let strandAbility = newAbilities
            ? newAbilities[`${nextLowest.strand}`]
            : moduleSettings.abilities[`${nextLowest.strand}`];
          let ability =
            strandAbility * (moduleSettings.selectedModule.variance / 3) +
            moduleSettings.selectedModule.mean;
          let gap = 0.1;
          let newQuestions = [];
          let routeIds = moduleSettings.route.map((r) => r.id);

          if (moduleSettings.passages?.length > 0) {
            let lastMark;
            if (moduleSettings.passageLeft === 0) {
              if (strandAbility === 0.0) {
                ability =
                  newAbilities.All *
                    (moduleSettings.selectedModule.variance / 3) +
                  moduleSettings.selectedModule.mean;
                lastMark = newMarks.All[newMarks.All.length - 1];
              } else {
                lastMark =
                  newMarks[`${nextLowest.strand}`][
                    newMarks[`${nextLowest.strand}`].length - 1
                  ];
              }

              let currPassages = [
                ...new Set(moduleSettings.route.map((r) => r.passageId)),
              ];
              let passageList = [];
              let nextPassage;

              while (newQuestions.length === 0) {
                if (lastMark === 0) {
                  passageList = moduleSettings.passages.filter(
                    (p) =>
                      p.difficulty > ability - gap &&
                      p.difficulty < ability + gap / 10
                  );
                } else {
                  passageList = moduleSettings.passages.filter(
                    (p) =>
                      p.difficulty > ability - gap / 10 &&
                      p.difficulty < ability + gap
                  );
                }
                passageList = passageList.filter(
                  (p) => !currPassages.includes(p.passageId)
                );
                if (passageList.length > 0) {
                  questionList = moduleSettings.questions.filter(
                    (q) =>
                      passageList.find((p) => p.passageId === q.passageId) &&
                      q.strand === nextLowest.strand
                  );
                  if (questionList.length === 0) {
                    gap += 0.05;
                    continue;
                  }
                  let pickedQuestion =
                    questionList[
                      Math.floor(Math.random() * questionList.length)
                    ];
                  nextPassage = passageList.find(
                    (p) => p.passageId === pickedQuestion.passageId
                  );
                  questionList = moduleSettings.questions
                    .filter((q) => q.passageId === nextPassage.passageId)
                    .sort((a, b) => a.difficulty - b.difficulty);
                  newQuestions.push(
                    questionList[Math.floor(questionList.length / 2)]
                  );
                }
                gap += 0.05;
              }
              nextPassageLeft = nextPassage.numQuestions - 1;
            } else {
              while (newQuestions.length === 0) {
                if (!answer.isCorrect) {
                  questionList = moduleSettings.questions.filter(
                    (q) =>
                      q.difficulty > ability - 0.1 - gap &&
                      q.difficulty < ability - 0.1 + gap / 10 &&
                      q.passageId ===
                        moduleSettings.route[moduleSettings.route.length - 1]
                          .passageId
                  );
                } else {
                  questionList = moduleSettings.questions.filter(
                    (q) =>
                      q.difficulty > ability - 0.1 - gap &&
                      q.difficulty < ability - 0.1 + gap / 10 &&
                      q.passageId ===
                        moduleSettings.route[moduleSettings.route.length - 1]
                          .passageId
                  );
                }
                newQuestions = questionList.filter(
                  (q) => !routeIds.includes(q.id)
                );
                gap += 0.05;
              }
              nextPassageLeft = moduleSettings.passageLeft - 1;
            }
          } else {
            while (newQuestions.length === 0) {
              if (answer.isCorrect) {
                questionList = moduleSettings.questions.filter(
                  (q) =>
                    q.difficulty > ability + 0.1 - gap / 10 &&
                    q.difficulty < ability + 0.1 + gap &&
                    q.lowest === nextLowest.name
                );
              } else {
                questionList = moduleSettings.questions.filter(
                  (q) =>
                    q.difficulty > ability - 0.1 - gap &&
                    q.difficulty < ability - 0.1 + gap / 10 &&
                    q.lowest === nextLowest.name
                );
              }

              newQuestions = questionList.filter(
                (q) => !routeIds.includes(q.id)
              );
              gap += 0.05;
            }
          }

          nextQuestion =
            newQuestions[Math.floor(Math.random() * newQuestions.length)];
          updatedPicked[`${nextQuestion.lowest}`]++;
          updatePicked = true;
          const result = await getFormattedQuestion({
            variables: {
              question_id: nextQuestion.id,
              is_review:
                moduleSettings.moduleType === "Training" ? true : false,
              test_id: moduleSettings.selectedModule.id,
              is_adaptive: true,
            },
          });
          if (result && result.data.getFormattedQuestion) {
            nextQuestion = result.data.getFormattedQuestion;
            if (nextQuestion.passageId) {
              if (moduleSettings.loadedPassages[nextQuestion.passageId]) {
                nextQuestion.src =
                  moduleSettings.loadedPassages[nextQuestion.passageId];
              } else {
                const passageResult = await getFormattedPassage({
                  variables: { passage_id: nextQuestion.passageId },
                });
                if (passageResult && passageResult.data.getFormattedPassage) {
                  nextQuestion.src = passageResult.data.getFormattedPassage;
                  newPassages[nextQuestion.passageId] =
                    passageResult.data.getFormattedPassage;
                  addedPassage = true;
                }
              }
            }
            const newQuestionIndex = newQuestions.findIndex(
              (q) => q.id === nextQuestion.id
            );
            newQuestions.splice(newQuestionIndex, 1, nextQuestion);
            changedQuestions = true;
          }
          newRoute = [
            ...moduleSettings.route,
            {
              id: nextQuestion.id,
              strand: nextQuestion.strand,
              passageId: nextQuestion.passageId,
            },
          ];
        }
        setLoading(false);
        setModuleSettings({
          ...moduleSettings,
          currentQuestionNumber: questionNumber,
          currentQuestion: nextQuestion,
          selectedAnswer: newAnswer,
          answers: [...answers],
          ...(incrementTotal ? { totalQuestionNumber: questionNumber } : {}),
          ...(newMarks ? { marks: newMarks } : {}),
          ...(newZetas ? { zetas: newZetas } : {}),
          ...(newAbilities ? { abilities: newAbilities } : {}),
          ...(newRoute.length > 0 ? { route: newRoute } : {}),
          ...(changedQuestions ? { questions: newQuestions } : {}),
          ...(addedPassage ? { loadedPassages: newPassages } : {}),
          initialAnswer: newAnswer,
          startTime: moment(),
          ...(nextPassageLeft >= 0 ? { passageLeft: nextPassageLeft } : {}),
          ...(updatePicked ? { questionsPicked: updatedPicked } : {}),
          currentSitting: newSitting,
        });
      } else {
        let nextQuestionId = moduleSettings.route[questionNumber - 1].id;
        const foundQuestion = moduleSettings.questions.find(
          (q) => q.id === nextQuestionId
        );

        if (foundQuestion.content) {
          nextQuestion = foundQuestion;
        } else {
          const result = await getFormattedQuestion({
            variables: {
              question_id: nextQuestionId,
              is_review:
                moduleSettings.moduleType === "Training" ? true : false,
              test_id: moduleSettings.selectedModule.id,
              is_adaptive: true,
            },
          });
          if (result && result.data.getFormattedQuestion) {
            nextQuestion = result.data.getFormattedQuestion;
            if (nextQuestion.passageId) {
              if (moduleSettings.loadedPassages[nextQuestion.passageId]) {
                nextQuestion.src =
                  moduleSettings.loadedPassages[nextQuestion.passageId];
              } else {
                const passageResult = await getFormattedPassage({
                  variables: { passage_id: nextQuestion.passageId },
                });
                if (passageResult && passageResult.data.getFormattedPassage) {
                  nextQuestion.src = passageResult.data.getFormattedPassage;
                  newPassages[nextQuestion.passageId] =
                    passageResult.data.getFormattedPassage;
                  addedPassage = true;
                }
              }
            }
            const newQuestionIndex = newQuestions.findIndex(
              (q) => q.id === nextQuestion.id
            );
            newQuestions.splice(newQuestionIndex, 1, nextQuestion);
            changedQuestions = true;
          }
        }
        setModuleSettings({
          ...moduleSettings,
          currentQuestionNumber: questionNumber,
          currentQuestion: nextQuestion,
          selectedAnswer: newAnswer,
          answers: [...answers],
          ...(changedQuestions ? { questions: newQuestions } : {}),
          ...(addedPassage ? { loadedPassages: newPassages } : {}),
          openReview: false,
          initialAnswer: newAnswer,
          startTime: moment(),
        });
        setLoading(false);
      }
    },
    finishSitting: async (module, type) => {
      if (module && type === "Interactive") {
        const result = await finishSitting({
          variables: {
            module_id: module.id,
            module_type: type,
            sitting_id: module.TrainingSittings[0].id,
          },
        });
        setLoading(false);
        if (result && result.data) return;
      }
      if (
        moduleSettings.moduleType === "Diagnostic" &&
        !moduleSettings.currentSitting.route
      ) {
        const result = await finishSitting({
          variables: {
            module_id: moduleSettings.selectedModule.id,
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting?.id,
          },
        });
        if (result && result.data) return;
      }
      const answers = await moduleFunctions.mfSaveAnswer();
      let score;
      if (moduleSettings.moduleType === "Diagnostic") {
        const result = await finishSitting({
          variables: {
            module_id: moduleSettings.selectedModule.id,
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
          },
        });
        if (result && result.data) return;
      } else if (moduleSettings.moduleType === "Training") {
        let numCorrect = moduleSettings.partOneAllCorrect
          ? answers
              .slice(moduleSettings.selectedModule.firstGroupQuestions)
              .filter((a) => a.isCorrect).length
          : answers
              .slice(moduleSettings.selectedModule.totalQuestions)
              .filter((a) => a.isCorrect).length;
        score = `${
          (numCorrect * 100) /
          (moduleSettings.selectedModule.totalQuestions -
            moduleSettings.selectedModule.firstGroupQuestions)
        }`;

        const result = await finishSitting({
          variables: {
            module_id: moduleSettings.selectedModule.id,
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
            score,
          },
        });
        if (result && result.data) return;
      } else if (moduleSettings.moduleType === "Interactive") {
        const result = await finishSitting({
          variables: {
            module_id: moduleSettings.selectedModule.id,
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
          },
        });
        if (result && result.data) return;
      } else {
        if (moduleSettings.selectedModule.isAdaptive) {
          const answer = answers[moduleSettings.currentQuestionNumber - 1];
          if (answer.isCorrect !== null) {
            const { newAbilities } = markQuestion(answers);
            score = JSON.stringify(newAbilities);
          } else {
            score = JSON.stringify(moduleSettings.abilities);
          }
        } else {
          let numCorrect = answers.filter((a) => a.isCorrect).length;
          score = `${numCorrect}/${moduleSettings.selectedModule.totalQuestions}`;
        }
        const result = await finishSitting({
          variables: {
            module_id: moduleSettings.selectedModule.id,
            module_type: moduleSettings.moduleType,
            sitting_id: moduleSettings.currentSitting.id,
            score,
          },
        });
        if (result && result.data) return;
      }
    },
    deleteTrainingAnswers,
    deleteSitting,
    updateSitting,
    exitSitting,
  };

  const moduleValues = {
    moduleSettings,
    ...moduleFunctions,
    setModuleSettings,
  };

  useEffect(() => {
    // console.log(moduleSettings);
    sessionStorage.setItem("moduleSettings", JSON.stringify(moduleSettings));
  }, [moduleSettings]);

  useEffect(() => {
    if (Object.keys(preferences).length === 0) {
      const localPref = localStorage.getItem("preferences");
      if (localPref) {
        if (localPref.lastUpdate === "24/8/2023") {
          setPreferences({ ...JSON.parse(localPref) });
        } else {
          setPreferences({ ...defaults });
        }
      } else {
        setPreferences({ ...defaults });
      }
    } else {
      localStorage.setItem("preferences", JSON.stringify(preferences));
    }
  }, [preferences]);

  useEffect(() => {
    sessionStorage.setItem(
      "studentMonitoringData",
      JSON.stringify(studentMonitoringData)
    );
  }, [studentMonitoringData]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthenticatedContext.Provider value={globalSettings}>
        <ModuleContext.Provider value={moduleValues}>
          <StudentMonitoringContext.Provider
            value={{ studentMonitoringData, setStudentMonitoringData }}
          >
            <TimerContext.Provider value={timeSettings}>
              <PreferenceContext.Provider
                value={{ preferences, setPreferences, defaults }}
              >
                <ThemeProvider theme={theme}>
                  <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={5000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    TransitionComponent={Fade}
                  >
                    {loading && (
                      <Backdrop
                        sx={{
                          zIndex: (theme) => theme.zIndex.drawer + 10000,
                        }}
                        open={loading}
                      >
                        <CircularProgress />
                      </Backdrop>
                    )}

                    <Router>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100vh",
                          position: "relative",
                        }}
                      >
                        <CssBaseline />
                        {isAuthenticated ? (
                          <Switch>
                            <Route exact path="/payment">
                              <Payment />
                            </Route>
                            <Route exact path="/report/:program">
                              <PlacementReport />
                            </Route>
                            <Route exact path="/report/:program/:id">
                              <PlacementReport />
                            </Route>
                            <Layout>
                              <Route exact path="/dashboard">
                                <Dashboard />
                              </Route>
                              <Route exact path="/tests">
                                <Tests />
                              </Route>
                              <Route exact path="/exercises">
                                <Exercises />
                              </Route>
                              <Route exact path="/progressreport">
                                <ProgressReport />
                              </Route>
                              {/* <Route exact path="/testinstructions">
                                  <Instructions />
                                </Route>
                                <Route exact path="/exerciseinstructions">
                                  <Instructions />
                                </Route> */}
                              <Route exact path="/instructions">
                                <InstructionsPage />
                              </Route>
                              <Route exact path="/updatedetails">
                                <UpdateDetails />
                              </Route>
                              <Route exact path="/usermanagement">
                                <UserManagement />
                              </Route>
                              <Route exact path="/placement-question">
                                {moduleSettings.openReview ? (
                                  <TestReviewSection />
                                ) : (
                                  <Question />
                                )}
                              </Route>
                              <Route exact path="/training-question">
                                {moduleSettings.openReview ? (
                                  <TestReviewSection />
                                ) : (
                                  <Question />
                                )}
                              </Route>
                              <Route exact path="/assessment-question">
                                {moduleSettings.openReview ? (
                                  <TestReviewSection />
                                ) : (
                                  <Question />
                                )}
                              </Route>
                              <Route exact path="/studentmanagement">
                                <StudentManagement />
                              </Route>
                              <Route exact path="/studentmanagement/:id">
                                <StudentDetailManagement />
                              </Route>
                              <Route exact path="/testmanagement">
                                <TestManagement />
                              </Route>
                              <Route
                                exact
                                path="/testmanagement/:moduleTypeId/:id"
                              >
                                <TestDetailManagement />
                              </Route>
                              <Route exact path="/trainingmanagement">
                                <TrainingManagement />
                              </Route>
                              <Route exact path="/trainingmanagement/:id">
                                <TrainingDetailManagement />
                              </Route>
                              <Route exact path="/reportmanagement">
                                <ReportManagement />
                              </Route>
                              <Route exact path="/studenttraining">
                                <StudentTraining />
                              </Route>
                              <Route exact path="/reportmanagement/:id">
                                <ReportDetailManagement />
                              </Route>
                              <Route exact path="/classmanagement">
                                <ClassManagement />
                              </Route>
                              <Route exact path="/classmanagementteacher">
                                <ClassManagementTeacher />
                              </Route>
                              <Route exact path="/classmanagementteacher/:id">
                                <IndividualStudentMonitor />
                              </Route>
                              <Route exact path="/classmanagement/:id">
                                <ClassDetailManagement />
                              </Route>
                              <Route exact path="/coursemanagement">
                                <CourseManagement />
                              </Route>
                              <Route exact path="/coursemanagement/:id">
                                <CourseDetailManagement />
                              </Route>
                              <Route exact path="/programmanagement">
                                <ProgramManagement />
                              </Route>
                              <Route exact path="/programmanagement/:id">
                                <ProgramDetailManagement />
                              </Route>
                              <Route
                                exact
                                path="/classmanagementteacher/:classId/:trainingId"
                              >
                                <ClassTrainingView />
                              </Route>
                              {/* <Route exact path="/medals">
                                  <Medals />
                                </Route> */}
                              <Route exact path="/curriculum/:id">
                                <Curriculum />
                              </Route>
                            </Layout>
                          </Switch>
                        ) : (
                          <ExternalRoute path="/payment" component={Payment} />
                        )}

                        <Route exact path="/">
                          {isAuthenticated ? (
                            <Redirect to="/dashboard" />
                          ) : (
                            <Signin />
                          )}
                        </Route>
                        <Footer />
                      </Box>
                    </Router>
                  </SnackbarProvider>
                </ThemeProvider>
              </PreferenceContext.Provider>
            </TimerContext.Provider>
          </StudentMonitoringContext.Provider>
        </ModuleContext.Provider>
      </AuthenticatedContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
