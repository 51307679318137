import React, { useState } from "react";
import { Box } from "@mui/system";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { Split } from "@geoffcox/react-splitter";
import parse from "html-react-parser";

import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { ModuleContext } from "../../App";
import { useEffect } from "react";

const WordExplanation = ({
  currentQuestion: {
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution,
  },
}) => {
  const moduleValue = React.useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };

  // const getCorrectAnswer = (CorrectAnswer) => {
  //   switch (parseInt(CorrectAnswer)) {
  //     case 1:
  //       return Answer1 ? parse(Answer1, options) : "";
  //     case 2:
  //       return Answer2 ? parse(Answer2, options) : "";
  //     case 3:
  //       return Answer3 ? parse(Answer3, options) : "";
  //     case 4:
  //       return Answer4 ? parse(Answer4, options) : "";
  //     case 5:
  //       return Answer5 ? parse(Answer5, options) : "";
  //     case 6:
  //       return Answer6 ? parse(Answer6, options) : "";
  //     case 7:
  //       return Answer7 ? parse(Answer7, options) : "";
  //     case 8:
  //       return Answer8 ? parse(Answer8, options) : "";
  //     case 9:
  //       return Answer9 ? parse(Answer9, options) : "";
  //     case 10:
  //       return Answer10 ? parse(Answer10, options) : "";
  //     default:
  //       return null;
  //   }
  // };

  const explanationArray = () => correctAns?.split("[split]");
  const questionSplit = () => content.split("[split]");

  const [buttonSelect, setButtonSelect] = useState({
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
  });

  useEffect(() => {
    let newAnswer = Object.entries(buttonSelect)
      .map((e, i) => [i + 1, e[1]])
      .filter((e) => e[1] === true)
      .map((e) => e[0])
      .join(",");
    moduleValue.setModuleSettings({
      ...moduleValue.moduleSettings,
      selectedAnswer: newAnswer,
    });
  }, [buttonSelect, moduleValue]);

  const isAnyButtonSelected = () => {
    return Object.values(buttonSelect).includes(true);
  };

  const handleSelect = (e) => {
    setButtonSelect({
      ...buttonSelect,
      [e.target.name]: !buttonSelect[e.target.name],
    });
  };

  return (
    <Split
      minPrimarySize="15%"
      minSecondarySize="15%"
      initialPrimarySize="50%"
      resetOnDoubleClick
      splitterSize="48px"
      defaultSplitterColors={splitterColors}
    >
      <Box>
        {src && <Typography variant="h6">{parse(src, options)}</Typography>}
        {!src && content && (
          <Typography variant="h6">
            {parse(questionSplit()[0], options)}
          </Typography>
        )}
      </Box>
      <Box sx={{ pb: 2 }}>
        {src && content && (
          <Typography variant="h6">
            {parse(questionSplit()[0], options)}
          </Typography>
        )}
        {questionSplit().length > 1 && (
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            sx={{ mb: 3 }}
          >
            <Typography variant="subtitle1">
              {parse(questionSplit()[1], options)}
            </Typography>

            {questionSplit().length > 2 && (
              <>
                <Button
                  variant={buttonSelect.button1 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button1"
                  // disabled={isButtonSelected("button1")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer1}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[2], options)}
                </Typography>
              </>
            )}

            {questionSplit().length > 3 && (
              <>
                <Button
                  variant={buttonSelect.button2 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button2"
                  // disabled={isButtonSelected("button2")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer2}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[3], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 4 && (
              <>
                <Button
                  variant={buttonSelect.button3 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button3"
                  // disabled={isButtonSelected("button3")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer3}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[4], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 5 && (
              <>
                <Button
                  variant={buttonSelect.button4 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button4"
                  // disabled={isButtonSelected("button4")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer4}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[5], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 6 && (
              <>
                <Button
                  variant={buttonSelect.button5 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button5"
                  // disabled={isButtonSelected("button5")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer5}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[6], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 7 && (
              <>
                <Button
                  variant={buttonSelect.button6 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button6"
                  // disabled={isButtonSelected("button6")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer6}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[7], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 8 && (
              <>
                <Button
                  variant={buttonSelect.button7 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button7"
                  // disabled={isButtonSelected("button7")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer7}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[8], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 9 && (
              <>
                <Button
                  variant={buttonSelect.button8 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button8"
                  // disabled={isButtonSelected("button8")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer8}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[9], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 10 && (
              <>
                <Button
                  variant={buttonSelect.button9 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button9"
                  // disabled={isButtonSelected("button9")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer9}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[10], options)}
                </Typography>
              </>
            )}
            {questionSplit().length > 11 && (
              <>
                <Button
                  variant={buttonSelect.button10 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button10"
                  // disabled={isButtonSelected("button10")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {answer10}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[11], options)}
                </Typography>
              </>
            )}
          </Stack>
        )}
        {correctAns && isAnyButtonSelected() && (
          <Alert severity="info" sx={{ mb: 2 }}>
            <strong></strong>
            <AlertTitle>Explanation:</AlertTitle>
            <p>
              {buttonSelect.button1 === true &&
                explanationArray().length >= 1 && (
                  <>
                    <strong>{answer1}</strong>
                    {": "}
                    {explanationArray()[0]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button2 === true &&
                explanationArray().length >= 2 && (
                  <>
                    <strong>{answer2}</strong>
                    {": "}
                    {explanationArray()[1]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button3 === true &&
                explanationArray().length >= 3 && (
                  <>
                    <strong>{answer3}</strong>
                    {": "}
                    {explanationArray()[2]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button4 === true &&
                explanationArray().length >= 4 && (
                  <>
                    <strong>{answer4}</strong>
                    {": "}
                    {explanationArray()[3]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button5 === true &&
                explanationArray().length >= 5 && (
                  <>
                    <strong>{answer5}</strong>
                    {": "}
                    {explanationArray()[4]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button6 === true &&
                explanationArray().length >= 6 && (
                  <>
                    <strong>{answer6}</strong>
                    {": "}
                    {explanationArray()[5]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button7 === true &&
                explanationArray().length >= 7 && (
                  <>
                    <strong>{answer7}</strong>
                    {": "}
                    {explanationArray()[5]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button8 === true &&
                explanationArray().length >= 8 && (
                  <>
                    <strong>{answer8}</strong>
                    {": "}
                    {explanationArray()[7]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button9 === true &&
                explanationArray().length >= 9 && (
                  <>
                    <strong>{answer9}</strong>
                    {": "}
                    {explanationArray()[8]}
                  </>
                )}
            </p>
            <p>
              {buttonSelect.button10 === true &&
                explanationArray().length >= 10 && (
                  <>
                    <strong>{answer10}</strong>
                    {": "}
                    {explanationArray()[9]}
                  </>
                )}
            </p>
          </Alert>
        )}
        {hint && (
          <Alert
            severity="info"
            iconMapping={{
              info: <WbIncandescentIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Hint: <br></br>
            </strong>
            {parse(hint, options)}
          </Alert>
        )}
        {solution && (
          <Alert
            severity="warning"
            iconMapping={{
              warning: <RadioButtonCheckedIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Solution: <br></br>
            </strong>
            {parse(solution, options)}
          </Alert>
        )}
      </Box>
    </Split>
  );
};

export default WordExplanation;
