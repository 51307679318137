import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { GET_COURSE_STUDENTS } from "../graphql/queries";
import { DEACTIVATE_TRAINING } from "../graphql/mutations";

// import PlacementReport from "./PlacementReport";

const CourseStudents = ({ course_id }) => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [chosenStudent] = useState("");
  const [setPayments] = useState([]);

  const [getCourseStudents, { studentsLoading }] = useLazyQuery(
    GET_COURSE_STUDENTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ getCourseStudents }) => {
        setRows(getCourseStudents.students);
        setPayments(getCourseStudents.payments);
      },
    }
  );

  const [deactivateTraining] = useMutation(DEACTIVATE_TRAINING, {
    onCompleted: () => {
      getCourseStudents({
        variables: {
          course_id,
        },
      });
      setOpen(false);
    },
  });

  useEffect(() => {
    getCourseStudents({
      variables: {
        course_id,
      },
    });
  }, [course_id]);

  const startDeactivation = () => {
    deactivateTraining({
      variables: {
        student_id: chosenStudent.row.id,
        course_id,
      },
    });
  };

  function getStudentName(params) {
    return params.row.givenname + " " + params.row.surname;
  }

  function getGrade(params) {
    return params.row.Grade?.name;
  }

  function getClass(params) {
    return params.row.Classes?.length > 0
      ? params.row.Classes[0].Class.name
      : "";
  }

  function getIsActivated(params) {
    // return params.row.Trainings[2].isPaid;
    return !!params.row.Trainings.find((t) => t.registrationId !== null);
  }

  function getCampus(params) {
    return params.row.Centre?.name;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      // {
      //   field: "actions",
      //   type: "actions",
      //   disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
      //   headerName: "Actions",
      //   flex: 1,
      //   headerAlign: "center",
      //   disableReorder: true,
      //   hideable: false,
      //   getApplyQuickFilterFn: undefined,
      //   getActions: (params) => [
      //     <GridActionsCellItem
      //       icon={<DeleteIcon />}
      //       label="Deactivate Training"
      //       color="primary"
      //       onClick={() => { setOpen(true); setChosenStudent(params) }}
      //       disabled={isDisabled(params) || !["Admin", "Education Consultant"].includes(userRole)}
      //     />,
      //   ],
      // },
      {
        field: "login",
        headerName: "Student Number",
        flex: 2,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "studentName",
        headerName: "Student Name",
        flex: 3,
        headerAlign: "center",
        align: "center",
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getStudentName,
      },
      {
        field: "grade",
        headerName: "Grade",
        headerAlign: "center",
        align: "center",
        flex: 2,
        getApplyQuickFilterFn: undefined,
        valueGetter: getGrade,
      },
      {
        field: "campus",
        headerName: "Campus",
        headerAlign: "center",
        align: "center",
        flex: 2,
        getApplyQuickFilterFn: undefined,
        valueGetter: getCampus,
      },
      {
        field: "class",
        headerName: "Class",
        flex: 3,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getClass,
      },
      // {
      //   field: "teacher",
      //   headerName: "Teacher",
      //   flex: 3,
      //   headerAlign: "center",
      //   align: "center",
      //   getApplyQuickFilterFn: getApplyFilterFnName,
      //   valueGetter: getTeacher,
      // },
      {
        field: "isPaid",
        headerName: "Training Activated?",
        flex: 1,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsActivated,
        type: "boolean",
      },
      // {
      //   field: "datePaid",
      //   headerName: "Date Paid At",
      //   flex: 1,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   getApplyQuickFilterFn: undefined,
      //   filterable: false,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy");
      //     }
      //   },
      //   valueGetter: getDate,
      //   align: "center",
      // }
    ],
    []
  );

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Deactivate Training</DialogTitle>
        <DialogContent>
          Are you sure you want to deactivate{" "}
          {chosenStudent.row &&
            `${chosenStudent.row.givenname} ${chosenStudent.row.surname}'s (${chosenStudent.row.login}) `}{" "}
          training modules?
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => startDeactivation()}>
            Deactivate Training
          </Button>
        </DialogActions>
      </Dialog>
      <DataGridPro
        rows={rows}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        disableSelectionOnClick
        autoHeight
        loading={studentsLoading}
        pagination
        getRowId={(row) => row.login}
        pinnedColumns={{ left: ["actions"] }}
      />
    </>
  );
};

export default CourseStudents;
