import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  GET_ALLOCATED_TESTS_BY_PROGRAM,
  GET_TESTS_BY_QUERY,
} from "../graphql/queries";
import {
  ALLOCATE_TESTS_TO_PROGRAM,
  DEALLOCATE_TEST,
} from "../graphql/mutations";
import { AuthenticatedContext } from "../App";

const SelectTest = ({ program_id }) => {
  const { userRole } = useContext(AuthenticatedContext);
  const { enqueueSnackbar } = useSnackbar();
  const [testName, setTestName] = useState("");
  const [testID, setTestID] = useState("");
  const [unselectedTests, setUnselectedTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteTestID, setDeleteTestID] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);

  const [getAllocatedTestsByProgram, { data: selectedTestsData }] =
    useLazyQuery(GET_ALLOCATED_TESTS_BY_PROGRAM, {
      fetchPolicy: "no-cache",
      onCompleted: ({ getAllocatedTestsByProgram }) => {
        setSelectedTests(getAllocatedTestsByProgram);
      },
      onError: (error) => {
        enqueueSnackbar(`${error}`, { variant: "error" });
      },
    });

  const [getTestsByQuery, { loading: getTestsByQueryLoading }] = useLazyQuery(
    GET_TESTS_BY_QUERY,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ getTestsByQuery }) => {
        if (getTestsByQuery.length !== 0) {
          if (selectedTestsData) {
            const testsIDs = selectedTestsData?.getAllocatedTestsByProgram?.map(
              (t) => t.id
            );
            const filteredTests = getTestsByQuery.filter((t) => {
              return !testsIDs.includes(t.id);
            });

            if (filteredTests?.length !== 0) {
              setUnselectedTests(filteredTests);
            } else {
              enqueueSnackbar("No tests found", { variant: "warning" });
              clearSearchData();
            }
          } else {
            setUnselectedTests(getTestsByQuery);
          }
        } else {
          enqueueSnackbar("No tests found", { variant: "warning" });
          clearSearchData();
        }
      },
      onError: (error) => {
        enqueueSnackbar(`${error}`, { variant: "error" });
      },
    }
  );

  const [deallocateTest] = useMutation(DEALLOCATE_TEST, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ deallocateTest }) => {
      if (deallocateTest) {
        enqueueSnackbar("Test has been successfully deallocated. ", {
          variant: "success",
        });
        await getTestsByQuery({
          variables: {
            ...(testName ? { test_name: testName } : {}),
            ...(testID ? { test_id: Number(testID) } : {}),
          },
        });
        setDeleteDialog(false);
      }
    },
    refetchQueries: [
      {
        query: GET_ALLOCATED_TESTS_BY_PROGRAM,
        variables: { program_id: Number(program_id) },
      },
      "getAllocatedTestsByProgram",
    ],
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });

  const [allocateTestsToProgram] = useMutation(ALLOCATE_TESTS_TO_PROGRAM, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ allocateTestsToProgram }) => {
      if (allocateTestsToProgram) {
        enqueueSnackbar(
          `${selectionModel?.length} Tests(s) has been successfully allocated.`,
          { variant: "success" }
        );
        if (selectionModel) {
          setUnselectedTests(
            unselectedTests?.filter((t) => !selectionModel.includes(t.id))
          );
        }
      }
    },
    refetchQueries: [
      {
        query: GET_ALLOCATED_TESTS_BY_PROGRAM,
        variables: { program_id: Number(program_id) },
      },
      "getAllocatedTestsByProgram",
    ],
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });

  useEffect(() => {
    if (program_id)
      getAllocatedTestsByProgram({
        variables: { program_id: Number(program_id) },
      });
  }, [program_id]);

  const clearSearchData = () => {
    setUnselectedTests([]);
    setTestID("");
    setTestName("");
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getTestsByQuery({
      variables: {
        ...(testName ? { test_name: testName } : {}),
        ...(testID ? { test_id: Number(testID) } : {}),
      },
    });
  };

  const removeTest = useCallback(
    (params) => () => {
      setDeleteTestID(params.id);
      setDeleteDialog(true);
    },
    []
  );

  const handleDeallocateTest = () => {
    deallocateTest({
      variables: {
        program_id: Number(program_id),
        test_id: Number(deleteTestID),
      },
    });
  };

  const handleAddTestsToProgram = () => {
    allocateTestsToProgram({
      variables: { program_id: Number(program_id), test_ids: selectionModel },
    });
  };

  const unselectedTestColumns = [
    {
      field: "id",
      headerName: "Test ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "name",
      headerName: "Test Name",
      flex: 5,
      headerAlign: "center",
      align: "center",
    },
  ];

  const selectedTestColumns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        hide: !["Admin", "Education Consultant"].includes(userRole),
        getActions: (params) =>
          ["Admin", "Education Consultant"].includes(userRole)
            ? [
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Remove"
                  color="primary"
                  onClick={removeTest(params)}
                  disabled={userRole !== "Admin"}
                />,
              ]
            : [],
      },
      {
        field: "id",
        headerName: "Test ID",
        flex: 1,
        headerAlign: "center",
        align: "center",
        type: "number",
      },
      {
        field: "name",
        headerName: "Test Name",
        flex: 5,
        headerAlign: "center",
        align: "center",
      },
    ],
    [removeTest, userRole]
  );

  return (
    <Paper elevation={0} sx={{ p: 1 }}>
      <Dialog open={deleteDialog}>
        <DialogTitle>Deselect Test</DialogTitle>
        <DialogContent>
          Are you sure you want to deselect this test?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeallocateTest}>Confirm</Button>
        </DialogActions>
      </Dialog>
      {userRole === "Admin" && (
        <>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSearchSubmit}
            sx={{ mb: 3 }}
          >
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="testName"
                  label="Test Name"
                  value={testName}
                  size="small"
                  onChange={(e) => setTestName(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="testID"
                  type="number"
                  label="Test ID"
                  value={testID}
                  size="small"
                  onChange={(e) => setTestID(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  type="submit"
                >
                  Search Test(s)
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 3 }} />
        </>
      )}

      <Grid
        container
        sx={{
          background: "#F8F8F8",
          border: "1px solid lightgray",
          p: 2,
          borderRadius: 1.2,
        }}
      >
        {userRole === "Admin" && (
          <>
            <Grid item xs={5.9}>
              <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
                Unselected Tests
              </Typography>
              <DataGridPro
                rows={unselectedTests}
                columns={unselectedTestColumns}
                // componentsProps={{
                //   toolbar: {
                //     showQuickFilter: true,
                //     quickFilterProps: { debounceMs: 500 },
                //   }
                // }}
                rowsPerPageOptions={[10, 25, 50, 100]}
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                autoHeight
                loading={getTestsByQueryLoading}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                density="compact"
              />
            </Grid>
            <Grid item xs={0.2} />
          </>
        )}
        <Grid item xs={userRole === "Admin" ? 5.9 : 12}>
          <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
            Selected Tests
          </Typography>
          <DataGridPro
            rows={selectedTests}
            columns={selectedTestColumns}
            density="compact"
            // componentsProps={{
            //   toolbar: {
            //     showQuickFilter: true,
            //     quickFilterProps: { debounceMs: 500 },
            //   }
            // }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            // loading={getStudentsByQueryLoading}
            pagination={true}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
          />
        </Grid>
        {userRole === "Admin" && (
          <Grid item xs={2} sx={{ mt: 2 }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={selectionModel && selectionModel?.length === 0}
              onClick={handleAddTestsToProgram}
              startIcon={<AddCircleIcon />}
            >
              Add Test(s)
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default SelectTest;
