import React from "react";
import { Container, Paper } from "@mui/material";

const ContainerPaper = ({
  children,
  maxWidth = "false",
  sxContainer = {},
  sxPaper = {},
}) => {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        mt: 3,
        mb: 3,
        position: "relative",

        ...sxContainer,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 3,
          position: "absolute",
          left: "10px",
          right: "10px",
          ...sxPaper,
        }}
      >
        {children}
      </Paper>
    </Container>
  );
};

export default ContainerPaper;
