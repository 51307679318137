import { Button, Container, Paper, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { format } from "date-fns";

const Tests = () => {
  /* const { data: tests, loading: testLoading } = useQuery(GET_TESTS, {
    fetchPolicy: "no-cache",
  });*/

  const renderAction = (status) => {
    if (status === 0) {
      return "Start";
    } else if (status === 1) {
      return "Resume";
    } else {
      return "Review";
    }
  };

  const tests = [
    {
      id: 0,
      testName: "Mathematics Placement Test",
      strand: "Number & Algebra",
      status: 2,
      result: 123,
      dueDate: new Date("01/29/2022"),
      completedOn: new Date("01/26/2022"),
    },
    {
      id: 1,
      testName: "Problem Solving Placement Test",
      strand: "Non-verbal Reasoning",
      status: 2,
      result: 123,
      dueDate: new Date("01/29/2022"),
      completedOn: new Date("01/26/2022"),
    },
    {
      id: 2,
      testName: "Mathematics Test 1",
      strand: "Number & Algebra",
      status: 1,
      result: null,
      dueDate: new Date("04/08/2022"),
      completedOn: null,
    },
    {
      id: 3,
      testName: "Problem Solving Test 1",
      strand: "Non-verbal Reasoning",
      status: 0,
      result: null,
      dueDate: new Date("04/08/2022"),
      completedOn: null,
    },
  ];

  const history = useHistory();
  const [rows] = useState(tests);
  const [pageSize, setPageSize] = useState(10);

  //console.log(tests);

  // useEffect(() => {
  //   if (tests) {
  //     const newRows = tests.tests.map((item) => ({
  //       id: item.TestID,
  //       testName: item.Name,
  //       subject: item.Topics ? findSubject(item.Topics) : "",
  //       status: item.Status ? item.Status : "",
  //       duration: item.Duration ? item.Duration : "",
  //       creationDate: item.CreatedAt ? new Date(item.CreatedAt) : "",
  //       lastRunDate: item.LastRunDate ? new Date(item.LastRunDate) : "",
  //       schoolYear: item.GradeID ? item.GradeID : "",
  //       age: item.age ? item.age : "",
  //       totalEligibleQuestions: item.TotalEligibleQuestions
  //         ? item.TotalEligibleQuestions
  //         : "",
  //       totalQuestions: item.TotalQuestions ? item.TotalQuestions : "",
  //     }));
  //     setRows(tests);
  //     setInitialRows(tests);
  //   }
  // }, [tests]);

  const handlePageSizeChange = ({ pageSize }) => setPageSize(pageSize);
  const viewTest = useCallback(
    (params) => () => history.push(`/instructions`, { data: params.row }),
    [history]
  );

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: 150,
        headerAlign: "center",
        getActions: (params) => [
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={viewTest(params)}
          >
            {renderAction(params.row.status)}
          </Button>,
        ],
      },
      {
        field: "testName",
        headerName: "Test Name",
        width: 400,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "strand",
        headerName: "Strand",
        width: 220,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "result",
        headerName: "Result",
        width: 120,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "dueDate",
        headerName: "Due Date",
        width: 190,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      },
      {
        field: "completedOn",
        headerName: "Completed On",
        width: 190,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      },
    ],
    [viewTest]
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Tests
          </Typography>
        </Box>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={/*testLoading*/ false}
        />
      </Paper>
    </Container>
  );
};

export default Tests;
