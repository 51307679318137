import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  OutlinedInput,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GET_PACKAGE_DETAILS } from "../graphql/queries";
import { UPDATE_PACKAGE } from "../graphql/mutations";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import { AuthenticatedContext } from "../App";
import { useHistory } from "react-router-dom";

const PackageUpdateDetails = ({ packageId, packageType }) => {
  const { userRole } = useContext(AuthenticatedContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [elPackage, setElPackage] = useState({});
  const schema = yup
    .object({
      packageName: yup.string(),
      // availableFrom: yup.date().required("Required").nullable().transform(v => (v instanceof Date && !isNaN(v) ? v: null)),
      // availableTo: yup.date().required("Required").nullable().transform(v => (v instanceof Date && !isNaN(v) ? v: null)),
      courseId: yup.number().nullable(),
      isActive: yup.boolean(),
      easyCourses: yup.string(),
      hasDiagnostic: yup.boolean(),
    })
    .required();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      packageName: "",
      // availableFrom: new Date(),
      // availableTo: new Date(),
      courseId: 0,
      isActive: false,
      easyCourses: "",
      hasDiagnostic: false,
    },
  });

  const location = useLocation();

  const [getPackageDetails] = useLazyQuery(GET_PACKAGE_DETAILS, {
    onCompleted: (returnPackageDetails) => {
      setValue("packageName", returnPackageDetails.getPackageDetails.name);
      // setValue("availableFrom", new Date(returnPackageDetails.getPackageDetails.availableFrom));
      // setValue("availableTo", new Date(returnPackageDetails.getPackageDetails.availableTo));
      setValue("courseId", returnPackageDetails.getPackageDetails.courseId);
      setValue(
        "isActive",
        returnPackageDetails.getPackageDetails.isActive || false
      );
      setValue(
        "hasDiagnostic",
        returnPackageDetails.getPackageDetails.hasDiagnostic || false
      );
      setElPackage(returnPackageDetails.getPackageDetails);
    },
    fetchPolicy: "no-cache",
  });

  // const [getAllCourses, { coursesLoading, coursesError, coursesData }] = useLazyQuery(GET_ALL_COURSES, {
  //   fetchPolicy: "no-cache",
  //   onCompleted: (courses) => {
  //     if (courses.getAllCourses?.courses.length >= 0) {
  //       setCourses(courses.getAllCourses.courses);
  //     }
  //   }
  // });

  const [updatePackage] = useMutation(UPDATE_PACKAGE, {
    onError: (error) => {
      enqueueSnackbar(`A ${packageType} with the same name already exists`, {
        variant: "error",
      });
    },
    onCompleted: ({ updatePackage }) => {
      if (updatePackage) {
        enqueueSnackbar(`${packageType} successfully updated`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          `There was a problem with updating the ${packageType}, please try again.`,
          {
            variant: "warning",
          }
        );
      }
    },
    fetchPolicy: "network-only",
  });

  const re = useMemo(() => new RegExp("coursemanagement"), []);

  useEffect(() => {
    if (re.test(location.pathname)) {
      getPackageDetails({
        variables: { package_id: packageId, package_type: "Course" },
      });
    } else {
      getPackageDetails({
        variables: { package_id: packageId, package_type: "Program" },
      });
    }
  }, [location.pathname, packageId, re]);

  const onSubmit = ({
    packageName,
    // availableFrom,
    // availableTo,
    courseId,
    isActive,
    hasDiagnostic,
  }) => {
    updatePackage({
      variables: {
        ...(re.test(location.pathname)
          ? { package_type: "Course" }
          : { package_type: "Program" }),
        name: packageName,
        package_id: packageId,
        // available_from: availableFrom,
        // available_to: availableTo,
        course_id: courseId,
        is_active: isActive,
        has_diagnostic: hasDiagnostic,
      },
    });
  };

  const onInvalid = (errors) => console.error(errors);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit, onInvalid)}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel>Name</InputLabel>
          <Controller
            name="packageName"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.packageName ? true : false}
                disabled={!["Admin", "Education Consultant"].includes(userRole)}
              >
                <OutlinedInput {...field} />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.packageName?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        {/* <Grid
          item
          xs={4}
        >
          <InputLabel>Available From</InputLabel>
          <Controller
            name="availableFrom"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                required
                error={errors.availableFrom ? true : false}
                fullWidth
              >
                <DateTimePicker 
                  renderInput={(params) => <TextField {...params} />} 
                  inputFormat="dd/MM/yyyy hh:mm"
                  maxDateTime={watch("availableTo") || moment().add(1, "year")}
                  minDateTime={moment().subtract(1, "year")}
                  disabled={!["Admin", "Education Consultant"].includes(userRole)}
                  {...field}
                />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.availableFrom?.message}
                </FormHelperText>
              </FormControl>
            )} />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <InputLabel>Available To</InputLabel>
          <Controller
            name="availableTo"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                required
                error={errors.availableTo ? true : false}
                fullWidth
              >
                <DateTimePicker 
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy hh:mm"
                  minDateTime={watch("availableFrom") || moment().subtract(1, "year")}
                  maxDateTime={moment().add(1, "year")}
                  disabled={!["Admin", "Education Consultant"].includes(userRole)}
                  {...field}
                />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.availableTo?.message}
                </FormHelperText>
              </FormControl>
            )} />
        </Grid> */}
        <Grid item xs={4}>
          {!re.test(location.pathname) && (
            <>
              <InputLabel>Course</InputLabel>
              <Controller
                name="courseId"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    error={errors.courseId ? true : false}
                    fullWidth
                  >
                    <Select
                      {...field}
                      defaultValue={control.courseId}
                      autoWidth
                      disabled={
                        !["Admin", "Education Consultant"].includes(userRole)
                      }
                    >
                      {history.location.state?.data.courses &&
                        history.location.state.data.courses
                          .split(";")
                          .map((c) => JSON.parse(c))
                          .map(({ id, name }) => (
                            <MenuItem value={Number(id)} key={Number(id)}>
                              {name}
                            </MenuItem>
                          ))}
                    </Select>
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.courseId?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </>
          )}
        </Grid>
        {["Admin", "Education Consultant"].includes(userRole) && (
          <>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <InputLabel>Created By</InputLabel>
              <TextField
                disabled
                value={`${elPackage.CreatedByStaff?.givenname} ${elPackage.CreatedByStaff?.surname}`}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Created At</InputLabel>
              <FormControl fullWidth>
                <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm"
                  value={elPackage.createdAt}
                  onChange={(event) => {}}
                  renderInput={(params) => <TextField {...params} />}
                  disabled
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <InputLabel>Updated By</InputLabel>
              <TextField
                disabled
                value={`${elPackage.UpdatedByStaff?.givenname} ${elPackage.UpdatedByStaff?.surname}`}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Updated At</InputLabel>
              <FormControl fullWidth>
                <DateTimePicker
                  inputFormat="dd/MM/yyyy hh:mm"
                  value={elPackage.updatedAt}
                  onChange={(event) => {}}
                  renderInput={(params) => <TextField {...params} />}
                  disabled
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={4} alignItems="center">
          {!re.test(location.pathname) && (
            <Grid container alignItems="center">
              <Controller
                name="hasDiagnostic"
                control={control}
                error={errors.hasDiagnostic ? true : false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ pt: "5%" }}
                    control={<Checkbox {...field} checked={field.value} />}
                    disabled={
                      !["Admin", "Education Consultant"].includes(userRole)
                    }
                    label="Include Diagnostic Test?"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4}>
          {["Admin", "Education Consultant"].includes(userRole) && (
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageUpdateDetails;
