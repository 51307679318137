import {
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import BarGraph from "./graphs/BarGraph";
import ResultTable from "./ResultTable";
import { GET_REPORT } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import ScatterGraph from "./graphs/ScatterGraph";

const TestReport = ({ openFn, test_id, student_id, student_name }) => {
  const [rankData, setRankData] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [testName, setTestName] = useState("");
  const [passageData, setPassageData] = useState([]);

  const [getReport] = useLazyQuery(GET_REPORT, {
    onCompleted: (report) => {
      const reportData = report.getReport;
      if (reportData.studentRanks) {
        let newRankData = [...reportData.studentRanks?.strands];
        newRankData.push({
          name: "Overall",
          score: reportData.studentRanks?.Overall,
        });
        setRankData(newRankData);
      }
      setQuestionData(reportData.questionData);
      setTestName(reportData.testName);
      setPassageData(reportData.passageData);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    getReport({
      variables: {
        test_id,
        student_id,
      },
    });
  }, [student_id, test_id]);

  useEffect(() => {
    console.log(passageData);
  }, [passageData, testName, questionData, rankData]);

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => openFn(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {testName} Report
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ margin: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          <Typography variant="h4" component="div">
            {testName} Report
          </Typography>
        </Stack>
        <Typography variant="body1" component="div">
          Dear {student_name},
        </Typography>
        <Typography variant="body1" component="div">
          Thank you for sitting the Problem Solving Assessment with SolverEdge.
          This report is best viewed on desktop computer/laptop or device with
          screen size of 10 inches or more. If the achievement scale below
          doesn't load correctly, we recommend that you download the PDF report
          onto a desktop computer/laptop and open it using PDF reader software.
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          {rankData.length > 0 && <BarGraph data={rankData} />}
          <Box />
        </Stack>
        {passageData?.length > 0 && (
          <Grid container spacing={2}>
            {passageData.map((p) => {
              return (
                <Grid item xs={6}>
                  <ScatterGraph passageData={p} student_id={student_id} />
                </Grid>
              );
            })}
          </Grid>
        )}
        {questionData.length > 0 &&
          questionData.map((q) => {
            return (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Box sx={{ width: "20vw" }} />
                  <Typography variant="h5" component="div">
                    {q.name}
                  </Typography>
                  <Box sx={{ width: "20vw" }} />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Box sx={{ width: "20vw" }} />
                  <ResultTable data={q.questions} />
                  <Box sx={{ width: "20vw" }} />
                </Stack>
                <br />
                <br />
              </>
            );
          })}
      </Box>
    </>
  );
};

export default TestReport;
