import React, { useContext } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { AuthenticatedContext } from "../App";
import PsychologyIcon from "@mui/icons-material/Psychology";
import CalculateIcon from "@mui/icons-material/Calculate";
import SchoolIcon from "@mui/icons-material/School";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const MenuItems = () => {
  const { userRole } = useContext(AuthenticatedContext);

  return (
    <>
      <ListItem button component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      {userRole === "Student" && (
        <>
          <ListItem button component={Link} to="/studenttraining">
            <ListItemIcon>
              <CalculateIcon />
            </ListItemIcon>
            <ListItemText primary="Training Module" />
          </ListItem>
          {/* <ListItem button component={Link} to="/medals">
            <ListItemIcon>
              <MilitaryTechIcon />
            </ListItemIcon>
            <ListItemText primary="Medals" />
          </ListItem> */}
        </>
      )}
      {[
        "Admin",
        "Education Consultant",
        "Region Administrator",
        "Region Manager",
        "Campus Director",
      ].includes(userRole) && (
        <>
          <ListItem button component={Link} to="/programmanagement">
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Program Management" />
          </ListItem>
          <ListItem button component={Link} to="/testmanagement">
            <ListItemIcon>
              <NoteAltIcon />
            </ListItemIcon>
            <ListItemText primary="Test Management" />
          </ListItem>
          <ListItem button component={Link} to="/coursemanagement">
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="Course Management" />
          </ListItem>
          <ListItem button component={Link} to="/trainingmanagement">
            <ListItemIcon>
              <PsychologyIcon />
            </ListItemIcon>
            <ListItemText primary="Training Management" />
          </ListItem>
          <ListItem button component={Link} to="/studentmanagement">
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Student Management" />
          </ListItem>
          <ListItem button component={Link} to="/usermanagement">
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="User Management" />
          </ListItem>
          <ListItem button component={Link} to="/classmanagement">
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Class Management" />
          </ListItem>
          {/* <ListItem button component={Link} to="/reportmanagement">
            <ListItemIcon>
              <PictureAsPdfIcon />
            </ListItemIcon>
            <ListItemText primary="Report Management" />
          </ListItem> */}
        </>
      )}
      {userRole === "Teacher" && (
        <>
          <ListItem button component={Link} to="/programmanagement">
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Program Management" />
          </ListItem>
          <ListItem button component={Link} to="/testmanagement">
            <ListItemIcon>
              <NoteAltIcon />
            </ListItemIcon>
            <ListItemText primary="Test Management" />
          </ListItem>
          <ListItem button component={Link} to="/coursemanagement">
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="Course Management" />
          </ListItem>
          <ListItem button component={Link} to="/trainingmanagement">
            <ListItemIcon>
              <PsychologyIcon />
            </ListItemIcon>
            <ListItemText primary="Training Management" />
          </ListItem>
          <ListItem button component={Link} to="/studentmanagement">
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Student Management" />
          </ListItem>
          <ListItem button component={Link} to="/classmanagementteacher">
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Class Management" />
          </ListItem>
        </>
      )}
    </>
  );
};

export default MenuItems;
