import React, { useEffect, useState } from "react";
import { Split } from "@geoffcox/react-splitter";
import { Box } from "@mui/system";
import {
  Alert,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { ModuleContext } from "../../App";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

const MultipleChoiceType = ({
  currentQuestion: {
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution,
    order,
  },
  moduleType,
  readOnly,
  isTrainingAnswerCorrect,
  isAssessmentAnswerCorrect,
  isResultShown,
  isReview,
}) => {
  const moduleValue = React.useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };

  const getCorrectAnswer = (correctAns) => {
    switch (parseInt(correctAns)) {
      case 1:
        return answer1 ? parse(answer1, options) : "";
      case 2:
        return answer2 ? parse(answer2, options) : "";
      case 3:
        return answer3 ? parse(answer3, options) : "";
      case 4:
        return answer4 ? parse(answer4, options) : "";
      case 5:
        return answer5 ? parse(answer5, options) : "";
      case 6:
        return answer6 ? parse(answer6, options) : "";
      case 7:
        return answer7 ? parse(answer7, options) : "";
      case 8:
        return answer8 ? parse(answer8, options) : "";
      case 9:
        return answer9 ? parse(answer9, options) : "";
      case 10:
        return answer10 ? parse(answer10, options) : "";
      default:
        return null;
    }
  };

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const updateAnswer = (event, value) => {
    setSelectedAnswer(value);
    moduleValue?.setModuleSettings({
      ...moduleValue?.moduleSettings,
      selectedAnswer: value,
    });
  };

  useEffect(() => {
    setSelectedAnswer(moduleValue?.moduleSettings.selectedAnswer);
  }, [id, moduleValue?.moduleSettings.selectedAnswer, order]);

  return (
    <Box>
      <Box sx={{ pb: 2, pl: 3 }}>
        <Split
          minPrimarySize="15%"
          minSecondarySize="15%"
          initialPrimarySize="50%"
          resetOnDoubleClick
          splitterSize="32px"
          defaultSplitterColors={splitterColors}
        >
          <div style={{ overflow: "auto", height: "100%" }}>
            {src && (
              <Typography variant="h6" sx={{ overflow: "auto" }}>
                {parse(src, options)}
              </Typography>
            )}
            {!src && content && (
              <Typography variant="h6" sx={{ overflow: "auto" }}>
                {parse(content, options)}
              </Typography>
            )}
          </div>
          <div style={{ overflow: "auto", height: "100%" }}>
            {src && content && (
              <Typography variant="h6" sx={{ mt: 2 }}>
                {parse(content, options)}
              </Typography>
            )}
            <FormControl component="fieldset" sx={{ m: 2 }}>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={selectedAnswer}
                onChange={updateAnswer}
              >
                {answer1 && (
                  <FormControlLabel
                    value={"1"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer1, options)}
                    key={1}
                    disabled={readOnly}
                  />
                )}
                {answer2 && (
                  <FormControlLabel
                    value={"2"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer2, options)}
                    key={2}
                    disabled={readOnly}
                  />
                )}
                {answer3 && (
                  <FormControlLabel
                    value={"3"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer3, options)}
                    key={3}
                    disabled={readOnly}
                  />
                )}
                {answer4 && (
                  <FormControlLabel
                    value={"4"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer4, options)}
                    key={4}
                    disabled={readOnly}
                  />
                )}
                {answer5 && (
                  <FormControlLabel
                    value={"5"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer5, options)}
                    key={5}
                    disabled={readOnly}
                  />
                )}
                {answer6 && (
                  <FormControlLabel
                    value={"6"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer6, options)}
                    key={6}
                    disabled={readOnly}
                  />
                )}
                {answer7 && (
                  <FormControlLabel
                    value={"7"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer7, options)}
                    key={7}
                    disabled={readOnly}
                  />
                )}
                {answer8 && (
                  <FormControlLabel
                    value={"8"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer8, options)}
                    key={8}
                    disabled={readOnly}
                  />
                )}
                {answer9 && (
                  <FormControlLabel
                    value={"9"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer9, options)}
                    key={9}
                    disabled={readOnly}
                  />
                )}
                {answer10 && (
                  <FormControlLabel
                    value={"10"}
                    size="medium"
                    control={<Radio size="medium" />}
                    label={parse(answer10, options)}
                    key={10}
                    disabled={readOnly}
                  />
                )}
              </RadioGroup>
            </FormControl>
            {isResultShown &&
              !isReview &&
              (isTrainingAnswerCorrect || isAssessmentAnswerCorrect) && (
                <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
                  <strong>Correct!</strong>
                </Alert>
              )}
            {correctAns &&
              isResultShown &&
              !isTrainingAnswerCorrect &&
              !isAssessmentAnswerCorrect && (
                <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                  <strong>Wrong answer.</strong>
                </Alert>
              )}
            {correctAns &&
              !isReview &&
              isResultShown &&
              !isTrainingAnswerCorrect &&
              !isAssessmentAnswerCorrect && (
                <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
                  <strong>Correct answer:</strong>{" "}
                  {getCorrectAnswer(correctAns)}
                </Alert>
              )}
            {correctAns && isReview && (
              <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
                <strong>Correct answer:</strong> {getCorrectAnswer(correctAns)}
              </Alert>
            )}
            {moduleType === "Training" &&
              hint &&
              isResultShown &&
              !isTrainingAnswerCorrect && (
                <Alert
                  severity="info"
                  iconMapping={{
                    info: <WbIncandescentIcon fontSize="inherit" />,
                  }}
                  sx={{ ml: 2, mb: 2 }}
                >
                  <strong>
                    Hint: <br></br>
                  </strong>
                  {parse(hint, options)}
                </Alert>
              )}
            {moduleType === "Assessment" && hint && (
              <Alert
                severity="info"
                iconMapping={{
                  info: <WbIncandescentIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2, mb: 2 }}
              >
                <strong>
                  Hint: <br></br>
                </strong>
                {parse(hint, options)}
              </Alert>
            )}
            {/* {hint && isReview && (
              <Alert
                severity="info"
                iconMapping={{
                  info: <WbIncandescentIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2, mb: 2 }}
              >
                <strong>
                  Hint: <br></br>
                </strong>
                {parse(hint, options)}
              </Alert>
            )} */}
            {moduleType === "Training" &&
              solution &&
              isResultShown &&
              !isTrainingAnswerCorrect && (
                <Alert
                  severity="warning"
                  iconMapping={{
                    warning: <RadioButtonCheckedIcon fontSize="inherit" />,
                  }}
                  sx={{ ml: 2 }}
                >
                  <strong>
                    Solution: <br></br>
                  </strong>
                  {parse(solution, options)}
                </Alert>
              )}
            {moduleType === "Assessment" && solution && isResultShown && (
              <Alert
                severity="warning"
                iconMapping={{
                  warning: <RadioButtonCheckedIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2 }}
              >
                <strong>
                  Solution: <br></br>
                </strong>
                {parse(solution, options)}
              </Alert>
            )}
            {/* {solution && isReview && (
              <Alert
                severity="warning"
                iconMapping={{
                  warning: <RadioButtonCheckedIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2 }}
              >
                <strong>
                  Solution: <br></br>
                </strong>
                {parse(solution, options)}
              </Alert>
            )} */}
          </div>
        </Split>
      </Box>
    </Box>
  );
};

export default MultipleChoiceType;
