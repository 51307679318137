import React, { useState } from "react";
import { Box } from "@mui/system";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { Split } from "@geoffcox/react-splitter";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const SingleInlineSelectType = ({
  currentQuestion: {
    Question,
    Answer1,
    Answer2,
    Answer3,
    Answer4,
    Answer5,
    Answer6,
    Answer7,
    Answer8,
    Answer9,
    Answer10,
    CorrectAnswer,
    Hint,
    VideoSrc,
    PassageSrc,
    Solution,
  },
}) => {
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const getCorrectAnswer = (CorrectAnswer) => {
    switch (parseInt(CorrectAnswer)) {
      case 1:
        return Answer1 ? parse(Answer1) : "";
      case 2:
        return Answer2 ? parse(Answer2) : "";
      case 3:
        return Answer3 ? parse(Answer3) : "";
      case 4:
        return Answer4 ? parse(Answer4) : "";
      case 5:
        return Answer5 ? parse(Answer5) : "";
      case 6:
        return Answer6 ? parse(Answer6) : "";
      case 7:
        return Answer7 ? parse(Answer7) : "";
      case 8:
        return Answer8 ? parse(Answer8) : "";
      case 9:
        return Answer9 ? parse(Answer9) : "";
      case 10:
        return Answer10 ? parse(Answer10) : "";
      default:
        return null;
    }
  };

  const questionSplit = () => Question.split("[split]");

  const [buttonSelect, setButtonSelect] = useState({
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
  });

  const isButtonSelected = (buttonName) => {
    const newButtonSelect = { ...buttonSelect };

    delete newButtonSelect[buttonName];

    return Object.values(newButtonSelect).includes(true);
  };

  const handleSelect = (e) => {
    setButtonSelect({
      ...buttonSelect,
      [e.target.name]: !buttonSelect[e.target.name],
    });
  };

  return (
    <Split
      minPrimarySize="15%"
      minSecondarySize="15%"
      initialPrimarySize="50%"
      resetOnDoubleClick
      splitterSize="48px"
      defaultSplitterColors={splitterColors}
    >
      <Box>
        {VideoSrc && (
          <>
            <ReactPlayer
              url={VideoSrc}
              controls={true}
              width="95%"
              height="95%"
            />
            <ReactAudioPlayer src={VideoSrc} controls />
          </>
        )}
        {PassageSrc && (
          <Typography variant="h6">{parse(PassageSrc)}</Typography>
        )}
        {!PassageSrc && !VideoSrc && Question && (
          <Typography variant="h6">{parse(questionSplit()[0])}</Typography>
        )}
      </Box>
      <Box sx={{ pb: 2 }}>
        {(PassageSrc || VideoSrc) && Question && (
          <Typography variant="h6">{parse(questionSplit()[0])}</Typography>
        )}
        {questionSplit().length > 1 && (
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            sx={{ mb: 3 }}
          >
            <Typography variant="subtitle1">
              {parse(questionSplit()[1])}
            </Typography>

            {questionSplit().length > 2 && (
              <>
                <Button
                  variant={buttonSelect.button1 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button1"
                  disabled={isButtonSelected("button1")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer1}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[2])}
                </Typography>
              </>
            )}

            {questionSplit().length > 3 && (
              <>
                <Button
                  variant={buttonSelect.button2 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button2"
                  disabled={isButtonSelected("button2")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer2}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[3])}
                </Typography>
              </>
            )}
            {questionSplit().length > 4 && (
              <>
                <Button
                  variant={buttonSelect.button3 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button3"
                  disabled={isButtonSelected("button3")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer3}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[4])}
                </Typography>
              </>
            )}
            {questionSplit().length > 5 && (
              <>
                <Button
                  variant={buttonSelect.button4 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button4"
                  disabled={isButtonSelected("button4")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer4}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[5])}
                </Typography>
              </>
            )}
            {questionSplit().length > 6 && (
              <>
                <Button
                  variant={buttonSelect.button5 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button5"
                  disabled={isButtonSelected("button5")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer5}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[6])}
                </Typography>
              </>
            )}
            {questionSplit().length > 7 && (
              <>
                <Button
                  variant={buttonSelect.button6 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button6"
                  disabled={isButtonSelected("button6")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer6}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[7])}
                </Typography>
              </>
            )}
            {questionSplit().length > 8 && (
              <>
                <Button
                  variant={buttonSelect.button7 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button7"
                  disabled={isButtonSelected("button7")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer7}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[8])}
                </Typography>
              </>
            )}
            {questionSplit().length > 9 && (
              <>
                <Button
                  variant={buttonSelect.button8 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button8"
                  disabled={isButtonSelected("button8")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer8}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[9])}
                </Typography>
              </>
            )}
            {questionSplit().length > 10 && (
              <>
                <Button
                  variant={buttonSelect.button9 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button9"
                  disabled={isButtonSelected("button9")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer9}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[10])}
                </Typography>
              </>
            )}
            {questionSplit().length > 11 && (
              <>
                <Button
                  variant={buttonSelect.button10 === true ? "outlined" : "text"}
                  onClick={handleSelect}
                  name="button10"
                  disabled={isButtonSelected("button10")}
                  sx={{ ml: 1, mr: 1 }}
                >
                  {Answer10}
                </Button>
                <Typography variant="subtitle1">
                  {parse(questionSplit()[11])}
                </Typography>
              </>
            )}
          </Stack>
        )}
        {CorrectAnswer && (
          <Alert severity="success" sx={{ mb: 2 }}>
            <strong>Correct answer:</strong> {getCorrectAnswer(CorrectAnswer)}
          </Alert>
        )}
        {Hint && (
          <Alert
            severity="info"
            iconMapping={{
              info: <WbIncandescentIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Hint: <br></br>
            </strong>
            {parse(Hint)}
          </Alert>
        )}
        {Solution && (
          <Alert
            severity="warning"
            iconMapping={{
              warning: <RadioButtonCheckedIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Solution: <br></br>
            </strong>
            {parse(Solution)}
          </Alert>
        )}
      </Box>
    </Split>
  );
};

export default SingleInlineSelectType;
