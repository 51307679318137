import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

const RadarGraph = ({ type, data }) => {
  return (
    <RadarChart outerRadius={150} data={data} width={920} height={438}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis
        domain={[0, 15]}
        tickCount={4}
        tickFormatter={() => ""}
        angle={30}
      />
      <Radar
        name="Your Score"
        dataKey="A"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.6}
      />
      {/* { type === "top" && (
        <Radar
          name="Top score of students your age"
          dataKey="Top"
          stroke="#82ca9d"
          fill="#82ca9d"
          fillOpacity={0.6}
        />
      )}
      { type === "average" && (
        <Radar
          name="Average score of students your age"
          dataKey="B"
          stroke="#82ca9d"
          fill="#82ca9d"
          fillOpacity={0.6}
        />
      )} */}
      <Legend />
    </RadarChart>
  );
};

export default RadarGraph;
