import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

const ViewDialog = ({ setIsDialogOpen, setSelectedWeek, selectedWeek, submitClassTrainings }) => {
  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedWeek(0);
  };

  const submitDialog = () => {
    submitClassTrainings();
    closeDialog();
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "40px",
        right: "0",
        backgroundColor: "white",
        padding: "20px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        zIndex: 1000,
      }}
    >
      <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
        Input the Week to Complete Up To
      </Typography>
      <input
        type="number"
        value={selectedWeek}
        onChange={(e) => setSelectedWeek(e.target.valueAsNumber)}
        style={{ padding: "5px", fontSize: "16px" }}
      />
      <br />
      <br />
      <Button
        color="primary"
        gutterBottom
        variant="contained"
        onClick={submitDialog}
        sx={{
          mb: 0,
          cursor: "pointer",
          mr: "10px",
          padding: "5px 10px",
        }}
      >
        Submit
      </Button>
      <Button
        onClick={closeDialog}
        color="primary"
        gutterBottom
        variant="contained"
        sx={{
          mb: 0,
          cursor: "pointer",
          mr: "10px",
          padding: "5px 10px",
        }}
      >
        Cancel
      </Button>
    </Box>
  );
};

export default ViewDialog;
