import {
  Button,
  Box,
  Typography,
  Stack,
  Autocomplete,
  TextField,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthenticatedContext } from "../App";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const CurriculumWeek = ({
  index,
  weekTrainings,
  unselectedTrainings,
  setWeekTrainings,
}) => {
  const { userRole } = useContext(AuthenticatedContext);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    setTrainings([...weekTrainings]);
  }, [weekTrainings]);

  return (
    <Box sx={{ my: 2 }} key={`ctbox-${index}`}>
      <Typography color="primary" gutterBottom variant="body1">
        Week {index + 1}
      </Typography>

      {["Admin", "Education Consultant"].includes(userRole) && (
        <Stack direction="row">
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() =>
              setTrainings([
                ...trainings,
                { trainingId: 0, percentile: 0, name: "" },
              ])
            }
          >
            Add Training
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={() => setWeekTrainings(index, trainings)}
          >
            Save
          </Button>
        </Stack>
      )}
      {trainings.map((t, i) => {
        return (
          <Stack direction="row" key={`ctstack-${index}-${i}`}>
            <Autocomplete
              key={`ctauto-${index}-${i}`}
              options={unselectedTrainings}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Training" margin="normal" />
              )}
              value={trainings[i]}
              onChange={(event, newValue) => {
                let newTrainings = [...trainings];
                newTrainings[i]["trainingId"] = newValue.id;
                newTrainings[i]["name"] = newValue.name;
                setTrainings([...newTrainings]);
              }}
              sx={{ width: 400, mr: 2 }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.trainingId
              }
              disabled={!["Admin", "Education Consultant"].includes(userRole)}
            />
            <TextField
              sx={{ pt: 2, mr: 2 }}
              label="Percentile"
              InputLabelProps={{
                shrink: true,
                sx: {
                  pt: 3,
                },
              }}
              type="number"
              inputProps={{ min: 0, max: 100 }}
              value={trainings[i].percentile}
              onChange={(event) => {
                let newTrainings = [...trainings];
                newTrainings[i].percentile = event.target.valueAsNumber;
                setTrainings([...newTrainings]);
              }}
              disabled={!["Admin", "Education Consultant"].includes(userRole)}
            />
            {["Admin", "Education Consultant"].includes(userRole) && (
              <IconButton
                color="primary"
                onClick={() => {
                  let newTrainings = [...trainings];
                  newTrainings.splice(i, 1);
                  setTrainings([...newTrainings]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        );
      })}
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};

export default CurriculumWeek;
