import { useLocation, Route, Redirect } from "react-router-dom";

export const ExternalRoute = (props) => {
  let location = useLocation();
  const urlQuery = new URLSearchParams(location.search);

  console.log(location);
  console.log(urlQuery.get("student_number"));
  return location.pathname === "/payment" ? (
    <Route {...props} />
  ) : (
    <Redirect to="/" />
  );
};
