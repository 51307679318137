import { BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";
import React from "react";

const TrainingBarGraph = ({ data }) => {
  // const finalData = data.map(d => ({
  //   ...d,
  //   ...("s1" in d) && { total: d.s1 + d.s2 },
  // }));
  console.log(data);
  // const bars = Object.keys(finalData[0]).filter(k => (k !== "name") && (k !== "total") && (k !== "__typename"));

  const CustomText = (props) => {
    const { x, y, stroke } = props;
    let value;
    if (props.name === "Median Time") {
      value =
        "The bar above shows the median time taken by all students who have completed the training module at least once.";
    } else {
      value =
        "The bar above shows the time taken for the student's first attempt.";
    }

    const newy = y + 240 - 40 * data.length;

    return (
      <text x={x + 5} y={newy} fill={stroke} fontSize={13} textAnchor="left">
        {value}
      </text>
    );
  };

  return (
    <BarChart
      width={1000}
      height={500}
      data={data}
      margin={{
        top: 20,
        right: 100,
        left: 20,
        bottom: 20,
      }}
      layout="vertical"
      barCategoryGap={25}
      barSize={130}
    >
      <XAxis type="number" hide />
      <YAxis type="category" dataKey="name" width={200} />
      {
        /*bars.map((b, i) => {
          if (i === 0) {
            return (
              <Bar
                isAnimationActive={false}
                dataKey={b}
                stackId="a"
                fill="#8884d8"
                label={
                  <CustomText
                    style={{
                      fontSize: 12,
                      fontFamily: "sans-serif"
                    }}
                  />
                }
              >
                {b === "s1" && (
                  <LabelList
                    dataKey="s1"
                    fill="#000000"
                    position="center"
                    formatter={(value) => {
                      // return "Time taken for Section 1";
                      return "Total time taken"
                    }}
                    style={{
                      fontSize: 18
                    }}
                  />
                )}
                {bars.length === 1 && (
                  <LabelList dataKey="time" position="right" formatter={(value) => {
                    return `${value} minutes`;
                  }} />
                )}
              </Bar>
            )
          } else {
            return (
              <Bar
                isAnimationActive={false}
                dataKey="s2"
                stackId="a"
                fill="#82ca9d"
              >
                <LabelList
                  dataKey="s2"
                  fill="#000000"
                  position="center"
                  formatter={(value) => {
                    return "Time taken for Section 2";
                  }}
                  style={{
                    fontSize: 18
                  }}
                />
                <LabelList dataKey="total" position="right" formatter={(value) => {
                  return `${value} minutes`;
                }} />
              </Bar>
            )
          }
        })*/
        <Bar
          isAnimationActive={false}
          dataKey={"time"}
          stackId="a"
          fill="#213162"
          label={
            <CustomText
              style={{
                fontSize: 12,
                fontFamily: "sans-serif",
              }}
            />
          }
        >
          <LabelList
            dataKey="time"
            fill="#FFFFFF"
            position="center"
            formatter={(value) => {
              // return "Time taken for Section 1";
              return "Total time taken";
            }}
            style={{
              fontSize: 18,
            }}
          />
          <LabelList
            dataKey="time"
            position="right"
            formatter={(value) => {
              return `${value} minutes`;
            }}
          />
        </Bar>
      }
    </BarChart>
  );
};

export default TrainingBarGraph;
