import { Box } from "@mui/material";
import moment from "moment";
import { useContext } from "react";
import { useEffect } from "react";
import { useStopwatch } from "react-timer-hook";
import { ModuleContext } from "../App";

function MyStopwatch({ timer, offsetTimestamp }) {
  const { seconds, minutes, hours, start, pause } = useStopwatch({
    autoStart: false,
    offsetTimestamp,
  });

  useEffect(() => {
    if (timer) {
      start();
    } else {
      pause();
    }
  }, [timer]);

  return (
    <Box
      sx={{
        backgroundColor: "black",
        mr: 8,
        padding: 1,
        pl: 2,
        pr: 2,
        width: "12vw",
        textAlign: "center",
      }}
    >
      <Box sx={{ fontSize: 36 }}>
        {hours > 1 && (
          <>
            <span>{hours}</span>:
          </>
        )}
        <span>{minutes < 10 && hours > 1 ? `0${minutes}` : minutes}</span>:
        <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
      </Box>
    </Box>
  );
}

export default function App({ timer }) {
  const { moduleSettings } = useContext(ModuleContext);

  const offSet =
    (moment() -
      moduleSettings.currentSitting?.createdAt -
      moduleSettings.totalLogoutTime) /
    1000;
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offSet);

  return <MyStopwatch timer={timer} offsetTimestamp={stopwatchOffset} />;
}
