import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PackageUpdateDetails from "../components/PackageUpdateDetails";
import SelectTest from "../components/SelectTest";
import ReportStudents from "../components/ReportStudents";
import { GET_ALLOCATED_TESTS_BY_PROGRAM } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import TrainingAllocation from "../components/TrainingAllocation";
import ProgramRegistrations from "../components/ProgramRegistrations";
import { AuthenticatedContext } from "../App";
import DiagnosticSittings from "../components/DiagnosticSittings";

const ProgramDetailManagement = () => {
  const { id } = useParams();
  const [programTests, setProgramTests] = useState([]);
  const { userRole } = useContext(AuthenticatedContext);
  const [getAllocatedTestsByProgram] = useLazyQuery(
    GET_ALLOCATED_TESTS_BY_PROGRAM,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ getAllocatedTestsByProgram }) => {
        setProgramTests(getAllocatedTestsByProgram);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callAllocatedTests = useCallback(() => {
    if (id) {
      getAllocatedTestsByProgram({
        variables: {
          program_id: Number(id),
        },
      });
    }
  }, []);

  useEffect(() => {
    callAllocatedTests();
  }, [id]);

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />} id="program-details-1">
          <Typography>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PackageUpdateDetails packageId={Number(id)} packageType="Program" />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />} id="assessment-list-1">
          <Typography>Assessment List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectTest program_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
      {["Admin", "Education Consultant"].includes(userRole) && (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            id="program-registration"
          >
            <Typography>Student Registration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProgramRegistrations program_id={Number(id)} />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />} id="diag-list-1">
          <Typography>Diagnostic Sittings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DiagnosticSittings programId={Number(id)} />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />} id="summary-report-1">
          <Typography>Summary Report</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReportStudents program_id={Number(id)} key="program" />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />} id="allocate-training-1">
          <Typography>Training Allocation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TrainingAllocation program_id={Number(id)} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ProgramDetailManagement;
