import React, { useEffect, useState, useCallback } from "react";
import { Box } from "@mui/system";
import { Alert, Typography } from "@mui/material";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const HorizontalSortType = ({
  currentQuestion: {
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    src,
    hint,
    solution,
  },
}) => {
  const [items, setItems] = useState([]);
  const [initialItems, setInitialItems] = useState([]);

  // Memoize formatList to avoid recreating it on every render
  const formatList = useCallback(
    (list) => {
      let indexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      if (list) indexes = JSON.parse("[" + list + "]");
      const answers = [
        answer1,
        answer2,
        answer3,
        answer4,
        answer5,
        answer6,
        answer7,
        answer8,
        answer9,
        answer10,
      ];
      return indexes
        .filter((i) => answers[i - 1]) // Filter out undefined answers
        .map((i) => ({ name: answers[i - 1], id: i.toString() }));
    },
    [
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
      answer6,
      answer7,
      answer8,
      answer9,
      answer10,
    ]
  );

  useEffect(() => {
    if (correctAns) setItems(formatList(parse(correctAns)));
    setInitialItems(formatList());
  }, [correctAns]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle, isCorrect = false) => ({
    userSelect: "none",
    padding: grid * 3,
    margin: `0 ${grid}px 0 0`,
    background: isCorrect ? "lightgreen" : isDragging ? "lightgreen" : "grey",
    color: "black",
    fontSize: "1.4rem",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver, isCorrect = false) => ({
    background: isCorrect
      ? "lightblue"
      : isDraggingOver
      ? "lightblue"
      : "lightgrey",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = reorder(
      initialItems,
      result.source.index,
      result.destination.index
    );
    setInitialItems(newItems);
  };

  return (
    <Box sx={{ pb: 2 }}>
      {src && <Typography variant="h6">{parse(src)}</Typography>}
      {content && <Typography variant="h6">{parse(content)}</Typography>}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {initialItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Alert severity="success" sx={{ mt: 2 }}>
        <div style={{ marginBottom: "8px" }}>
          <strong>Correct answer:</strong>
        </div>
        <Droppable droppableId="droppableCorrect" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver, true)}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        true
                      )}
                    >
                      {item.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Alert>

      {hint && (
        <Alert
          severity="info"
          iconMapping={{ info: <WbIncandescentIcon fontSize="inherit" /> }}
          sx={{ mt: 2 }}
        >
          <strong>Hint:</strong> {parse(hint)}
        </Alert>
      )}

      {solution && (
        <Alert
          severity="warning"
          iconMapping={{
            warning: <RadioButtonCheckedIcon fontSize="inherit" />,
          }}
          sx={{ mt: 2 }}
        >
          <strong>Solution:</strong> {parse(solution)}
        </Alert>
      )}
    </Box>
  );
};

export default HorizontalSortType;
