import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Box,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import {
  GET_CENTRES,
  GET_EASY_COURSES,
  GET_EASY_TERMS,
  GET_NSDC_GRADES,
  GET_PROGRAM_REGISTRATIONS,
  GET_REGIONS,
} from "../graphql/queries";
import {
  CREATE_PROGRAM_REGISTRATION,
  DELETE_PROGRAM_REGISTRATION,
} from "../graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";

const schema = yup.object({
  enrolFrom: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  enrolTo: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  availableFrom: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  availableTo: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  easyCourse: yup.object().nullable(),
  grade: yup.object().nullable(),
  term: yup.object().nullable(),
  regionId: yup.number().required(),
  centre: yup.object().nullable(),
});

const ProgramRegistrations = ({ program_id }) => {
  // let programPref = {...preferences.progReg};
  const { enqueueSnackbar } = useSnackbar();
  const [registrations, setRegistrations] = useState([]);
  const [openReg, setOpenReg] = useState(false);
  const [easyCourses, setEasyCourses] = useState([]);
  const [easyTerms, setEasyTerms] = useState([]);
  const [nsdcGrades, setNsdcGrades] = useState([]);
  const [centres, setCentres] = useState([]);
  const [openDel, setOpenDel] = useState(false);
  const [chosenRegistration, setChosenRegistration] = useState(null);

  const [getProgramRegistrations] = useLazyQuery(GET_PROGRAM_REGISTRATIONS, {
    onCompleted: (registrations) => {
      setRegistrations(registrations.getProgramRegistrations);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (!openReg)
      getProgramRegistrations({
        variables: {
          program_id,
        },
      });
  }, [openReg, program_id]);

  const [getEasyCourses] = useLazyQuery(GET_EASY_COURSES, {
    fetchPolicy: "network-only",
    onCompleted: ({ getEasyCourses }) => {
      setEasyCourses(getEasyCourses);
    },
  });

  const [getEasyTerms] = useLazyQuery(GET_EASY_TERMS, {
    fetchPolicy: "network-only",
    onCompleted: ({ getEasyTerms }) => {
      setEasyTerms(getEasyTerms);
    },
  });

  const [getNsdcGrades] = useLazyQuery(GET_NSDC_GRADES, {
    fetchPolicy: "network-only",
    onCompleted: ({ getNsdcGrades }) => {
      setNsdcGrades(getNsdcGrades);
    },
  });

  const [getCentres] = useLazyQuery(GET_CENTRES, {
    fetchPolicy: "network-only",
    onCompleted: ({ getCentres }) => {
      setCentres(getCentres);
    },
  });

  const [createProgramRegistration] = useMutation(CREATE_PROGRAM_REGISTRATION, {
    onCompleted: ({ createProgramRegistration }) => {
      if (createProgramRegistration) {
        enqueueSnackbar(`Registration successfully created`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          `There was a problem with creating the registration, please try again.`,
          {
            variant: "warning",
          }
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
    fetchPolicy: "network-only",
  });

  const { data: regionData } = useQuery(GET_REGIONS, {
    fetchPolicy: "network-only",
  });

  const [deleteProgramRegistration] = useMutation(DELETE_PROGRAM_REGISTRATION, {
    onCompleted: (reg) => {
      if (reg.deleteProgramRegistration === true) {
        enqueueSnackbar(`Registration deleted sucessfully`, {
          variant: "success",
        });
        getProgramRegistrations({
          variables: {
            program_id,
          },
        });
      } else {
        enqueueSnackbar(`Error deleting Program Registration`, {
          variant: "error",
        });
      }
    },
    fetchPolicy: "network-only",
  });

  function getRegionName(params) {
    return params.row.Region?.name;
  }

  function getCentreName(params) {
    return params.row.Centre?.name;
  }

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Registration"
            color="primary"
            onClick={() => {
              setOpenDel(true);
              setChosenRegistration(params);
            }}
          />,
        ],
      },
      {
        field: "id",
        headerName: "Map ID",
        flex: 1,
        headerAlign: "center",
        align: "center",
        hide: true,
      },
      {
        field: "regionId",
        headerName: "Region",
        flex: 1,
        headerAlign: "center",
        align: "center",
        valueGetter: getRegionName,
      },
      {
        field: "centreId",
        headerName: "Centre",
        flex: 1,
        headerAlign: "center",
        align: "center",
        valueGetter: getCentreName,
      },
      {
        field: "gradeName",
        headerName: "Grade",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "termName",
        headerName: "Term",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "easyCourseName",
        headerName: "EASy Course",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "enrolFrom",
        headerName: "Enrolled From",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      },
      {
        field: "enrolTo",
        headerName: "Enrolled To",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      },
      {
        field: "availableFrom",
        headerName: "Available From",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      },
      {
        field: "availableTo",
        headerName: "Available To",
        flex: 1,
        headerAlign: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      },
    ],
    []
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      enrolFrom: null,
      enrolTo: null,
      availableFrom: null,
      availableTo: null,
      easyCourse: null,
      grade: null,
      term: null,
      regionId: null,
      centre: null,
    },
  });

  const onSubmit = async ({
    enrolFrom,
    enrolTo,
    availableFrom,
    availableTo,
    easyCourse,
    grade,
    term,
    regionId,
    centre,
  }) => {
    await createProgramRegistration({
      variables: {
        programId: program_id,
        regionId,
        ...(easyCourse?.id
          ? {
              easyCourseId: Number(easyCourse.id),
              easyCourseName: easyCourse.name,
            }
          : {}),
        ...(enrolFrom && enrolTo ? { enrolFrom, enrolTo } : {}),
        ...(availableFrom && availableTo ? { availableFrom, availableTo } : {}),
        ...(grade?.Region_yearID
          ? { gradeId: grade.Region_yearID, gradeName: grade.FullName }
          : {}),
        ...(term?.id ? { termId: Number(term.id), termName: term.name } : {}),
        ...(centre?.id ? { centreId: centre.id } : {}),
      },
    });
    reset({
      enrolFrom: null,
      enrolTo: null,
      availableFrom: null,
      availableTo: null,
      easyCourse: null,
      grade: null,
      term: null,
      regionId: null,
      centre: null,
    });
    setOpenReg(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const regionId = watch("regionId");
      if (regionId) {
        await getEasyCourses({
          variables: {
            regionId,
          },
        });
        await getEasyTerms({
          variables: {
            regionId,
          },
        });
        await getNsdcGrades({
          variables: {
            regionId,
          },
        });
        await getCentres({
          variables: {
            regionId,
          },
        });
      }
    };

    fetchData();
  }, [watch("regionId")]);

  const deleteReg = () => {
    deleteProgramRegistration({
      variables: { registrationId: chosenRegistration?.row.id },
    });
    setOpenDel(false);
  };

  return (
    <>
      <Dialog
        open={openDel}
        onClose={() => {
          setChosenRegistration(null);
          setOpenDel(false);
        }}
      >
        <DialogTitle>Delete Program Registration</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this registration?
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => setOpenDel(false)}>Cancel</Button>
          <Button onClick={() => deleteReg()}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenReg(false)}
        open={openReg}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Add New Registration</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ pt: 1, pb: 1 }}
          >
            <Grid container spacing={0.1} columnSpacing={1}>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Controller
                  name="regionId"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.regionId ? true : false}
                    >
                      <InputLabel>Region</InputLabel>
                      <Select label="Region" {...field} defaultValue="">
                        {regionData?.getRegions.map(({ id, name }) => (
                          <MenuItem value={id} key={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.regionId?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Controller
                  name="centreId"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.centre ? true : false}
                    >
                      <Autocomplete
                        options={centres}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Centre"
                            margin="normal"
                          />
                        )}
                        disabled={centres.length === 0}
                        onChange={(event, newValue) =>
                          setValue("centre", newValue)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.centre?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="easyCourse"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.easyCourse ? true : false}
                    >
                      <Autocomplete
                        options={easyCourses}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="EASy Course"
                            margin="normal"
                          />
                        )}
                        disabled={easyCourses.length === 0}
                        onChange={(event, newValue) =>
                          setValue("easyCourse", newValue)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.easyCourse?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="term"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.term ? true : false}
                    >
                      <Autocomplete
                        options={easyTerms}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField {...params} label="Term" margin="normal" />
                        )}
                        disabled={easyTerms.length === 0}
                        onChange={(event, newValue) =>
                          setValue("term", newValue)
                        }
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.term?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="grade"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={errors.grade ? true : false}
                    >
                      <Autocomplete
                        options={nsdcGrades}
                        getOptionLabel={(option) => option.FullName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Grade"
                            margin="normal"
                          />
                        )}
                        disabled={nsdcGrades.length === 0}
                        onChange={(event, newValue) =>
                          setValue("grade", newValue)
                        }
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.grade?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="enrolFrom"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      required
                      error={errors.enrolFrom ? true : false}
                      sx={{ width: "100%", pb: 3 }}
                    >
                      <DateTimePicker
                        renderInput={(params) => <TextField {...params} />}
                        label="Enrolled From"
                        inputFormat="dd/MM/yyyy hh:mm"
                        maxDateTime={
                          watch("enrolTo") || moment().add(1, "year")
                        }
                        minDateTime={moment().subtract(1, "year")}
                        disabled={!watch("regionId")}
                        {...field}
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.enrolFrom?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="enrolTo"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      required
                      error={errors.enrolTo ? true : false}
                      sx={{ width: "100%", pb: 3 }}
                    >
                      <DateTimePicker
                        renderInput={(params) => <TextField {...params} />}
                        label="Enrolled To"
                        inputFormat="dd/MM/yyyy hh:mm"
                        minDateTime={
                          watch("enrolFrom") || moment().subtract(1, "year")
                        }
                        maxDateTime={moment().add(1, "year")}
                        disabled={!watch("enrolFrom")}
                        {...field}
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.enrolTo?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="availableFrom"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      required
                      error={errors.availableFrom ? true : false}
                      sx={{ width: "100%" }}
                    >
                      <DateTimePicker
                        renderInput={(params) => <TextField {...params} />}
                        label="Available From"
                        inputFormat="dd/MM/yyyy hh:mm"
                        maxDateTime={
                          watch("availableTo") || moment().add(1, "year")
                        }
                        minDateTime={moment().subtract(1, "year")}
                        disabled={!watch("regionId")}
                        {...field}
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.availableFrom?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="availableTo"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      required
                      error={errors.availableTo ? true : false}
                      sx={{ width: "100%" }}
                    >
                      <DateTimePicker
                        renderInput={(params) => <TextField {...params} />}
                        label="Available To"
                        inputFormat="dd/MM/yyyy hh:mm"
                        minDateTime={
                          watch("availableFrom") || moment().subtract(1, "year")
                        }
                        maxDateTime={moment().add(1, "year")}
                        disabled={!watch("availableFrom")}
                        {...field}
                      />
                      <FormHelperText sx={{ color: "primary.main" }}>
                        {errors.availableTo?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={10} />
              <Grid item xs={2}>
                <Box>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    //disabled={}
                    sx={{ mr: 2 }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    //disabled={}
                    onClick={() => setOpenReg(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
        <Paper elevation={0} sx={{ p: 3, minHeight: "100%" }}>
          <Stack direction="row" justifyContent="space-between" sx={{ pb: 3 }}>
            <Box />
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenReg(true)}
            >
              Add Registration
            </Button>
          </Stack>
          <DataGridPro
            rows={registrations}
            columns={columns}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            pagination
            density="compact"
          />
        </Paper>
      </Container>
    </>
  );
};

export default ProgramRegistrations;
