import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ALLOCATED_TEACHERS_BY_CLASS,
  GET_ALL_TEACHERS,
} from "../../graphql/queries";
import {
  ALLOCATE_TEACHER_TO_CLASS,
  DEALLOCATE_TEACHER,
} from "../../graphql/mutations";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import renderCellExpand from "../../widgets/renderCellExpand";
import { useParams, useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const SelectTeacher = () => {
  const { enqueueSnackbar } = useSnackbar();
  let { id: class_id } = useParams();
  const [unselectedTeachers, setUnselectedTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteTeacherID, setDeleteTeacherID] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const history = useHistory();

  const [getAllTeachers, { loading: getAllTeachersLoading }] = useLazyQuery(
    GET_ALL_TEACHERS,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ getAllTeachers }) => {
        if (getAllTeachers?.length !== 0) {
          setUnselectedTeachers(
            getAllTeachers.filter(
              (t) => !selectedTeachers.find((s) => s.id === t.id)
            )
          );
        }
      },
      onError: (error) => {
        enqueueSnackbar(`${error}`, { variant: "error" });
      },
    }
  );

  const [getAllocatedTeachersByClass, { loading: allocatedTeachersLoading }] =
    useLazyQuery(GET_ALLOCATED_TEACHERS_BY_CLASS, {
      fetchPolicy: "no-cache",
      onCompleted: ({ getAllocatedTeachersByClass }) => {
        setSelectedTeachers(getAllocatedTeachersByClass);
        getAllTeachers({
          variables: {
            region_id: history.location.state?.data.regionId,
            centre_id: history.location.state?.data.centreId,
          },
        });
      },
      onError: (error) => {
        enqueueSnackbar(`${error}`, { variant: "error" });
      },
    });

  const [deallocateTeacher] = useMutation(DEALLOCATE_TEACHER, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ deallocateTeacher }) => {
      if (deallocateTeacher) {
        enqueueSnackbar("Teacher has been successfully deallocated. ", {
          variant: "success",
        });
        setDeleteDialog(false);
      }
    },
    refetchQueries: [
      {
        query: GET_ALLOCATED_TEACHERS_BY_CLASS,
        variables: { class_id: Number(class_id) },
      },
      "getAllocatedTeachersByClass",
    ],
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });

  const [allocateTeacherToClass] = useMutation(ALLOCATE_TEACHER_TO_CLASS, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ allocateTeacherToClass }) => {
      if (allocateTeacherToClass) {
        enqueueSnackbar(
          `${selectionModel?.length} Teacher(s) successfully allocated.`,
          { variant: "success" }
        );
        if (selectionModel) {
          setUnselectedTeachers(
            unselectedTeachers?.filter((t) => !selectionModel.includes(t.id))
          );
        }
      }
    },
    refetchQueries: [
      {
        query: GET_ALLOCATED_TEACHERS_BY_CLASS,
        variables: { class_id: Number(class_id) },
      },
      "getAllocatedTeachersByClass",
    ],
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });

  useEffect(() => {
    if (class_id)
      getAllocatedTeachersByClass({
        variables: { class_id: Number(class_id) },
      });
  }, [class_id]);

  const removeTeacher = useCallback(
    (params) => () => {
      setDeleteTeacherID(params.id);
      setDeleteDialog(true);
    },
    []
  );

  const handleDeallocateTeacher = () => {
    deallocateTeacher({
      variables: {
        class_id: Number(class_id),
        teacher_id: Number(deleteTeacherID),
      },
    });
  };

  const handleAddTeacherToClass = () => {
    allocateTeacherToClass({
      variables: { class_id: Number(class_id), teacher_ids: selectionModel },
    });
  };

  const unselectedTeacherColumns = [
    {
      field: "name",
      headerName: "Teacher Name",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params.row.givenname + " " + params.row.surname,
    },
    {
      field: "classes",
      headerName: "Assigned Classes",
      flex: 5,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.Classes?.map((c) => c.Class.name).join(", "),
      renderCell: renderCellExpand,
    },
  ];

  const selectedTeacherColumns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        flex: 1,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remove"
            color="primary"
            onClick={removeTeacher(params)}
          />,
        ],
      },
      {
        field: "name",
        headerName: "Teacher Name",
        flex: 2,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => params.row.givenname + " " + params.row.surname,
      },
      {
        field: "classes",
        headerName: "Assigned Classes",
        flex: 5,
        headerAlign: "center",
        align: "center",
        valueGetter: (params) =>
          params.row.Classes?.map((c) => c.Class.name).join(", "),
        renderCell: renderCellExpand,
      },
    ],
    [removeTeacher]
  );

  return (
    <Paper elevation={0} sx={{ p: 1 }}>
      <Dialog open={deleteDialog}>
        <DialogTitle>Deselect Teacher</DialogTitle>
        <DialogContent>
          Are you sure you want to deselect this teacher?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeallocateTeacher}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        sx={{
          background: "#F8F8F8",
          border: "1px solid lightgray",
          p: 2,
          borderRadius: 1.2,
        }}
      >
        <Grid item xs={5.9}>
          <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
            Unselected Teachers
          </Typography>
          <DataGridPro
            rows={unselectedTeachers}
            columns={unselectedTeacherColumns}
            // componentsProps={{
            //   toolbar: {
            //     showQuickFilter: true,
            //     quickFilterProps: { debounceMs: 500 },
            //   }
            // }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            checkboxSelection={true}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            autoHeight
            loading={getAllTeachersLoading}
            pagination={true}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            density="compact"
          />
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={5.9}>
          <Typography color="primary" sx={{ mb: 2, fontWeight: 500 }}>
            Selected Teachers
          </Typography>
          <DataGridPro
            rows={selectedTeachers}
            columns={selectedTeacherColumns}
            density="compact"
            // componentsProps={{
            //   toolbar: {
            //     showQuickFilter: true,
            //     quickFilterProps: { debounceMs: 500 },
            //   }
            // }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            loading={allocatedTeachersLoading}
            pagination={true}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: 2 }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={selectionModel && selectionModel?.length === 0}
            onClick={handleAddTeacherToClass}
            startIcon={<AddCircleIcon />}
          >
            Add Teacher(s)
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SelectTeacher;
