import React, { useEffect, useState, useCallback, useContext } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Split } from "@geoffcox/react-splitter";
import parse from "html-react-parser";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ModuleContext } from "../../App";

const SortOrderType = ({
  currentQuestion: {
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
  },
}) => {
  const moduleValue = useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const [initialItems, setInitialItems] = useState([]);

  // Memoize formatList to prevent unnecessary re-renders
  const formatList = useCallback(
    (list) => {
      let indexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      if (list) indexes = JSON.parse("[" + list + "]");
      let result = [];
      for (let i of indexes) {
        switch (i) {
          case 1:
            result.push({ name: answer1, id: "1" });
            break;
          case 2:
            result.push({ name: answer2, id: "2" });
            break;
          case 3:
            result.push({ name: answer3, id: "3" });
            break;
          case 4:
            result.push({ name: answer4, id: "4" });
            break;
          case 5:
            if (answer5) result.push({ name: answer5, id: "5" });
            break;
          case 6:
            if (answer6) result.push({ name: answer6, id: "6" });
            break;
          case 7:
            if (answer7) result.push({ name: answer7, id: "7" });
            break;
          case 8:
            if (answer8) result.push({ name: answer8, id: "8" });
            break;
          case 9:
            if (answer9) result.push({ name: answer9, id: "9" });
            break;
          case 10:
            if (answer10) result.push({ name: answer10, id: "10" });
            break;
          default:
        }
      }

      if (moduleValue.moduleSettings.selectedAnswer) {
        const order = moduleValue.moduleSettings.selectedAnswer.split(",");
        return result.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
      }

      return result;
    },
    [
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
      answer6,
      answer7,
      answer8,
      answer9,
      answer10,
      moduleValue.moduleSettings.selectedAnswer,
    ]
  );

  useEffect(() => {
    setInitialItems(formatList());
  }, [
    id,
    content,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8,
    answer9,
    answer10,
    correctAns,
    formatList, // Ensure formatList is properly memoized
  ]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 3,
    marginBottom: `${grid}px`,
    width: "100%",
    background: isDragging ? "lightgreen" : "grey",
    color: "black",
    fontSize: "1.4rem",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    overflow: "auto",
    maxWidth: "40vw",
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = reorder(
      [...initialItems],
      result.source.index,
      result.destination.index
    );

    moduleValue.setModuleSettings({
      ...moduleValue.moduleSettings,
      selectedAnswer: newItems.map((i) => i.id).toString(),
    });

    setInitialItems(newItems);
  };

  // useEffect(() => {
  //   console.log("Updated initialItems:", initialItems);
  // }, [initialItems]);

  return (
    <Split
      minPrimarySize="15%"
      minSecondarySize="15%"
      initialPrimarySize="50%"
      resetOnDoubleClick
      splitterSize="48px"
      defaultSplitterColors={splitterColors}
    >
      <Box sx={{ pb: 2 }}>
        {content && <Typography variant="h6">{parse(content)}</Typography>}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {initialItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Split>
  );
};

export default SortOrderType;
