import React from "react";
import { Split } from "@geoffcox/react-splitter";
import { Box } from "@mui/system";
import {
  Alert,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { ModuleContext } from "../../App";

const MultipleInputBoxType = ({
  currentQuestion: { content, correctAns, src, hint, solution },
  moduleType,
  isAssessmentAnswerCorrect,
  readOnly,
  isTrainingAnswerCorrect,
  isResultShown,
  isReview,
}) => {
  const moduleValue = React.useContext(ModuleContext);
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const options = {
    replace: ({ attribs }) => {
      if (!attribs) {
        return;
      }

      if (attribs["data-value"]) {
        const parsedHtml = attribs["data-value"];
        return <Latex>{`$${parsedHtml}$`}</Latex>;
      }
    },
  };

  const handleInputChange = ({ target }) => {
    const re = /^[A-Za-z0-9.+\-*/():]+$/;
    if (target.value === "" || re.test(target.value)) {
      let array = [
        ...moduleValue.moduleSettings.selectedAnswer.split("[split]"),
      ];
      array[Number(target.id)] = target.value;
      moduleValue.setModuleSettings({
        ...moduleValue.moduleSettings,
        selectedAnswer: array.join("[split]"),
      });
    }
  };

  const getCorrectAnswer = () =>
    correctAns
      ?.split("[or]")
      .map((a) => a.split("[split]").join(", "))
      .join(" or ");
  const questionUnitSplitBefore = () => content.split("[unit-before]");
  const questionUnitSplitAfter = () => content.split("[unit-after]");

  const handleBefore = () => {
    const question = questionUnitSplitBefore();
    question.splice(0, 1);
    return (
      <FormControl
        variant="standard"
        sx={{
          minWidth: "200px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <Stack direction="row">
          {question.map((q, i) => (
            <>
              <Stack direction="column" justifyContent="space-between">
                <Box />
                <Typography variant="h6" sx={{ ml: 1, mr: 2 }}>
                  {parse(q, options)}
                </Typography>
                <Box />
              </Stack>
              <TextField
                id={i}
                variant="outlined"
                onChange={handleInputChange}
                value={
                  moduleValue.moduleSettings.selectedAnswer?.split("[split]")[i]
                }
                type="input"
                sx={{
                  minWidth: "100px",
                  maxWidth: "100px",
                }}
                disabled={readOnly}
              />
            </>
          ))}
        </Stack>
      </FormControl>
    );
  };

  const handleAfter = () => {
    const question = questionUnitSplitAfter();
    question.splice(0, 1);
    return (
      <FormControl
        variant="standard"
        sx={{
          minWidth: "200px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <Stack direction="row">
          {question.map((q, i) => (
            <>
              <TextField
                id={i}
                variant="outlined"
                onChange={handleInputChange}
                value={
                  moduleValue.moduleSettings.selectedAnswer.split("[split]")[i]
                }
                type="input"
                sx={{
                  maxWidth: "50px",
                }}
                disabled={readOnly}
              />
              <Stack direction="column" justifyContent="space-between">
                <Box />
                <Typography variant="h6" sx={{ ml: 1, mr: 2 }}>
                  {parse(q, options)}
                </Typography>
                <Box />
              </Stack>
            </>
          ))}
        </Stack>
      </FormControl>
    );
  };

  return (
    <Box sx={{ pb: 2, pl: 3 }}>
      <Split
        minPrimarySize="15%"
        minSecondarySize="15%"
        initialPrimarySize="50%"
        resetOnDoubleClick
        splitterSize="32px"
        defaultSplitterColors={splitterColors}
      >
        <div style={{ overflow: "auto", height: "100%" }}>
          {src && <Typography variant="h6">{parse(src, options)}</Typography>}
          {!src && content && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {questionUnitSplitBefore().length <= 1 &&
                  questionUnitSplitAfter().length <= 1 &&
                  parse(content, options)}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {questionUnitSplitBefore().length > 1 &&
                  parse(questionUnitSplitBefore()[0], options)}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {questionUnitSplitAfter().length > 1 &&
                  parse(questionUnitSplitAfter()[0], options)}
              </Typography>
            </>
          )}
        </div>
        <div style={{ overflow: "auto", height: "100%" }}>
          {src && content && (
            <>
              <Typography variant="h6">
                {questionUnitSplitBefore().length <= 1 &&
                  questionUnitSplitAfter().length <= 1 &&
                  parse(content, options)}
              </Typography>
              <Typography variant="h6">
                {questionUnitSplitBefore().length > 1 &&
                  parse(questionUnitSplitBefore()[0], options)}
              </Typography>
              <Typography variant="h6">
                {questionUnitSplitAfter().length > 1 &&
                  parse(questionUnitSplitAfter()[0], options)}
              </Typography>
            </>
          )}
          <Stack direction="row" alignItems="center" sx={{ ml: 2, mb: 2 }}>
            {questionUnitSplitBefore().length > 1 && handleBefore()}
            {questionUnitSplitAfter().length > 1 && handleAfter()}
          </Stack>
          {moduleValue.moduleSettings?.moduleType === "Diagnostic" &&
            moduleValue.moduleSettings?.currentQuestion?.attempts > 1 &&
            moduleValue.moduleSettings?.attempt > 1 &&
            moduleValue.moduleSettings?.attempt <=
              moduleValue.moduleSettings?.currentQuestion?.attempts && (
              <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                Your answer was incorrect! Please try again.
              </Alert>
            )}
          {moduleValue.moduleSettings?.moduleType === "Diagnostic" &&
            moduleValue.moduleSettings?.currentQuestion?.attempts > 1 &&
            moduleValue.moduleSettings?.attempt > 1 &&
            moduleValue.moduleSettings?.attempt >
              moduleValue.moduleSettings?.currentQuestion?.attempts && (
              <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                Your answer was incorrect and you are now out of attempts.
                Please click 'Next' to continue.
              </Alert>
            )}
          {isResultShown &&
            !isReview &&
            (isTrainingAnswerCorrect || isAssessmentAnswerCorrect) && (
              <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
                <strong>Correct!</strong>
              </Alert>
            )}
          {correctAns &&
            isResultShown &&
            !isTrainingAnswerCorrect &&
            !isAssessmentAnswerCorrect && (
              <Alert severity="error" sx={{ ml: 2, mb: 2 }}>
                <strong>Wrong answer.</strong>
              </Alert>
            )}
          {correctAns &&
            !isReview &&
            isResultShown &&
            !isTrainingAnswerCorrect &&
            !isAssessmentAnswerCorrect && (
              <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
                <strong>Correct answer:</strong> {getCorrectAnswer()}
              </Alert>
            )}
          {correctAns && isReview && (
            <Alert severity="success" sx={{ ml: 2, mb: 2 }}>
              <strong>Correct answer:</strong> {getCorrectAnswer()}
            </Alert>
          )}
          {moduleType === "Training" &&
            hint &&
            isResultShown &&
            !isTrainingAnswerCorrect && (
              <Alert
                severity="info"
                iconMapping={{
                  info: <WbIncandescentIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2, mb: 2 }}
              >
                <strong>
                  Hint: <br></br>
                </strong>
                {parse(hint, options)}
              </Alert>
            )}
          {moduleType === "Assessment" && hint && (
            <Alert
              severity="info"
              iconMapping={{
                info: <WbIncandescentIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2, mb: 2 }}
            >
              <strong>
                Hint: <br></br>
              </strong>
              {parse(hint, options)}
            </Alert>
          )}
          {moduleType === "Training" &&
            solution &&
            isResultShown &&
            !isTrainingAnswerCorrect && (
              <Alert
                severity="warning"
                iconMapping={{
                  warning: <RadioButtonCheckedIcon fontSize="inherit" />,
                }}
                sx={{ ml: 2 }}
              >
                <strong>
                  Solution: <br></br>
                </strong>
                {parse(solution, options)}
              </Alert>
            )}
          {moduleType === "Assessment" && solution && isResultShown && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <RadioButtonCheckedIcon fontSize="inherit" />,
              }}
              sx={{ ml: 2 }}
            >
              <strong>
                Solution: <br></br>
              </strong>
              {parse(solution, options)}
            </Alert>
          )}
        </div>
      </Split>
    </Box>
  );
};

export default MultipleInputBoxType;
