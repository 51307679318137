import { Typography, Stack } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import sampleBar from "../assets/sample bar.PNG";

const BarReportInstructions = () => {
  return (
    <Box sx={{ margin: 3 }}>
      <Typography variant="h6" component="div">
        Interpreting the result bars:
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <img
          src={sampleBar}
          alt=" showing an example bar graph"
          style={{ width: "25%", height: "35%" }}
        />
        <Box sx={{ pl: "5vw" }}>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Each bar shows your child's performance in each strand compared to
            their peers.
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Your child's position on the graph is denoted by a black arrow.
          </Typography>
          <br />
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            The bar is split into four parts which group a percentage of
            students:
          </Typography>
          <ol>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Students who performed within the lowest 50% of students tested.
                Half of all students who sat the test performed in this range.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Students who performed better than 50% of students tested, but
                lower than the top 25% of students.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Students who are in the 15% bar performed better than the lowest
                75% of students who sat the test, but lower than the top 10% of
                students.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Students who are in the 10% bar performed better than 90% of
                students who sat the test and are in the top 10% of students who
                sat the test.
              </Typography>
            </li>
          </ol>
        </Box>
      </Stack>
    </Box>
  );
};

export default BarReportInstructions;
