import { Typography, Stack } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import sampleReading from "../assets/sample reading.PNG";

const ReadingReportInstructions = () => {
  return (
    <Box sx={{ margin: 3 }}>
      <Typography variant="h6" component="div">
        Interpreting the reading result:
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <img
          src={sampleReading}
          alt=" showing an example graph of the reading component"
          style={{ width: "25%", height: "25%" }}
        />
        <Box sx={{ pl: "11vw" }}>
          <br />
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Each graph represents your child's reading speed and accuracy for
            each passage or strand depending on the type of report you are
            reading.
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Your child's position on the graph is denoted by a red dot with the
            word "You" written next to it.
          </Typography>
          <br />
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            The graph is split into two axes, speed on the horizontal axis and
            accuracy on the vertical.
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Students who are closer to the right side of the graph complete
            questions faster than other students in their cohort.
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Students who are closer to the top of the graph answer more
            questions correct compared to other students in their cohort.
          </Typography>
          <br />
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Ideally, students aim to be placed in the top right corner, which
            shows that they are able to complete questions quickly and
            accurately.
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Students in the top left corner are able to answer questions
            accurately but need to improve their speed.
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Students in the bottom right corner are able to answer questions
            quickly but need to improve their accuracy.
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            Students in the bottom left corner need to improve both their speed
            and accuracy.
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default ReadingReportInstructions;
