import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import { GET_DIAGNOSTIC_SITTINGS } from "../graphql/queries";
import { DELETE_DIAGNOSTIC_SITTING } from "../graphql/mutations";
import DeleteIcon from "@mui/icons-material/Delete";

const DiagnosticSittings = ({ programId }) => {
  const { preferences, setPreferences } = useContext(PreferenceContext);
  const { userRole } = useContext(AuthenticatedContext);
  let sitPref = { ...preferences.diagSittings };
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(sitPref.pageSize || 10);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const [getDiagnosticSittings, { sittingsLoading }] = useLazyQuery(
    GET_DIAGNOSTIC_SITTINGS,
    {
      onCompleted: (sittings) => {
        if (sittings?.getDiagnosticSittings.sittings.length > 0) {
          setRows(sittings.getDiagnosticSittings.sittings);
        }
        setRowCount(sittings.getDiagnosticSittings.total);
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );

  const [deleteDiagnosticSitting] = useMutation(DELETE_DIAGNOSTIC_SITTING, {
    onCompleted: (sitting) => {
      if (sitting.deleteDiagnosticSitting === true) {
        enqueueSnackbar(`Diagnostic Sitting deleted`, {
          variant: "success",
        });
        if (page === 0) {
          getDiagnosticSittings({
            variables: {
              program_id: programId,
              skip: page * pageSize,
              take: pageSize,
              filters: sitPref.filters,
              sort: sitPref.sort,
            },
          });
        } else {
          setPage(0);
        }
      } else {
        enqueueSnackbar(`No diagnostic sitting was found`, {
          variant: "error",
        });
      }
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getDiagnosticSittings({
      variables: {
        program_id: programId,
        skip: page * pageSize,
        take: pageSize,
        filters: sitPref.filters || {
          items: [],
          linkOperator: "and",
          quickFilterValues: [],
          quickFilterLogicOperator: "and",
          quickFilterExcludeHiddenColumns: true,
        },
        sort: sitPref.sort || [{ field: "id", sort: "asc" }],
      },
    });
  }, [
    page,
    // pageSize,
    programId,
    // sitPref.filters,
    // sitPref.sort,
  ]);

  const handlePageSizeChange = (newPageSize) => {
    sitPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      diagSittings: {
        ...sitPref,
      },
    });
    if (page === 0) {
      getDiagnosticSittings({
        variables: {
          program_id: programId,
          skip: page * pageSize,
          take: pageSize,
          filters: sitPref.filters,
          sort: sitPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    sitPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      diagSittings: {
        ...sitPref,
      },
    });
  };

  const handleColumnOrderChange = (params) => {
    sitPref.columns.splice(params.oldIndex, 1);
    sitPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      diagSittings: {
        ...sitPref,
      },
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    sitPref.visible = { ...model };
    setPreferences({
      ...preferences,
      diagSittings: {
        ...sitPref,
      },
    });
  };

  const handleSortModelChange = (model) => {
    sitPref.sort = [...model];

    setPreferences({
      ...preferences,
      diagSittings: {
        ...sitPref,
      },
    });
    if (page === 0) {
      getDiagnosticSittings({
        variables: {
          program_id: programId,
          skip: page * pageSize,
          take: pageSize,
          filters: sitPref.filters,
          sort: sitPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    sitPref.filters = { ...model };

    setPreferences({
      ...preferences,
      diagSittings: {
        ...sitPref,
      },
    });
    if (page === 0) {
      getDiagnosticSittings({
        variables: {
          program_id: programId,
          skip: page * pageSize,
          take: pageSize,
          filters: sitPref.filters,
          sort: sitPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  function getFullName(params) {
    return `${params.row.givenname || ""} ${params.row.surname || ""}`;
  }

  function getIsCompleted(params) {
    return params.row.isCompleted;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const deleteSittingStart = () => {
    deleteDiagnosticSitting({
      variables: { program_id: programId, student_id: selectedRow.studentId },
    });
    setDialog(false);
  };

  const startDelete = (params) => {
    setSelectedRow(params.row);
    setDialog(true);
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: sitPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="View"
            color="primary"
            onClick={() => startDelete(params)}
          />,
        ],
      },
      {
        field: "id",
        headerName: "Sitting ID",
        width: sitPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        hide:
          !sitPref?.visible?.id ||
          !["Admin", "Education Consultant"].includes(userRole),
        getApplyQuickFilterFn: undefined,
        type: "number",
      },
      {
        field: "studentId",
        headerName: "Student ID",
        width: sitPref?.widths?.studentId,
        headerAlign: "center",
        align: "center",
        hide:
          !sitPref?.visible?.studentId ||
          !["Admin", "Education Consultant"].includes(userRole),
        getApplyQuickFilterFn: undefined,
        type: "number",
      },
      {
        field: "studentNumber",
        headerName: "Student Number",
        width: sitPref?.widths?.studentNumber,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.studentNumber,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "name",
        headerName: "Name",
        width: sitPref?.widths?.name,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.name,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getFullName,
      },
      {
        field: "campus",
        headerName: "Campus",
        width: sitPref?.widths?.campus,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.campus,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "isCompleted",
        headerName: "Is Completed",
        width: sitPref?.widths?.isCompleted,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.isCompleted,
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsCompleted,
        type: "boolean",
      },
      {
        field: "upTo",
        headerName: "Progress",
        width: sitPref?.widths?.upTo,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.upTo,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "route",
        headerName: "Route",
        width: sitPref?.widths?.route,
        headerAlign: "center",
        align: "center",
        hide: !sitPref?.visible?.route,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "createdAt",
        headerName: "Started At",
        width: sitPref?.widths?.createdAt,
        headerAlign: "center",
        hide: !sitPref?.visible?.createdAt,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      },
      {
        field: "completedDate",
        headerName: "Completed At",
        width: sitPref?.widths?.completedDate,
        headerAlign: "center",
        hide: !sitPref?.visible?.completedDate,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy HH:mm:ss");
          }
        },
        align: "center",
      },
    ],
    [
      // sitPref?.visible?.campus,
      // sitPref?.visible?.completedDate,
      // sitPref?.visible?.createdAt,
      // sitPref?.visible?.id,
      // sitPref?.visible?.isCompleted,
      // sitPref?.visible?.name,
      // sitPref?.visible?.route,
      // sitPref?.visible?.studentId,
      // sitPref?.visible?.studentNumber,
      // sitPref?.visible?.upTo,
      // sitPref?.widths?.actions,
      // sitPref?.widths?.campus,
      // sitPref?.widths?.completedDate,
      // sitPref?.widths?.createdAt,
      // sitPref?.widths?.id,
      // sitPref?.widths?.isCompleted,
      // sitPref?.widths?.name,
      // sitPref?.widths?.route,
      // sitPref?.widths?.studentId,
      // sitPref?.widths?.studentNumber,
      // sitPref?.widths?.upTo,
      // userRole,
    ]
  );
  columns.sort(
    (a, b) =>
      sitPref.columns?.indexOf(a.field) - sitPref.columns?.indexOf(b.field)
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={dialog}>
        <DialogTitle>Delete Sitting</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this sitting?
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => setDialog(false)}>Cancel</Button>
          <Button onClick={deleteSittingStart}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Paper elevation={0} sx={{ p: 3 }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={sitPref.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={sittingsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{ left: ["actions"] }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterModel={sitPref.filters}
          sortModel={sitPref.sort}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          rowCount={rowCount}
          density="compact"
        />
      </Paper>
    </Container>
  );
};

export default DiagnosticSittings;
