import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { GET_ALL_COURSES, GET_ALL_PROGRAMS } from "../graphql/queries";
import { CREATE_PROGRAM, DELETE_PROGRAM } from "../graphql/mutations";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ContainerPaper } from "../components";

const ProgramManagement = () => {
  const { preferences, setPreferences, defaults } =
    React.useContext(PreferenceContext);
  const { userRole } = React.useContext(AuthenticatedContext);
  let programPref = { ...preferences.programMgmt };
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(programPref.pageSize || 10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogParams, setDeleteDialogParams] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState("");

  const schema = yup
    .object({
      name: yup.string().max(100).required("Required"),
      availableFrom: yup
        .date()
        .nullable()
        .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
      availableTo: yup
        .date()
        .nullable()
        .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
    })
    .required();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      availableFrom: null,
      availableTo: null,
    },
  });

  const [getAllPrograms, { programsLoading }] = useLazyQuery(GET_ALL_PROGRAMS, {
    fetchPolicy: "no-cache",
    onCompleted: (programs) => {
      if (programs.getAllPrograms?.programs.length >= 0) {
        setRows(programs.getAllPrograms.programs);
      }
      setRowCount(programs.getAllPrograms.total);
    },
  });

  const [getAllCourses] = useLazyQuery(GET_ALL_COURSES, {
    fetchPolicy: "no-cache",
    onCompleted: (courses) => {
      if (courses.getAllCourses?.courses.length >= 0) {
        setCourses(
          courses.getAllCourses.courses
            .map((c) => {
              return JSON.stringify(c);
            })
            .join(";")
        );
      }
    },
  });

  const [deleteProgram] = useMutation(DELETE_PROGRAM, {
    onError: (error) => {
      console.log(error);
      setDeleteDialogOpen(false);
    },
    onCompleted: ({ deleteProgram }) => {
      if (deleteProgram === true) {
        setRows(rows.filter((row) => row.id !== deleteDialogParams.id));
        enqueueSnackbar(
          `Assessment Program (id: ${deleteDialogParams.id}) has been successfully deleted`,
          {
            variant: "success",
          }
        );
      } else if (deleteProgram === false) {
        enqueueSnackbar("Assessment Program could not be found", {
          variant: "error",
        });
      }
      setDeleteDialogOpen(false);
    },
    fetchPolicy: "network-only",
  });

  const [createProgram] = useMutation(CREATE_PROGRAM, {
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(
        `A Assessment Program with the same name already exists.`,
        {
          variant: "error",
        }
      );
      reset();
      setOpen(false);
    },
    onCompleted: ({ createProgram }) => {
      if (createProgram) {
        if (page === 0) {
          getAllPrograms({
            variables: {
              skip: page * pageSize,
              take: pageSize,
              filters: programPref?.filters || defaults.programMgmt.filters,
              sort: programPref?.sort || defaults.programMgmt.sort,
            },
          });
        } else {
          setPage(0);
        }
        enqueueSnackbar("Assessment Program has been successfully created", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Failed to create Assessment Program.`, {
          variant: "error",
        });
      }
      reset();
      setOpen(false);
    },
  });

  useEffect(() => {
    getAllPrograms({
      variables: {
        skip: page * pageSize,
        take: pageSize,
        filters: programPref?.filters || defaults.programMgmt.filters,
        sort: programPref?.sort || defaults.programMgmt.sort,
      },
    });
  }, [
    defaults.programMgmt.filters,
    defaults.programMgmt.sort,
    page,
    pageSize,
    programPref?.filters,
    programPref?.sort,
  ]);

  useEffect(() => {
    getAllCourses();
  }, []);

  const handleDeleteProgram = (params) => {
    deleteProgram({ variables: { id: params.id } });
  };

  const deleteProgramFunction = useCallback(
    (params) => () => {
      setDeleteDialogOpen(true);
      setDeleteDialogParams(params.row);
    },
    []
  );

  const viewProgram = useCallback(
    (params) => () => {
      history.push(`/programmanagement/${params.row.id}`, {
        data: { ...params.row, courses },
      });
    },
    [courses, history]
  );

  const handlePageSizeChange = (newPageSize) => {
    programPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      programMgmt: {
        ...programPref,
      },
    });
    if (page === 0 && defaults) {
      getAllPrograms({
        variables: {
          skip: page * newPageSize,
          take: newPageSize,
          filters: programPref.filters,
          sort: programPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    programPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      programMgmt: {
        ...programPref,
      },
    });
  };

  const handleColumnOrderChange = (params) => {
    programPref.columns.splice(params.oldIndex, 1);
    programPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      programMgmt: {
        ...programPref,
      },
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    programPref.visible = { ...model };
    setPreferences({
      ...preferences,
      programMgmt: {
        ...programPref,
      },
    });
  };

  const handleSortModelChange = (model) => {
    programPref.sort = [...model];

    setPreferences({
      ...preferences,
      programMgmt: {
        ...programPref,
      },
    });
    if (page === 0 && defaults) {
      getAllPrograms({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: programPref.filters,
          sort: [...model],
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    programPref.filters = { ...model };

    setPreferences({
      ...preferences,
      programMgmt: {
        ...programPref,
      },
    });
    if (page === 0) {
      getAllPrograms({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: { ...model },
          sort: programPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: programPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => {
          let actions = [
            <GridActionsCellItem
              icon={
                <Tooltip title="View Program">
                  <VisibilityIcon />
                </Tooltip>
              }
              label="View"
              color="primary"
              onClick={viewProgram(params)}
            />,
          ];
          if (userRole === "Admin" || userRole === "Education Consultant") {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Delete Program">
                    <DeleteIcon />
                  </Tooltip>
                }
                label="Delete"
                color="primary"
                onClick={deleteProgramFunction(params)}
                disabled={
                  userRole !== "Admin" && userRole !== "Education Consultant"
                }
              />
            );
          }
          return actions;
        },
      },
      {
        field: "id",
        headerName: "ID",
        width: programPref?.widths?.id,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        hide:
          !programPref?.visible?.id ||
          userRole !== "Admin" ||
          userRole !== "Education Consultant",
        type: "number",
      },
      {
        field: "name",
        headerName: "Program Name",
        width: programPref?.widths?.name,
        headerAlign: "center",
        align: "center",
        hide: !programPref?.visible?.name,
        getApplyQuickFilterFn: getApplyFilterFnName,
      },
      // {
      //   field: "availableFrom",
      //   headerName: "Available From",
      //   width: programPref?.widths?.availableFrom,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   hide: !programPref?.visible?.availableFrom,
      //   getApplyQuickFilterFn: undefined,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy hh:mm");
      //     }
      //   },
      //   align: "center",
      //   filterable: false,
      // },
      // {
      //   field: "availableTo",
      //   headerName: "Available To",
      //   width: programPref?.widths?.availableTo,
      //   headerAlign: "center",
      //   type: "dateTime",
      //   hide: !programPref?.visible?.availableTo,
      //   getApplyQuickFilterFn: undefined,
      //   valueFormatter: ({ value }) => {
      //     if (value) {
      //       return format(value, "dd/MM/yyyy hh:mm");
      //     }
      //   },
      //   align: "center",
      //   filterable: false,
      // },
      {
        field: "course",
        headerName: "Course",
        width: programPref?.widths?.course,
        headerAlign: "center",
        hide: !programPref.visible?.course,
        align: "center",
      },
      {
        field: "hasDiagnostic",
        headerName: "Includes Diagnostic Test?",
        width: programPref?.widths?.hasDiagnostic,
        headerAlign: "center",
        hide: !programPref?.visible?.hasDiagnostic,
        align: "center",
        type: "boolean",
      },
      {
        field: "createdAt",
        headerName: "Created",
        width: programPref?.widths?.createdAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !programPref?.visible?.createdAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: programPref?.widths?.updatedAt,
        headerAlign: "center",
        type: "dateTime",
        hide: !programPref?.visible?.updatedAt,
        getApplyQuickFilterFn: undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
        filterable: false,
      },
    ],
    [
      programPref?.widths?.actions,
      programPref?.widths?.id,
      programPref?.widths?.name,
      programPref?.widths?.course,
      programPref?.widths?.hasDiagnostic,
      programPref?.widths?.createdAt,
      programPref?.widths?.updatedAt,
      programPref.visible?.id,
      programPref.visible?.name,
      programPref.visible?.course,
      programPref.visible?.hasDiagnostic,
      programPref.visible?.createdAt,
      programPref.visible?.updatedAt,
      userRole,
      viewProgram,
      deleteProgramFunction,
    ]
  );

  const onSubmit = ({ name }) => {
    createProgram({
      variables: {
        name,
        // availableFrom,
        // availableTo
      },
    });
  };

  return (
    <>
      <Dialog open={open} fullWidth sx={{ padding: 10 }}>
        <DialogTitle>Create Assessment Program</DialogTitle>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ pl: 2, pr: 2, pb: 2 }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.name ? true : false}
                sx={{ mb: 3 }}
              >
                <InputLabel>Name</InputLabel>
                <OutlinedInput {...field} label="Name" />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.name?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* <Stack space={2} direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
            <Controller
              name="availableFrom"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  required
                  error={errors.availableFrom ? true : false}
                >
                  <DateTimePicker 
                    renderInput={(params) => <TextField {...params} />} 
                    label="Available From"
                    inputFormat="dd/MM/yyyy hh:mm"
                    maxDateTime={watch("availableTo") || moment().add(1, "year")}
                    minDateTime={moment().subtract(1, "year")}
                    {...field}
                  />
                  <FormHelperText sx={{ color: "primary.main" }}>
                    {errors.availableFrom?.message}
                  </FormHelperText>
                </FormControl>
              )} />
              <Controller
              name="availableTo"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  required
                  error={errors.availableTo ? true : false}
                >
                  <DateTimePicker 
                    renderInput={(params) => <TextField {...params} />} 
                    label="Available To"
                    inputFormat="dd/MM/yyyy hh:mm"
                    minDateTime={watch("availableFrom") || moment().subtract(1, "year")}
                    maxDateTime={moment().add(1, "year")}
                    {...field}
                  />
                  <FormHelperText sx={{ color: "primary.main" }}>
                    {errors.availableTo?.message}
                  </FormHelperText>
                </FormControl>
              )} />
          </Stack> */}
          <DialogActions>
            <Stack
              space={2}
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  setOpen(false);
                  reset();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Create
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
      <ContainerPaper>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Program Management
          </Typography>
        </Box>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>
            {`Are you sure you want to delete this training program?`}
          </DialogTitle>
          <DialogActions sx={{ mx: 1.6, my: 1 }}>
            <Button
              onClick={() => handleDeleteProgram(deleteDialogParams)}
              color="primary"
              variant="contained"
              startIcon={<CheckCircleIcon />}
              autoFocus
            >
              Confirm
            </Button>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<CancelIcon />}
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {userRole === "Admin" && (
          <>
            <Stack space={2} direction="row" justifyContent="space-between">
              <Box />
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Create Program
              </Button>
            </Stack>
            <br />
          </>
        )}

        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={programPref.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={programsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{ left: ["actions"] }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterModel={programPref.filters}
          sortModel={programPref.sort}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          rowCount={rowCount}
          density="compact"
        />
      </ContainerPaper>
    </>
  );
};

export default ProgramManagement;
