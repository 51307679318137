import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { AuthenticatedContext, ModuleContext } from "../App";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import AppsIcon from "@mui/icons-material/Apps";
import FlagIcon from "@mui/icons-material/Flag";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { GET_FORMATTED_QUESTION } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import fail from "../assets/failed.png";
import success from "../assets/star.png";
import { useRef } from "react";
import parse from "html-react-parser";
import { useContext } from "react";

function Copyright() {
  return (
    <Typography variant="body2" color="#fff" textAlign="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        Problem Solving
      </Link>{" "}
      {new Date().getFullYear()}
      {" SolverEdge"}
    </Typography>
  );
}

export default function Footer() {
  const {
    moduleSettings,
    setModuleSettings,
    goToQuestion,
    exitSitting,
    mfSaveAnswer,
    deleteTrainingAnswers,
    updateSitting,
  } = React.useContext(ModuleContext);

  const [goNext, setGoNext] = useState(false);
  const buttonRef = useRef(null);
  const [trainingDialog, setTrainingDialog] = useState(false);

  const { userRole, loading } = useContext(AuthenticatedContext);
  const history = useHistory();
  const location = useLocation();
  const [partOneAllCorrect, setPartOneAllCorrect] = useState(
    moduleSettings.partOneAllCorrect
  );
  const [sectionOnePass, setSectionOnePass] = useState(
    moduleSettings.sectionOnePass
  );
  const [sectionTwoPass, setSectionTwoPass] = useState(
    moduleSettings.sectionTwoPass
  );

  const [enterPressed, setEnterPressed] = useState(false);

  const [getFormattedQuestion] = useLazyQuery(GET_FORMATTED_QUESTION, {
    fetchPolicy: "no-cache",
  });

  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [exitDialog, setExitDialog] = useState(false);

  // const throttleFunction = (func, delay) => {
  //   let shouldFire = true;
  //   return function() {
  //     console.log(shouldFire);
  //     if (shouldFire) {
  //         func();
  //         shouldFire = false;
  //         setTimeout(() => {
  //           shouldFire = true;
  //         }, delay)
  //     }
  //   }
  // }

  document.addEventListener("keyup", (event) => {
    if (
      event.key === "Enter" &&
      moduleSettings.selectedAnswer &&
      moduleSettings.moduleType === "Training" &&
      moduleSettings?.currentQuestion?.questionType === "InputBoxType"
    ) {
      setEnterPressed(true);
      // if (goNext) {
      //   throttleFunction(nextQuestion, 200);
      // } else {
      //   throttleFunction(checkTrainingAnswer, 200);
      // }
    }
  });

  useEffect(() => {
    if (enterPressed && moduleSettings.moduleType === "Training") {
      if (goNext) {
        nextQuestion();
      } else {
        checkTrainingAnswer();
      }
    }
  }, [enterPressed]);

  useEffect(() => {
    if (
      buttonRef.current &&
      moduleSettings.selectedAnswer &&
      moduleSettings?.currentQuestion?.questionType === "MultipleChoiceType"
    ) {
      buttonRef.current.focus();
    }
  }, [moduleSettings.selectedAnswer]);

  useEffect(() => {
    setModuleSettings({
      ...moduleSettings,
      partOneAllCorrect: partOneAllCorrect === true ? true : false,
      sectionOnePass: sectionOnePass === true ? true : false,
      sectionTwoPass: sectionTwoPass === true ? true : false,
    });
  }, [partOneAllCorrect, sectionOnePass, sectionTwoPass]);

  useEffect(() => {
    if (
      moduleSettings.selectedModule &&
      moduleSettings.moduleType === "Training"
    ) {
      if (
        moduleSettings.currentQuestionNumber <=
        moduleSettings.selectedModule.firstGroupQuestions
      ) {
        setModuleSettings({ ...moduleSettings, currentPart: 1 });
      } else if (
        moduleSettings.currentQuestionNumber >
          moduleSettings.selectedModule.firstGroupQuestions &&
        moduleSettings.currentQuestionNumber <=
          moduleSettings.selectedModule.totalQuestions
      ) {
        setModuleSettings({ ...moduleSettings, currentPart: 2 });
      } else {
        setModuleSettings({ ...moduleSettings, currentPart: 3 });
      }
    }
  }, [moduleSettings.currentQuestionNumber]);

  // useEffect(() => {
  //   if (showTrainingDialog()) setTrainingDialog(true)
  // }, [])

  useEffect(() => {
    if (moduleSettings.moduleType === "Training") {
      if (moduleSettings?.isResultShown && moduleSettings?.readOnly) {
        setGoNext(true);
      } else {
        setGoNext(false);
      }
    }
  }, [
    moduleSettings?.isResultShown,
    moduleSettings?.readOnly,
    moduleSettings.currentQuestionNumber,
  ]);

  useEffect(() => {
    if (
      moduleSettings.moduleType === "Assessment" &&
      moduleSettings.reviews?.length > 0 &&
      moduleSettings.currentQuestion &&
      userRole === "Student"
    ) {
      if (canAssessmentQuestionRedo()) {
        setModuleSettings({
          ...moduleSettings,
          readOnly: false,
          isResultShown: null,
          isAssessmentAnswerCorrect: true,
        });
      } else {
        if (moduleSettings.currentQuestion) {
          if (
            !!(
              moduleSettings.currentQuestion.correctAns.trim() ===
              moduleSettings.selectedAnswer.trim()
            )
          ) {
            setModuleSettings({
              ...moduleSettings,
              readOnly: true,
              isResultShown: true,
              isAssessmentAnswerCorrect: true,
            });
          } else {
            setModuleSettings({
              ...moduleSettings,
              readOnly: true,
              isResultShown: true,
              isAssessmentAnswerCorrect: false,
            });
          }
        }
      }
    }
  }, [moduleSettings.currentQuestion]);

  const handleTrainingDialogClose = async () => {
    setTrainingDialog(false);
    if (
      moduleSettings.selectedModule?.totalQuestions ===
      moduleSettings.selectedModule?.firstGroupQuestions
    ) {
      return setModuleSettings({
        ...moduleSettings,
        open: true,
      });
    }
    if (moduleSettings.currentPart === 2 && sectionOnePass === false) {
      try {
        const result = await deleteTrainingAnswers({
          variables: {
            sitting_id: moduleSettings.currentSitting.id,
            current_section: [moduleSettings.currentPart],
          },
        });

        if (result && result?.data?.deleteTrainingAnswers) {
          await updateSitting({
            variables: {
              module_type: moduleSettings.moduleType,
              sitting_id: moduleSettings.currentSitting.id,
              up_to: (
                moduleSettings.selectedModule.firstGroupQuestions + 1
              ).toString(),
              current_section: moduleSettings.currentPart,
            },
          });

          let currentQuestion;
          const foundQuestion = moduleSettings.questions.find(
            (q) =>
              q.order === moduleSettings.selectedModule.firstGroupQuestions + 1
          );

          if (foundQuestion.content) {
            currentQuestion = foundQuestion;
          } else {
            const result = await getFormattedQuestion({
              variables: {
                question_id: foundQuestion?.id,
                is_review:
                  moduleSettings.moduleType === "Training" ? true : false,
              },
            });
            if (result && result.data.getFormattedQuestion) {
              currentQuestion = result.data.getFormattedQuestion;
            }
          }

          setGoNext(false);
          // setTimeLeft(Date.now() + moduleSettings.selectedModule?.timeOfFirstGroupQuestions * 60 * 1000)

          return setModuleSettings({
            ...moduleSettings,
            currentQuestionNumber:
              moduleSettings.selectedModule.firstGroupQuestions + 1,
            currentQuestion,
            answers: moduleSettings.answers.slice(
              0,
              moduleSettings.selectedModule.firstGroupQuestions
            ),
            selectedAnswer: "",
            initialAnswer: "",
            openReview: false,
            isTrainingAnswerCorrect: false,
            isResultShown: false,
            readOnly: false,
            currentPart: 2,
            partOneAllCorrect: false,
            sectionOnePass: false,
            sectionTwoPass: false,
            totalScorePartTwo: "",
            totalTimeTakenPartTwo: 0,
            totalScorePartThree: "",
            totalTimeTakenPartThree: 0,
            startTime: moment(),
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (moduleSettings.currentPart === 2 && sectionOnePass === true) {
      goToQuestion(moduleSettings.selectedModule.totalQuestions + 1);
    } else if (moduleSettings.currentPart === 3 && sectionTwoPass === true) {
      return setModuleSettings({
        ...moduleSettings,
        open: true,
      });
    } else if (moduleSettings.currentPart === 3 && sectionTwoPass === false) {
      try {
        const result = await deleteTrainingAnswers({
          variables: {
            sitting_id: moduleSettings.currentSitting.id,
            current_section: [moduleSettings.currentPart],
          },
        });

        if (result && result.data.deleteTrainingAnswers) {
          await updateSitting({
            variables: {
              module_type: moduleSettings.moduleType,
              sitting_id: moduleSettings.currentSitting.id,
              up_to: (
                moduleSettings.selectedModule.totalQuestions + 1
              ).toString(),
              current_section: moduleSettings.currentPart,
            },
          });

          let currentQuestion;
          const foundQuestion = moduleSettings.questions.find(
            (q) => q.order === moduleSettings.selectedModule.totalQuestions + 1
          );

          if (foundQuestion.content) {
            currentQuestion = foundQuestion;
          } else {
            const result = await getFormattedQuestion({
              variables: {
                question_id: foundQuestion?.id,
                is_review:
                  moduleSettings.moduleType === "Training" ? true : false,
              },
            });
            if (result && result.data.getFormattedQuestion) {
              currentQuestion = result.data.getFormattedQuestion;
            }
          }

          setGoNext(false);

          return setModuleSettings({
            ...moduleSettings,
            currentQuestionNumber:
              moduleSettings.selectedModule.totalQuestions + 1,
            currentQuestion,
            answers: partOneAllCorrect
              ? moduleSettings.answers.slice(
                  0,
                  moduleSettings.selectedModule.firstGroupQuestions
                )
              : moduleSettings.answers.slice(
                  0,
                  moduleSettings.selectedModule.totalQuestions
                ),
            selectedAnswer: "",
            initialAnswer: "",
            openReview: false,
            isTrainingAnswerCorrect: false,
            isResultShown: false,
            readOnly: false,
            currentPart: 3,
            sectionTwoPass: false,
            totalScorePartThree: "",
            totalTimeTakenPartThree: 0,
            startTime: moment(),
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (
      moduleSettings.currentPart === 1 &&
      moduleSettings.partOneAllCorrect
    ) {
      goToQuestion(moduleSettings.selectedModule.totalQuestions + 1);
    } else if (
      moduleSettings.currentPart === 1 &&
      !moduleSettings.partOneAllCorrect
    ) {
      goToQuestion(moduleSettings.selectedModule.firstGroupQuestions + 1);
    }
  };

  const showTrainingDialog = () => {
    if (
      moduleSettings.selectedModule &&
      moduleSettings.moduleType === "Training" &&
      moduleSettings.reviews?.length === 0
    ) {
      if (
        moduleSettings.currentQuestion?.order ===
          moduleSettings.selectedModule?.firstGroupQuestions ||
        (moduleSettings.currentQuestion?.order ===
          moduleSettings.selectedModule?.totalQuestions &&
          Boolean(moduleSettings.selectedAnswer)) ||
        (moduleSettings.currentQuestion?.order ===
          moduleSettings.selectedModule?.totalQuestions +
            moduleSettings.selectedModule?.totalQuestions -
            moduleSettings.selectedModule?.firstGroupQuestions &&
          Boolean(moduleSettings.selectedAnswer))
      ) {
        return true;
      }
    }
    return false;
  };

  const nextQuestion = async () => {
    if (showTrainingDialog()) {
      setTrainingDialog(true);
    } else {
      if (moduleSettings.reviews?.length > 0) {
        if ((moduleSettings.moduleType === "Placement") && userRole === "Student") {
          goToQuestion(moduleSettings.incorrect[moduleSettings.incorrectIndex + 1]);
        } else {
          goToQuestion(moduleSettings.currentQuestionNumber + 1);
        }
      } else {
        goToQuestion(moduleSettings.currentQuestionNumber + 1);
      }
    }

    setGoNext(false);
    setEnterPressed(false);
  };

  const prevQuestion = () => {
    if (moduleSettings.reviews?.length > 0) {
      if (
        (moduleSettings.moduleType === "Assessment" ||
          moduleSettings.moduleType === "Placement") &&
        userRole === "Student"
      ) {
        goToQuestion(
          moduleSettings.incorrect[moduleSettings.incorrectIndex - 1]
        );
      } else {
        goToQuestion(moduleSettings.currentQuestionNumber - 1);
      }
    } else {
      goToQuestion(moduleSettings.currentQuestionNumber - 1);
    }
  };

  const checkAnswer = async () => {
    const answers = await mfSaveAnswer();
    if (answers[answers.length - 1].isCorrect === 0) {
      if (moduleSettings.attempt >= moduleSettings.currentQuestion.attempts) {
        setGoNext(true);
      }
      setModuleSettings({
        ...moduleSettings,
        attempt: moduleSettings.attempt + 1,
        ...(moduleSettings.attempt <
          moduleSettings.currentQuestion.attempts && { selectedAnswer: "" }),
        startTime: moment(),
      });
    } else {
      goToQuestion(moduleSettings.currentQuestionNumber + 1);
    }
  };

  const skipAnswer = async () => {
    goToQuestion(moduleSettings.currentQuestionNumber + 1, true);
  };

  const checkTrainingAnswer = async () => {
    let numCorrect =
      moduleSettings.currentPart === 3
        ? partOneAllCorrect === true
          ? moduleSettings.answers
              .slice(moduleSettings.selectedModule.firstGroupQuestions)
              .filter((a) => a.isCorrect).length
          : moduleSettings.answers
              .slice(moduleSettings.selectedModule.totalQuestions)
              .filter((a) => a.isCorrect).length
        : moduleSettings.currentPart === 2
        ? moduleSettings.answers
            .slice(moduleSettings.selectedModule.firstGroupQuestions)
            .filter((a) => a.isCorrect).length
        : moduleSettings.answers.filter((a) => a.isCorrect).length;

    const totalTwoSectionQuestions =
      moduleSettings.selectedModule.totalQuestions +
      moduleSettings.selectedModule.totalQuestions -
      moduleSettings.selectedModule.firstGroupQuestions;

    if (
      !!(
        moduleSettings.currentQuestion.correctAns.trim() ===
        moduleSettings.selectedAnswer.trim()
      )
    ) {
      numCorrect = numCorrect + 1;
    }

    if (
      numCorrect === moduleSettings.selectedModule.firstGroupQuestions &&
      moduleSettings.currentQuestionNumber ===
        moduleSettings.selectedModule.firstGroupQuestions
    ) {
      setPartOneAllCorrect(true);
      setSectionOnePass(true);
      setSectionTwoPass(false);
    } else if (
      moduleSettings.currentQuestionNumber ===
      moduleSettings.selectedModule.totalQuestions
    ) {
      if (
        Math.ceil(
          (numCorrect * 100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
        ) < moduleSettings.selectedModule.totalCorrectScoreSectionOne
      ) {
        setSectionOnePass(false);
        setSectionTwoPass(false);
      } else {
        setSectionOnePass(true);
        setSectionTwoPass(false);
      }
    } else if (
      moduleSettings.currentQuestionNumber === totalTwoSectionQuestions
    ) {
      if (
        Math.ceil(
          (numCorrect * 100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
        ) < moduleSettings.selectedModule.totalCorrectScoreSectionTwo
      ) {
        setSectionTwoPass(false);
      } else {
        setSectionTwoPass(true);
      }
    }

    if (
      !!(
        moduleSettings.currentQuestion.correctAns.trim() ===
        moduleSettings.selectedAnswer.trim()
      )
    ) {
      setModuleSettings({
        ...moduleSettings,
        isTrainingAnswerCorrect: true,
        isResultShown: true,
        readOnly: true,
        endTime: moment(),
      });
    } else {
      setModuleSettings({
        ...moduleSettings,
        isTrainingAnswerCorrect: false,
        isResultShown: true,
        readOnly: true,
        endTime: moment(),
      });
    }

    setGoNext(true);
  };

  const checkAssessmentAnswer = async () => {
    const answers = await mfSaveAnswer(2);
    let newAnswers;
    let newReviews;

    const findAnswer = answers.find(
      (a) => moduleSettings.currentQuestion.id === a.questionId
    );
    if (findAnswer) {
      newAnswers = moduleSettings.answers.map((a) => {
        if (a.questionId === findAnswer.questionId) {
          return findAnswer;
        } else {
          return a;
        }
      });

      newReviews = moduleSettings.reviews.map((r) => {
        if (r.answerId === findAnswer.id) {
          return {
            ...r,
            chosen: findAnswer.chosen,
            isCorrect: findAnswer.isCorrect,
            attempt: findAnswer.attempt,
          };
        } else {
          return r;
        }
      });
    }

    const numCorrect = newAnswers.filter((a) => a.isCorrect).length;
    const score = `${numCorrect}/${moduleSettings.selectedModule.totalQuestions}`;

    try {
      await updateSitting({
        variables: {
          module_type: moduleSettings.moduleType,
          sitting_id: moduleSettings.currentSitting.id,
          score,
        },
      });
    } catch (error) {
      console.log(error);
    }

    if (
      !!(
        moduleSettings.currentQuestion.correctAns.trim() ===
        moduleSettings.selectedAnswer.trim()
      )
    ) {
      setModuleSettings({
        ...moduleSettings,
        isAssessmentAnswerCorrect: true,
        isResultShown: true,
        readOnly: true,
        reviews: [...newReviews],
        answers: [...newAnswers],
      });
    } else {
      setModuleSettings({
        ...moduleSettings,
        isAssessmentAnswerCorrect: false,
        isResultShown: true,
        readOnly: true,
        reviews: [...newReviews],
        answers: [...newAnswers],
      });
    }
  };

  const canAssessmentQuestionRedo = () => {
    if (moduleSettings.currentQuestion) {
      const findReview = moduleSettings.reviews.find(
        (r) => r.questionId === moduleSettings.currentQuestion.id
      );
      if (findReview) {
        if (findReview?.attempt === 1 && !findReview?.isCorrect) return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const openDialog = () => {
    if (showTrainingDialog()) return setTrainingDialog(true);
    setModuleSettings({
      ...moduleSettings,
      open: true,
    });
  };

  const trainingTextResult = () => {
    if (
      moduleSettings.selectedModule?.totalQuestions ===
      moduleSettings.selectedModule?.firstGroupQuestions
    ) {
      if (moduleSettings.selectedModule?.assessmentId) {
        return "Well done! You can proceed to the assessment!";
      } else {
        return "Well done! Your training has been completed!";
      }
    }
    if (moduleSettings.currentPart === 1 && partOneAllCorrect) {
      return "Well done! You can proceed to Section 2!";
    } else if (
      moduleSettings.currentPart === 1 &&
      !partOneAllCorrect &&
      moduleSettings?.currentQuestionNumber ===
        moduleSettings?.selectedModule?.firstGroupQuestions
    ) {
      return "You can proceed to Section 1 Part 2!";
    } else if (moduleSettings.currentPart === 2 && sectionOnePass === false) {
      return "You have tried your best, but you will need to reattempt Section 1 Part 2 and score a higher score to proceed further. Practice makes perfect!";
    } else if (moduleSettings.currentPart === 2 && sectionOnePass === true) {
      return "You can proceed to Section 2!";
    } else if (moduleSettings.currentPart === 3 && sectionTwoPass === true) {
      if (moduleSettings.selectedModule?.assessmentId) {
        return "Well done! You can proceed to the assessment!";
      } else {
        return "Well done! Your training has been completed!";
      }
    } else if (moduleSettings.currentPart === 3 && sectionTwoPass === false) {
      return "You have tried your best, but you will need to reattempt Section 2 and score a higher score to proceed further. Practice makes perfect!";
    }
  };

  const trainingResult = () => {
    if (moduleSettings.moduleType === "Interactive") return;
    let newScore;
    let lastQuestionTimeTaken;

    if (moduleSettings.currentPart === 1) {
      if (
        moduleSettings.answers.some(
          (a) => a.questionNumber === moduleSettings.currentQuestionNumber
        )
      ) {
        lastQuestionTimeTaken = 0;
      } else {
        lastQuestionTimeTaken =
          moment(moduleSettings.endTime) - moment(moduleSettings.startTime);
      }

      if (
        moduleSettings.answers.some(
          (a) => a.questionNumber === moduleSettings.currentQuestionNumber
        )
      ) {
        newScore = Math.ceil(
          (moduleSettings.answers.filter((a) => a.isCorrect).length * 100) /
            moduleSettings?.selectedModule?.firstGroupQuestions
        );
      } else {
        newScore = Math.ceil(
          ((moduleSettings.answers.filter((a) => a.isCorrect).length + 1) *
            100) /
            moduleSettings?.selectedModule?.firstGroupQuestions
        );
      }

      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ my: 1.2 }}
        >
          <img
            alt={"showing a star"}
            src={success}
            style={{ height: "100px", marginBottom: "4px" }}
          />
          {moduleSettings.selectedModule?.totalQuestions ===
          moduleSettings.selectedModule?.firstGroupQuestions ? (
            <Typography
              variant="h6"
              gutterBottom
            >{`Your total score for the Training Module: ${
              moduleSettings.isTrainingAnswerCorrect
                ? Math.ceil(newScore)
                : Math.ceil(moduleSettings.totalScorePartOne)
            }
            %`}</Typography>
          ) : (
            <Typography
              variant="h6"
              gutterBottom
            >{`Your total score for Section 1 Part 1: ${
              moduleSettings.isTrainingAnswerCorrect
                ? Math.ceil(newScore)
                : Math.ceil(moduleSettings.totalScorePartOne)
            }
            %`}</Typography>
          )}
          <Typography variant="h6" gutterBottom>{`Time taken: ${Math.ceil(
            (moduleSettings.totalTimeTakenPartOne + lastQuestionTimeTaken) /
              60000
          )} Minutes`}</Typography>
        </Stack>
      );
    }
    if (moduleSettings.currentPart === 2) {
      if (
        moduleSettings.answers.some(
          (a) => a.questionNumber === moduleSettings.currentQuestionNumber
        )
      ) {
        newScore = Math.ceil(
          (moduleSettings.answers
            .slice(moduleSettings.selectedModule.firstGroupQuestions)
            .filter((a) => a.isCorrect).length *
            100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
        );
      } else {
        newScore = Math.ceil(
          ((moduleSettings.answers
            .slice(moduleSettings.selectedModule.firstGroupQuestions)
            .filter((a) => a.isCorrect).length +
            1) *
            100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
        );
      }

      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ my: 1.2 }}
        >
          <img
            alt={"showing a star"}
            src={moduleSettings.sectionOnePass ? success : fail}
            style={{ height: "100px", marginBottom: "4px" }}
          />
          <Typography
            variant="h6"
            gutterBottom
          >{`Your total score for Section 1 Part 2: ${
            moduleSettings.isTrainingAnswerCorrect
              ? Math.ceil(newScore)
              : Math.ceil(moduleSettings.totalScorePartTwo)
          }%`}</Typography>
        </Stack>
      );
    }

    if (moduleSettings.currentPart === 3) {
      if (
        moduleSettings.answers.some(
          (a) => a.questionNumber === moduleSettings.currentQuestionNumber
        )
      ) {
        newScore = Math.ceil(
          (moduleSettings.answers
            .slice(
              partOneAllCorrect
                ? moduleSettings.selectedModule.firstGroupQuestions
                : moduleSettings.selectedModule.totalQuestions
            )
            .filter((a) => a.isCorrect).length *
            100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
        );
      } else {
        newScore = Math.ceil(
          ((moduleSettings.answers
            .slice(
              partOneAllCorrect
                ? moduleSettings.selectedModule.firstGroupQuestions
                : moduleSettings.selectedModule.totalQuestions
            )
            .filter((a) => a.isCorrect).length +
            1) *
            100) /
            (moduleSettings.selectedModule.totalQuestions -
              moduleSettings.selectedModule.firstGroupQuestions)
        );
      }

      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ my: 1.2 }}
        >
          <img
            alt={"showing a star"}
            src={moduleSettings.sectionTwoPass ? success : fail}
            style={{ height: "100px", marginBottom: "4px" }}
          />
          <Typography
            variant="h6"
            gutterBottom
          >{`Your total score for Section 2: ${
            moduleSettings.isTrainingAnswerCorrect
              ? Math.ceil(newScore)
              : Math.ceil(moduleSettings.totalScorePartThree)
          }%`}</Typography>
        </Stack>
      );
    }
  };

  const flagColour = () => {
    return moduleSettings.currentQuestion &&
      moduleSettings.flags.includes(moduleSettings.currentQuestion.id)
      ? "error"
      : "primary";
  };

  const toggleFlag = () => {
    let newFlags = [...moduleSettings.flags];
    let index = moduleSettings.flags.indexOf(moduleSettings.currentQuestion.id);
    if (index >= 0) {
      newFlags.splice(index, 1);
    } else {
      newFlags.push(moduleSettings.currentQuestion.id);
    }
    setModuleSettings({
      ...moduleSettings,
      flags: [...newFlags],
    });
  };

  const toggleReview = () => {
    setModuleSettings({
      ...moduleSettings,
      openReview: !moduleSettings.openReview,
    });
  };

  const handleVideoClick = () => {
    setVideoDialogOpen(true);
  };

  const canGoNext = () => {
    if (moduleSettings.moduleType === "Diagnostic") {
      if (
        moduleSettings.currentQuestion.questionType === "WordExplanation" ||
        moduleSettings.currentQuestion.questionType === "WordExplanationV2" ||
        moduleSettings.selectedAnswer
      )
        return true;
      return false;
    }
    if (moduleSettings.moduleType === "Placement") {
      if (
        moduleSettings.currentQuestionNumber <= moduleSettings.numStart &&
        !moduleSettings.selectedAnswer
      )
        return false;
      return true;
    }
    if (moduleSettings.moduleType === "Training") return true;
    if (!moduleSettings.selectedAnswer) return false;
    return true;
  };

  const exitSittingClick = async () => {
    if (moduleSettings.reviews.length === 0) await mfSaveAnswer();
    exitSitting();
    setEnterPressed(false);
    setExitDialog(false);
    if (userRole !== "Student") {
      // if (userRole === "Teacher") return history.push(`/classmanagementteacher/${studentMonitoringData?.id}`);
      return history.goBack();
    }
    if (
      moduleSettings.moduleType === "Assessment" ||
      moduleSettings.moduleType === "Interactive" ||
      moduleSettings.moduleType === "Training"
    )
      return history.push("/studenttraining");
    history.push("/dashboard");
  };

  const diagnosticFinish = () => {
    let route = moduleSettings.currentSitting.route.split(",");
    if (
      Number(route[route.length - 1]) ===
      moduleSettings.currentQuestion.originalQuestionId
    ) {
      let path = moduleSettings.diagTree.filter(
        (dt) =>
          dt.originalQuestionId === Number(route[route.length - 1]) &&
          dt.parent === moduleSettings.currentQuestion.nodeId
      );
      if (path.length === 0) {
        return true;
      } else {
        let nodes = path.map((p) => p.node);
        let questions = moduleSettings.questions.filter(
          (q) =>
            nodes.includes(q.nodeId) &&
            (q.order === moduleSettings.maxOrder ||
              q.order === moduleSettings.maxOrder + 1 ||
              q.order === null) &&
            q.originalQuestionId === Number(route[route.length - 1])
        );
        if (questions.length === 0) return true;
        for (let i = 0; i < questions.length; i++) {
          let answer = moduleSettings.answers.find(
            (a) => a.diagnosticQuestionId === questions[i].id
          );
          if (!answer) return false;
        }
        return true;
      }
    }
    return false;
  };

  const showPreviousReview = () => {
    if (["Placement", "Assessment"].includes(moduleSettings.moduleType)) {
      if (userRole === "Student") {
        return moduleSettings.moduleType === "Placement"
          ? moduleSettings.incorrectIndex > 0
          : moduleSettings.currentQuestionNumber > 1;
      } else {
        return moduleSettings.currentQuestionNumber > 1;
      }
    } else {
      return moduleSettings.currentQuestionNumber > 1;
    }
  };

  const showNextReview = () => {
    if (["Placement", "Assessment"].includes(moduleSettings.moduleType)) {
      if (userRole === "Student") {
        return moduleSettings.moduleType === "Placement"
          ? moduleSettings.incorrectIndex < moduleSettings.incorrect.length - 1
          : moduleSettings.currentQuestionNumber <
              moduleSettings.selectedModule?.totalQuestions;
      } else {
        return (
          moduleSettings.currentQuestionNumber <
          moduleSettings.selectedModule?.totalQuestions
        );
      }
    } else {
      return (
        moduleSettings.currentQuestionNumber <
        moduleSettings.selectedModule?.totalQuestions
      );
    }
  };

  const renderMenu = () => {
    if (
      moduleSettings.moduleType === "Diagnostic" &&
      moduleSettings.currentQuestion
    ) {
      return (
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Box />
          <Box>
            {!diagnosticFinish() &&
              !goNext &&
              moduleSettings.currentQuestion.attempts > 1 &&
              moduleSettings.attempt <=
                moduleSettings.currentQuestion.attempts && (
                <>
                  <Button
                    onClick={skipAnswer}
                    color="secondary"
                    variant="contained"
                    sx={{ mr: 2, marginRight: "2vw" }}
                    size="small"
                    disabled={loading}
                  >
                    Skip Question
                  </Button>
                  <Button
                    disabled={!canGoNext() || loading}
                    onClick={checkAnswer}
                    color="secondary"
                    variant="contained"
                    sx={{ mr: 2 }}
                    size="small"
                  >
                    Check Answer
                  </Button>
                </>
              )}
            {!diagnosticFinish() &&
              (goNext || moduleSettings.currentQuestion.attempts === 1) && (
                <Button
                  disabled={!canGoNext() || loading}
                  onClick={nextQuestion}
                  color="secondary"
                  variant="contained"
                  sx={{ mr: 2 }}
                  size="small"
                >
                  Next <ChevronRightIcon />
                </Button>
              )}
            {diagnosticFinish() && (
              <Button
                onClick={openDialog}
                color="secondary"
                variant="contained"
                sx={{ mr: 2 }}
                size="small"
                disabled={loading}
              >
                Finish {moduleSettings.moduleType}
              </Button>
            )}
          </Box>
        </Stack>
      );
    } else if (moduleSettings.selectedModule) {
      if (moduleSettings.reviews?.length > 0) {
        if (moduleSettings.openReview) {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Box />
              <Button
                onClick={exitSittingClick}
                color="secondary"
                variant="contained"
                sx={{ mr: 2 }}
                size="small"
                disabled={loading}
              >
                Exit{" "}
                {moduleSettings.moduleType === "Placement"
                  ? "Test"
                  : moduleSettings.moduleType}
              </Button>
            </Stack>
          );
        } else {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              {showPreviousReview() ? (
                <Button
                  onClick={prevQuestion}
                  color="secondary"
                  variant="contained"
                  sx={{ mr: 2 }}
                  size="small"
                  disabled={loading}
                >
                  <ChevronLeft /> Previous
                </Button>
              ) : (
                <Box />
              )}
              {/* {moduleSettings.moduleType === "Assessment" ? moduleSettings.currentQuestionNumber > 1 ? 
                <Button onClick={prevQuestion} style={{ color: "white" }}>
                  <ChevronLeft /> Previous
                </Button> : <Box /> : (moduleSettings.incorrectIndex > 0 && moduleSettings.moduleType !== "Training") ?
                <Button onClick={prevQuestion} style={{ color: "white" }}>
                  <ChevronLeft /> Previous
                </Button> : <Box />} */}
              <Box>
                {moduleSettings.moduleType === "Assessment" && (
                  <Tooltip title="Training Video">
                    <Button
                      onClick={handleVideoClick}
                      color="secondary"
                      variant="contained"
                      sx={{ mr: 2 }}
                      size="small"
                      disabled={loading}
                    >
                      <OndemandVideoIcon />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Show Questions">
                  <Button
                    onClick={toggleReview}
                    color="secondary"
                    variant="contained"
                    sx={{ mr: 2 }}
                    size="small"
                    disabled={loading}
                  >
                    <AppsIcon />
                  </Button>
                </Tooltip>
                {moduleSettings.moduleType === "Assessment" &&
                  canAssessmentQuestionRedo() &&
                  userRole === "Student" && (
                    <Button
                      onClick={checkAssessmentAnswer}
                      color="secondary"
                      variant="contained"
                      size="small"
                      disabled={loading}
                      sx={{ mx: 2 }}
                    >
                      Check Answer
                    </Button>
                  )}
                {showNextReview() ? (
                  <Button
                    onClick={nextQuestion}
                    color="secondary"
                    variant="contained"
                    sx={{ mr: 2 }}
                    size="small"
                    disabled={loading}
                  >
                    Next <ChevronRightIcon />
                  </Button>
                ) : (
                  <Box />
                )}
                {/* {moduleSettings.moduleType === "Assessment"
                  ? moduleSettings.currentQuestionNumber < moduleSettings.selectedModule.totalQuestions 
                  ? <Button onClick={nextQuestion} style={{ color: "white" }}>
                  Next <ChevronRightIcon />
                </Button> : <Box></Box> : (moduleSettings.incorrectIndex < moduleSettings.incorrect.length - 1 && moduleSettings.moduleType !== "Training") ?
                  <Button onClick={nextQuestion} style={{ color: "white" }}>
                    Next <ChevronRightIcon />
                  </Button> : <Box></Box>} */}
              </Box>
            </Stack>
          );
        }
      } else {
        if (moduleSettings.openReview) {
          return <Copyright />;
        } else {
          if (moduleSettings.currentQuestion) {
            if (
              moduleSettings.moduleType !== "Training" &&
              moduleSettings.moduleType !== "Interactive"
            ) {
              return (
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                >
                  {moduleSettings.currentQuestionNumber > 1 ? (
                    <Button
                      onClick={prevQuestion}
                      color="secondary"
                      variant="contained"
                      sx={{ mr: 2 }}
                      size="small"
                      disabled={loading}
                    >
                      <ChevronLeft /> Previous
                    </Button>
                  ) : (
                    <Box />
                  )}
                  <Box>
                    {moduleSettings.moduleType === "Assessment" && (
                      <Tooltip title="Training Video">
                        <Button
                          onClick={handleVideoClick}
                          color="secondary"
                          variant="contained"
                          sx={{ mr: 2 }}
                          size="small"
                          disabled={loading}
                        >
                          <OndemandVideoIcon />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Show Questions">
                      <Button
                        onClick={toggleReview}
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 2 }}
                        size="small"
                        disabled={loading}
                      >
                        <AppsIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Flag Question">
                      <Button
                        onClick={toggleFlag}
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 2 }}
                        size="small"
                        disabled={loading}
                      >
                        <FlagIcon color={flagColour()} />
                      </Button>
                    </Tooltip>
                    {moduleSettings.currentQuestionNumber <
                      moduleSettings.selectedModule.totalQuestions && (
                      <Button
                        disabled={!canGoNext() || loading}
                        onClick={nextQuestion}
                        ref={buttonRef}
                        color="secondary"
                        variant="contained"
                        size="small"
                      >
                        Next <ChevronRightIcon />
                      </Button>
                    )}
                    {moduleSettings.currentQuestionNumber ===
                      moduleSettings.selectedModule.totalQuestions && (
                      <Button
                        onClick={openDialog}
                        ref={buttonRef}
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 2 }}
                        size="small"
                        disabled={loading}
                      >
                        Finish{" "}
                        {moduleSettings.moduleType === "Placement"
                          ? "Test"
                          : moduleSettings.moduleType}
                      </Button>
                    )}
                  </Box>
                </Stack>
              );
            } else {
              if (location.pathname === "/instructions") return <Copyright />;
              return (
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Box>
                    <Button
                      onClick={() => setExitDialog(true)}
                      color="secondary"
                      variant="contained"
                      sx={{ mr: 2 }}
                      size="small"
                      disabled={loading}
                    >
                      Exit Training
                    </Button>
                  </Box>
                  <Box>
                    <Typography sx={{ color: "white" }}>
                      <strong>{moduleSettings.selectedModule.name}</strong>
                    </Typography>
                  </Box>
                  <Box>
                    {
                      moduleSettings.moduleType === "Training" && (
                        <Tooltip title="Training Video">
                          <Button 
                            onClick={handleVideoClick} 
                            color="secondary"
                            variant="contained"
                            sx={{ mr: 7 }}
                            size="small"
                            disabled={loading}
                          >
                            <OndemandVideoIcon />
                          </Button>
                        </Tooltip>
                      )
                    }
                    {(moduleSettings.moduleType === "Training" && moduleSettings.currentQuestionNumber <
                      moduleSettings.selectedModule.totalQuestions +
                        (moduleSettings.selectedModule.totalQuestions -
                          moduleSettings.selectedModule
                            .firstGroupQuestions)) && (
                      <Button
                        ref={buttonRef}
                        disabled={loading}
                        onClick={goNext ? nextQuestion : checkTrainingAnswer}
                        endIcon={goNext ? <ChevronRightIcon /> : ""}
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 7 }}
                        size="small"
                        
                      >
                        {(goNext || moduleSettings.moduleType === "Interactive") ? "Next" : "Check Answer"}
                      </Button>
                    )}
                    {(moduleSettings.moduleType === "Training" && moduleSettings.currentQuestionNumber ===
                      moduleSettings.selectedModule.totalQuestions +
                        (moduleSettings.selectedModule.totalQuestions -
                          moduleSettings.selectedModule
                            .firstGroupQuestions)) && (
                      <Button
                        ref={buttonRef}
                        onClick={goNext ? openDialog : checkTrainingAnswer}
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 7 }}
                        size="small"
                        disabled={loading}
                      >
                        {goNext
                          ? `Finish ${moduleSettings.moduleType}`
                          : "Check Answer"}
                      </Button>
                    )}
                    {moduleSettings.moduleType === "Interactive" && (
                      <Button
                        ref={buttonRef}
                        onClick={
                          moduleSettings.currentQuestionNumber ===
                          moduleSettings.selectedModule.totalQuestions
                            ? () =>
                                setModuleSettings({
                                  ...moduleSettings,
                                  open: true,
                                })
                            : nextQuestion
                        }
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 7 }}
                        size="small"
                        disabled={loading}
                      >
                        {moduleSettings.currentQuestionNumber ===
                        moduleSettings.selectedModule.totalQuestions ? (
                          "Finish Training"
                        ) : (
                          <>
                            {"Next "} <ChevronRightIcon />
                          </>
                        )}
                      </Button>
                    )}
                  </Box>
                </Stack>
              );
            }
          } else {
            return <Copyright />;
          }
        }
      }
    } else {
      return <Copyright />;
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
        // position: "fixed",
        // left: 0,
        // bottom: 0,
        // width: "100%"
      }}
    >
      <Container maxWidth="lg">
        <Dialog open={trainingDialog}>
          <DialogTitle align="center" sx={{ color: "#333" }}>
            TRAINING RESULT
          </DialogTitle>
          <DialogContent>
            {trainingResult()}
            <Typography
              variant="subtitle1"
              sx={{
                textAlign:
                  moduleSettings.currentPart === 1 ||
                  moduleSettings.currentPart === 2
                    ? "center"
                    : "",
                mt: 2,
                fontWeight: 500,
                color: "#555",
              }}
            >
              {trainingTextResult()}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Box />
            <Button
              autoFocus
              onClick={handleTrainingDialogClose}
              color="secondary"
              variant="contained"
              disabled={loading}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {renderMenu()}
      </Container>
      <Dialog
        maxWidth="xl"
        open={videoDialogOpen}
        onClose={() => setVideoDialogOpen(false)}
      >
        <DialogTitle align="center">Training Video</DialogTitle>
        <DialogContent dividers={true}>
          {moduleSettings.moduleType === "Training" &&
            moduleSettings.selectedModule &&
            moduleSettings.selectedModule.Medias[0]?.Media.source &&
            parse(moduleSettings.selectedModule.Medias[0]?.Media.source)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVideoDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        open={exitDialog}
        onClose={() => setExitDialog(false)}
      >
        <DialogTitle align="center">Exit Training</DialogTitle>
        <DialogContent>
          Are you sure you want to exit this training? You can continue
          completing the training later.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setExitDialog(false)}
            color="secondary"
            variant="contained"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={exitSittingClick}
            color="secondary"
            variant="contained"
            disabled={loading}
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
