import {
  Container,
  Paper,
  Typography,
  LinearProgress,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { GET_CLASS_TRAINING_DATA, GET_DASHBOARD } from "../graphql/queries";
import { ModuleContext, StudentMonitoringContext } from "../App";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClassQuestionView from "../components/ClassQuestionView";

const ClassTrainingView = () => {
  const { classId, trainingId } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const [studentData, setStudentData] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const { moduleSettings, setModuleSettings, exitSitting } = useContext(ModuleContext);
  const { setStudentMonitoringData } = useContext(StudentMonitoringContext);
  const [className, setClassName] = useState("");
  const [trainingName, setTrainingName] = useState("");
  const [isActivity, setIsActivity] = useState(false);
  const [isAssessment, setIsAssessment] = useState(false);

  useEffect(() => {
    exitSitting();
    getClassTrainingData({
      variables: {
        class_id: Number(classId),
        training_id: Number(trainingId)
      }
    });
  }, [classId]);

  const [getDashboard] = useLazyQuery(GET_DASHBOARD);

  const [getClassTrainingData, { loading: classLoading }] = useLazyQuery(GET_CLASS_TRAINING_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getClassTrainingData }) => {
      setStudentData(getClassTrainingData.studentData)
      setQuestionData(getClassTrainingData.questionData);
      setClassName(getClassTrainingData.className);
      setTrainingName(getClassTrainingData.trainingName);
      setIsActivity(getClassTrainingData.isActivity);
      setIsAssessment(getClassTrainingData.isAssessment);
    }
  });

  const viewStudentProgress = async (params) => {
    const respond = await getDashboard({
      variables: {
        user_id: params.row.id,
        role: "Student",
      },
    });
    if (!respond) return enqueueSnackbar("Can not get student sitting data", { variant: "warning" })

    if (isAssessment) {
      const findAssessmentModule = respond?.data?.getDashboard?.assessments?.find(i => i.Sittings.find(ts => ts.id === params?.row?.sittingId));
      if (!findAssessmentModule) return enqueueSnackbar("no module found", { variant: "warning" })

      setStudentMonitoringData({ id: params.row.id });
      setModuleSettings({
        ...moduleSettings,
        selectedModule: findAssessmentModule,
        moduleType: "Assessment",
      });

      history.push("/assessment-question");
    } else {
      const findTrainingModule = respond?.data?.getDashboard?.trainings?.find(i => i.TrainingSittings.find(ts => ts.id === params?.row?.sittingId));
      if (!findTrainingModule) return enqueueSnackbar("no module found", { variant: "warning" })

      setStudentMonitoringData({ id: params.row.id });
      setModuleSettings({
        ...moduleSettings,
        selectedModule: findTrainingModule,
        moduleType: "Training",
      });

      history.push("/training-question");
    }
    
  }

  const getStarted = (params) => {
    return !!(params.row.upTo) || params.row.attempts > 0;
  }

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        width: 100,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        hide: isActivity,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            onClick={() => viewStudentProgress(params)}
            disabled={!params.row.sittingId || !params.row.upTo}
          />,
        ],
      },
      {
        field: "login",
        headerName: "Student #",
        width: 140,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Student",
        width: 200,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "order",
        headerName: "Started Training?",
        width: 140,
        headerAlign: "center",
        align: "center",
        type: "boolean",
        valueGetter: getStarted
      },
      {
        field: "attempts",
        headerName: "# Sittings Completed",
        width: 140,
        headerAlign: "center",
        align: "center",
        type: "number"
      },
      {
        field: "numAssigned",
        headerName: "Progress",
        width: 300,
        headerAlign: "center",
        align: "center",
        hide: isActivity,
        renderCell: (params) => {
          const { upTo, totalQuestions } = params.row;
          const progress = upTo ? (upTo / totalQuestions) * 100 : 0;
          return (
            <div style={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={progress} />
              <div style={{ textAlign: "right", marginTop: "4px", fontSize: "0.8rem" }}>
                {`${upTo || 0}/${totalQuestions}`}
              </div>
            </div>
          )
        }
      }
    ],
    [viewStudentProgress]
  );

  return (
    <>
      <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
         <Paper elevation={0} sx={{ p: 3 }}>
           <Box
             sx={{
               display: "flex",
               mb: 3,
               alignItems: "center",
               justifyContent: "space-between",
             }}
           >
             <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
                { className }<br />{ trainingName }
             </Typography>
           </Box>
           <ClassQuestionView questionData={questionData} />
           <DataGridPro
             getRowId={(row) => row.id}
             rows={studentData}
             columns={columns}
             components={{ Toolbar: GridToolbar }}
             rowsPerPageOptions={[10, 25, 50, 100]}
             disableSelectionOnClick
             autoHeight
             loading={classLoading}
             pagination={true}
             pageSize={pageSize}
             onPageSizeChange={handlePageSizeChange}
             pinnedColumns={{ left: ["actions"] }}
           />
         </Paper>
       </Container>
    </>
    // <>
    //   <Dialog open={openInfo}>
    //     <DialogTitle align="center">Interpreting the Statuses</DialogTitle>
    //     <DialogContent>
    //       <Typography variant="h6">Raise Hand</Typography>
    //       <Typography variant="body2">If a student requires assistance with something, they will click the Raise Hand button. This will show a 
    //         <IconButton size="small" color="primary" aria-label="add to shopping cart">
    //           <PanToolRoundedIcon fontSize="inherit" />
    //         </IconButton>
    //       </Typography>
    //       <Typography variant="h6">On Schedule</Typography>
    //       <Typography variant="body2">Shows whether the student is keeping up with their training:<br />
    //         <Box sx={{ height: 15, width: 15, background: "crimson", borderRadius: 2 , display: "inline-flex"}} /> 2 or more training modules are overdue<br />
    //         <Box sx={{ height: 15, width: 15, background: "orange", borderRadius: 2 , display: "inline-flex"}} /> 1 training module is overdue<br />
    //         <Box sx={{ height: 15, width: 15, background: "limegreen", borderRadius: 2 , display: "inline-flex"}} /> No training modules are overdue
    //       </Typography>
    //       <Typography variant="h6">Performance</Typography>
    //       <Typography variant="body2">Shows whether the student is performing well in their training assessments:<br />
    //         <Box sx={{ height: 15, width: 15, background: "crimson", borderRadius: 2 , display: "inline-flex"}} /> 2 or more training assessments have a score under 60%<br />
    //         <Box sx={{ height: 15, width: 15, background: "orange", borderRadius: 2 , display: "inline-flex"}} /> 1 training assessment has a score under 60%<br />
    //         <Box sx={{ height: 15, width: 15, background: "limegreen", borderRadius: 2 , display: "inline-flex"}} /> No training assessments have a score under 60%
    //       </Typography>
    //     </DialogContent>
    //     <DialogActions sx={{ justifyContent: "space-between" }}>
    //       <Box />
    //       <Button autoFocus onClick={() => setOpenInfo(false)}>Close</Button>
    //     </DialogActions>
    //   </Dialog>
    //   <Dialog 
    //     open={trainingDialogOpen} onClose={() => setTrainingDialogOpen(false)}
    //     maxWidth='lg'
    //     fullWidth
    //   >
    //     <DialogTitle align="center">Add Training</DialogTitle>
    //     <DialogContent>
    //       <Box
    //         sx={{
    //           mb: 3,
    //           alignItems: "center",
    //           width: "100%"
    //         }}
    //       >
    //         <Grid container spacing={3}>
    //           <Grid item xs={6}>
    //             <Autocomplete
    //               name="training"
    //               value={training}
    //               options={trainings}
    //               onChange={(e, options) => setTraining(options)}
    //               getOptionLabel={(option) => 
    //                 option.id ? `${option.name}`: ""
    //               }
    //               renderOption={(props, option) => 
    //                 <li {...props} key={option.id}>
    //                   {option.id ? `${option.name}`: ""}
    //                 </li>
    //               }
    //               sx={{ width: "100%", pr: 5 }}
    //               isOptionEqualToValue={(option, value) => option.id === value.id}
    //               renderInput={(params) => (
    //                 <>
    //                   <body1>Select Training</body1>
    //                   <TextField
    //                     {...params}
    //                     placeholder="Select Training"
    //                   />
    //                 </>
    //               )}
    //             />
    //           </Grid>
    //           <Grid item xs={6}>
    //             <Box
    //               sx={{
    //                 display: "flex",
    //                 justifyContent: "space-between",
    //                 width: "100%",
    //                 flexDirection: "column"
    //               }}
    //             >
    //               <body1>Week</body1>
    //               <TextField
    //                 value={order}
    //                 onChange={e => setOrder(Number(e.target.value))}
    //                 sx={{ width: "100%" }}
    //               />
    //             </Box>
    //           </Grid>
    //           {
    //             rows.length > 0 ? (
    //               <Grid item xs={6} sx={{ pr: 5 }}>
    //                 <Box
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "space-between",
    //                     width: "100%",
    //                     flexDirection: "column"
    //                   }}
    //                 >
    //                   <body1>Students (leave empty to assign to entire class)</body1>
    //                   <Select defaultValue="" disabled={!training} sx={{ width: "100%", pr: 5 }} multiple value={selectedStudents} onChange={handleChangeStudent}>
    //                     {rows?.filter(r => !r.Trainings?.find(t => t.id === training?.id)).map((r) => (
    //                       <MenuItem value={r.id} key={r.id}>
    //                         {r.name}
    //                       </MenuItem>
    //                     ))}
    //                   </Select>
    //                 </Box>
    //               </Grid>
    //             ) : <></>
    //           }
    //         </Grid>
    //       </Box>
          
          
    //     </DialogContent>
    //     <DialogActions
    //       sx={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //       }}
    //     >
    //       <Button onClick={() => setTrainingDialogOpen(false)}>Close</Button>
    //       <Button onClick={() => createTraining()}>Add Training</Button>
    //     </DialogActions>
    //   </Dialog>
    //   <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
    //     <Paper elevation={0} sx={{ p: 3 }}>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           mb: 3,
    //           alignItems: "center",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
    //           Class Management
    //         </Typography>
    //         {/* {getAllocatedStudentsByTeacherData && (<>
    //           <IconButton color="primary" onClick={() => setOpenInfo(true)}><InfoIcon /></IconButton>
    //           <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
    //             {`Total Student Number: ${getAllocatedStudentsByTeacherData?.getAllocatedStudentsByTeacher?.length}`}
    //           </Typography>
    //         </>)} */}
    //       </Box>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           mb: 3,
    //           alignItems: "center",
    //           justifyContent: "space-between",
    //           width: "100%"
    //         }}
    //       >
    //         <Autocomplete
    //           name="trainingClass"
    //           value={selectedClass}
    //           options={trainingClasses}
    //           onChange={(e, options) => setSelectedClass(options)}
    //           getOptionLabel={(option) => 
    //             option.id ? `${option.name}`: ""
    //           }
    //           renderOption={(props, option) => 
    //             <li {...props} key={option.id}>
    //               {option.id ? `${option.name}`: ""}
    //             </li>
    //           }
    //           sx={{ width: "100%" }}
    //           isOptionEqualToValue={(option, value) => option.id === value.id}
    //           renderInput={(params) => (
    //             <TextField
    //               {...params}
    //               size="small"
    //               label="Select Class"
    //               placeholder="Select Class"
    //             />
    //           )}
    //         />
    //       </Box>
    //       { selectedClass && (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               mb: 3,
    //               alignItems: "center",
    //               justifyContent: "space-between",
    //             }}
    //           >
    //             <Box
    //               sx={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "space-between",
    //               }}
    //             >
    //               <Box
    //                 sx={{
    //                   mr: 3
    //                 }}
    //               >
    //                 <Typography variant="body2" color="primary">Current Week: </Typography>
    //                 <TextField
    //                   value={week}
    //                   size="small"
    //                   onChange={e => setWeek(Number(e.target.value))}
    //                   sx={{ width: "100%" }}
    //                 />
    //               </Box>
    //               <Button sx={{ mt: 2 }} variant="contained" onClick={() => setCurrentWeek({ variables: { class_id: selectedClass.id, week } })}>Set Current Week</Button>
    //             </Box>
    //             <Button sx={{ mt: 2 }} variant="contained" onClick={() => setTrainingDialogOpen(true)}>Assign Training to Class</Button>
    //           </Box>
    //       )}
    //       <DataGridPro
    //         getRowId={(row) => row.id}
    //         rows={trainingRows}
    //         columns={columns}
    //         components={{ Toolbar: GridToolbar }}
    //         rowsPerPageOptions={[10, 25, 50, 100]}
    //         disableSelectionOnClick
    //         autoHeight
    //         loading={getAllocatedStudentsByTeacherLoading || toggleRaiseHandLoading}
    //         pagination={true}
    //         pageSize={pageSize}
    //         onPageSizeChange={handlePageSizeChange}
    //         pinnedColumns={{ left: ["actions"] }}
    //       />
    //       {/* <DataGridPro
    //         getRowId={(row) => row.id}
    //         rows={rows}
    //         columns={columns}
    //         components={{ Toolbar: GridToolbar }}
    //         rowsPerPageOptions={[10, 25, 50, 100]}
    //         disableSelectionOnClick
    //         autoHeight
    //         loading={getAllocatedStudentsByTeacherLoading || toggleRaiseHandLoading}
    //         pagination={true}
    //         pageSize={pageSize}
    //         onPageSizeChange={handlePageSizeChange}
    //         pinnedColumns={{ left: ["actions"] }}
    //       /> */}
    //     </Paper>
    //   </Container>
    // </>
  );
};

export default ClassTrainingView;