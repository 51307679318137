import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { GET_REGISTERED_STUDENTS } from "../graphql/queries";
import SelectStudent from "./SelectStudent";

const Registrations = ({ moduleType, moduleId }) => {
  const { preferences, setPreferences, defaults } =
    useContext(PreferenceContext);
  const { userRole } = useContext(AuthenticatedContext);
  let regPref = { ...preferences.registrations };
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(regPref.pageSize || 10);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [dialog, setDialog] = useState(false);

  const [getRegisteredStudents, { studentsLoading }] = useLazyQuery(
    GET_REGISTERED_STUDENTS,
    {
      onCompleted: (students) => {
        if (students?.getRegisteredStudents.students.length >= 0) {
          setRows(students.getRegisteredStudents.students);
        }
        setRowCount(students.getRegisteredStudents.total);
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    if (defaults)
      getRegisteredStudents({
        variables: {
          module_id: moduleId,
          module_type: moduleType,
          skip: page * pageSize,
          take: pageSize,
          filters: regPref?.filters || defaults.registrations.filters,
          sort: regPref?.sort || defaults.registrations.sort,
        },
      });
  }, [
    defaults,
    moduleId,
    moduleType,
    page,
    pageSize,
    regPref?.filters,
    regPref?.sort,
  ]);

  const handlePageSizeChange = (newPageSize) => {
    regPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      registrations: {
        ...regPref,
      },
    });
    if (page === 0) {
      getRegisteredStudents({
        variables: {
          module_id: moduleId,
          module_type: moduleType,
          skip: page * newPageSize,
          take: newPageSize,
          filters: regPref.filters,
          sort: regPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    regPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      registrations: {
        ...regPref,
      },
    });
  };

  const handleColumnOrderChange = (params) => {
    regPref.columns.splice(params.oldIndex, 1);
    regPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      registrations: {
        ...regPref,
      },
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    regPref.visible = { ...model };
    setPreferences({
      ...preferences,
      registrations: {
        ...regPref,
      },
    });
  };

  const handleSortModelChange = (model) => {
    regPref.sort = [...model];

    setPreferences({
      ...preferences,
      registrations: {
        ...regPref,
      },
    });
    if (page === 0) {
      getRegisteredStudents({
        variables: {
          module_id: moduleId,
          module_type: moduleType,
          skip: page * pageSize,
          take: pageSize,
          filters: regPref.filters,
          sort: [...model],
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    regPref.filters = { ...model };

    setPreferences({
      ...preferences,
      registrations: {
        ...regPref,
      },
    });
    if (page === 0) {
      getRegisteredStudents({
        variables: {
          module_id: moduleId,
          module_type: moduleType,
          skip: page * pageSize,
          take: pageSize,
          filters: { ...model },
          sort: regPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const viewStudent = useCallback(
    (params) => () =>
      history.push(`/studentmanagement/${params.row.id}`, { data: params.row }),
    [history]
  );

  function getFullName(params) {
    return `${params.row.givenname || ""} ${params.row.surname || ""}`;
  }

  function getGrade(params) {
    return params.row.gradeId ? "Year " + params.row.gradeId : "";
  }

  function getHasPaid(params) {
    return !!params.row.registrationId;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: regPref?.widths?.actions || 0,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            onClick={viewStudent(params)}
          />,
        ],
      },
      {
        field: "id",
        headerName: "Student ID",
        width: regPref?.widths?.id || 0,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.id,
        getApplyQuickFilterFn: undefined,
        type: "number",
      },
      {
        field: "login",
        headerName: "Student Number",
        width: regPref?.widths?.login || 0,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.login,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "name",
        headerName: "Name",
        width: regPref?.widths?.name || 0,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.name,
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getFullName,
      },
      {
        field: "campus",
        headerName: "Campus",
        width: regPref?.widths?.campus,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.campus,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "gradeId",
        headerName: "Grade",
        width: regPref?.widths?.gradeId || 0,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.gradeId,
        getApplyQuickFilterFn: undefined,
        valueGetter: getGrade,
      },
      {
        field: "hasPaid",
        headerName: "Has Paid?",
        width: regPref?.widths?.hasPaid || 0,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.hasPaid || moduleType !== "Exercise",
        getApplyQuickFilterFn: undefined,
        valueGetter: getHasPaid,
        type: "boolean",
      },
      {
        field: "isCompleted",
        headerName: "Is Completed",
        width: regPref?.widths?.isCompleted || 0,
        headerAlign: "center",
        align: "center",
        hide: !regPref?.visible?.isCompleted,
        getApplyQuickFilterFn: undefined,
        type: "boolean",
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: regPref?.widths?.createdAt || 0,
        headerAlign: "center",
        hide: !regPref?.visible?.createdAt,
        type: "dateTime",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      },
    ],
    [
      moduleType,
      regPref?.visible?.campus,
      regPref?.visible?.createdAt,
      regPref?.visible?.gradeId,
      regPref?.visible?.hasPaid,
      regPref?.visible?.id,
      regPref?.visible?.isCompleted,
      regPref?.visible?.login,
      regPref?.visible?.name,
      regPref?.widths?.actions,
      regPref?.widths?.campus,
      regPref?.widths?.createdAt,
      regPref?.widths?.gradeId,
      regPref?.widths?.hasPaid,
      regPref?.widths?.id,
      regPref?.widths?.isCompleted,
      regPref?.widths?.login,
      regPref?.widths?.name,
      viewStudent,
    ]
  );
  columns.sort(
    (a, b) =>
      regPref.columns?.indexOf(a.field) - regPref.columns?.indexOf(b.field)
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={dialog} fullScreen>
        <DialogTitle>Register Students</DialogTitle>
        <DialogContent>
          <SelectStudent test_id={moduleId} />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => setDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Paper elevation={0} sx={{ p: 3, minHeight: "100%" }}>
        {(moduleType === "Placement" || moduleType === "Progression") && (
          <Box
            sx={{
              display: "flex",
              mb: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box />
            {["Admin", "Education Consultant"].includes(userRole) && (
              <Button
                color="primary"
                variant="contained"
                sx={{ mb: 0 }}
                onClick={() => setDialog(true)}
              >
                Register Student(s)
              </Button>
            )}
          </Box>
        )}
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={regPref.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          loading={studentsLoading}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          pagination
          pinnedColumns={{ left: ["actions"] }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterModel={regPref.filters}
          sortModel={regPref.sort}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          rowCount={rowCount}
          density="compact"
        />
      </Paper>
    </Container>
  );
};

export default Registrations;
