import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import logo from "../assets/NSCC_logo.png";
import altlogo from "../assets/SolverEDGE.svg";
import {
  Box,
  IconButton,
  Toolbar,
  Typography,
  List,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import UserDialog from "./UserDialog";
import MenuItems from "./MenuItems";
import { AuthenticatedContext, ModuleContext, TimerContext } from "../App";
import Countdown, { zeroPad } from "react-countdown";
import { useHistory, useLocation } from "react-router-dom";
import MyStopwatch from "./MyStopwatch";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_STAFF_FEEDBACK,
  CREATE_STUDENT_FEEDBACK,
} from "../graphql/mutations";
import { useSnackbar } from "notistack";
import { GET_SURVEY_QUESTIONS } from "../graphql/queries";

const drawerWidth = 290;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  minHeight: "94.6vh",
  float: "left",
  // borderRight: "1px solid lightgray",
  "& .MuiDrawer-paper": {
    position: "relative",
    border: "none",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Layout = ({ children }) => {
  const { moduleSettings, finishSitting, exitSitting, setModuleSettings } =
    React.useContext(ModuleContext);
  const { timeLeft, timeUp, setTimeUp } = React.useContext(TimerContext);
  const { user, userRole } = React.useContext(AuthenticatedContext);
  const history = useHistory();
  const location = useLocation();
  const [timer, setTimer] = useState(false);
  const [open, setOpen] = useState(false);
  const [diagOpen, setDiagOpen] = useState(false);
  const [bugOpen, setBugOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  // const [timeLeft, setTimeLeft] = useState(0);
  // const timeSettings = {
  //   timeLeft,
  //   setTimeLeft,
  //   timeUp,
  //   setTimeUp,
  // };

  const types = [
    "Bug Report",
    "Issue with Question",
    "Improvements and Feedback",
  ];
  const [feedback, setFeedback] = useState("");
  const [subject, setSubject] = useState("");
  const [surveyOpen, setSurveyOpen] = useState(false);
  // const [timeUp, setTimeUp] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [ratings, setRatings] = useState([]);

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const changeRatings = (index) => (event) => {
    let newRatings = [...ratings];
    newRatings[index] = event.target.value;
    setRatings(newRatings);
  };

  const { enqueueSnackbar } = useSnackbar();
  const toggleDrawer = () => setOpen(!open);
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setTimeUp(true);
      return <span>0:00</span>;
    } else {
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const [getSurveyQuestions] = useLazyQuery(GET_SURVEY_QUESTIONS, {
    onCompleted: ({ getSurveyQuestions }) => {
      let initRatings = [];
      for (let i = 0; i < getSurveyQuestions.length; i++) {
        initRatings.push("");
      }
      setRatings(initRatings);
      setQuestions(getSurveyQuestions);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [createStaffFeedback] = useMutation(CREATE_STAFF_FEEDBACK, {
    onCompleted: ({ createStaffFeedback }) => {
      if (createStaffFeedback) {
        enqueueSnackbar(
          `Thank you for submitting your feedback! It will help us improve the platform for everyone.`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          `There was a problem with sending the feedback, please try again.`,
          {
            variant: "warning",
          }
        );
      }
      setBugOpen(false);
    },
    onError: (error) => {
      console.log(`create sitting error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const [createStudentFeedback] = useMutation(CREATE_STUDENT_FEEDBACK, {
    onCompleted: ({ createStudentFeedback }) => {
      if (surveyOpen) {
        if (createStudentFeedback) {
          enqueueSnackbar(
            `Thank you for submitting your feedback! It will help us improve the module for other students.`,
            {
              variant: "success",
            }
          );
        }
        cancelSurvey();
      } else {
        if (createStudentFeedback) {
          enqueueSnackbar(
            `Thank you for submitting your feedback! It will help us to improve the platform for other students.`,
            {
              variant: "success",
            }
          );
        } else {
          enqueueSnackbar(
            `There was a problem with sending the feedback, please try again.`,
            {
              variant: "warning",
            }
          );
        }
        setBugOpen(false);
      }
    },
    onError: (error) => {
      console.log(`create sitting error: ${error}`);
    },
    fetchPolicy: "network-only",
  });

  const cancelSurvey = () => {
    const typeCopy = moduleSettings.moduleType;
    exitSitting();
    if (
      typeCopy === "Training" ||
      typeCopy === "Assessment" ||
      typeCopy === "Interactive"
    ) {
      setSurveyOpen(false);
      return history.push("/studenttraining");
    }
    setSurveyOpen(false);
    history.push("/dashboard");
  };

  const submitSurvey = () => {
    const studentFeedback = questions.map((q, i) => {
      const surveyResponse = ratings[i];
      return {
        type: "Module Survey",
        rating: 0,
        moduleId: moduleSettings.selectedModule?.id || 0,
        moduleType: moduleSettings.moduleType || "",
        subject: "",
        feedback: surveyResponse,
        studentId: JSON.parse(user).student_id,
        surveyQuestionId: q.id,
      };
    });
    createStudentFeedback({
      variables: {
        responses: studentFeedback,
      },
    });
  };

  const submitSitting = async () => {
    await finishSitting();
    setModuleSettings({
      ...moduleSettings,
      countdown: false,
    });
    getSurveyQuestions({
      variables: {
        usage: "Post Module Survey",
      },
    });
    setSurveyOpen(true);
    // if (typeCopy === "Training" || typeCopy === "Assessment" || typeCopy === "Interactive") {
    //   exitSitting();
    //   return history.push("/studenttraining");
    // }
    // if (moduleCopy.isDiagnostic && typeCopy === "Placement") {
    //   // setDiagOpen(true);
    //   exitSitting();
    //   history.push("/dashboard");
    // } else {
    //   exitSitting();
    //   history.push("/dashboard");
    // }
  };

  useEffect(() => {
    setTimer(moduleSettings.countdown);
  }, [moduleSettings.countdown]);

  // useEffect(() => {
  //   setTimeLeft(moduleSettings.selectedModule?.duration * 60 * 1000);
  // }, [moduleSettings.selectedModule?.duration]);

  useEffect(() => {
    if (moduleSettings.moduleType) setOpen(false);
  }, [moduleSettings.moduleType]);

  const handleSelectChange = ({ target }) => {
    setSelectedType(target.value);
  };

  const handleSubjectChange = ({ target }) => {
    setSubject(target.value);
  };

  const handleFeedbackChange = ({ target }) => {
    setFeedback(target.value);
  };

  const submitFeedback = () => {
    if (userRole === "Student") {
      createStudentFeedback({
        variables: {
          responses: [
            {
              type: selectedType,
              rating: 0,
              moduleId: moduleSettings.selectedModule?.id || 0,
              moduleType: moduleSettings.moduleType || "",
              subject,
              feedback,
              studentId: JSON.parse(user).student_id,
            },
          ],
        },
      });
    } else {
      createStaffFeedback({
        variables: {
          type: selectedType,
          subject,
          feedback,
          staffId: JSON.parse(user).staff_id,
        },
      });
    }
  };
  return (
    <>
      <Dialog open={surveyOpen}>
        <DialogTitle>Module Feedback</DialogTitle>
        <DialogContent>
          Fantastic work{" "}
          {toTitleCase(user ? JSON.parse(user)?.student_givenname || "" : "")}!
          <br />
          You are a champion for finishing this module! We would love to know
          how you felt about the module.
          <br />
          {questions.map((q, i) => {
            return q.responseType === "Radio" ? (
              <FormControl sx={{ mx: 2, mt: 2 }}>
                <FormLabel>{q.content}</FormLabel>
                <RadioGroup
                  name="radio-buttons-group"
                  key={i}
                  row
                  value={ratings[i]}
                  onChange={changeRatings(i)}
                >
                  {q.responseOptions.split("[split]").map((r) => {
                    return (
                      <FormControlLabel
                        value={r}
                        control={<Radio />}
                        label={r}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            ) : null;
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelSurvey}>Cancel</Button>
          <Button onClick={submitSurvey}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bugOpen}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            submitFeedback();
          },
        }}
      >
        <DialogTitle>User Feedback</DialogTitle>
        <DialogContent>
          If you have any feedback, suggestions, or have found an issue, please
          report it here:
        </DialogContent>
        <FormControl sx={{ mx: 2 }} required>
          <InputLabel>Type</InputLabel>
          <Select
            label="Type"
            onChange={handleSelectChange}
            value={selectedType}
            defaultValue=""
            required
          >
            {types?.map((type, index) => (
              <MenuItem value={type} key={index}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          onChange={handleSubjectChange}
          value={subject}
          sx={{ mx: 2, mt: 2 }}
          label={
            selectedType === "Issue with Question" ? "Question ID" : "Subject"
          }
          required
          type={selectedType === "Issue with Question" ? "number" : "text"}
        />
        <TextField
          label="Feedback"
          multiline
          maxRows={4}
          onChange={handleFeedbackChange}
          value={feedback}
          sx={{ mx: 2, mt: 2 }}
          required
        />
        <DialogActions>
          <Button onClick={() => setBugOpen(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={timeUp}>
        <DialogTitle>Out of Time</DialogTitle>
        <DialogContent>
          You have run out of time. Please submit the test.
        </DialogContent>
        <DialogActions>
          <Button onClick={submitSitting}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={diagOpen}>
        <DialogContent>
          <Typography variant="body1" component="div">
            <strong>
              Additional Instruction for Problem Solving Behaviour test:
            </strong>
          </Typography>
          <br />
          <Typography variant="body1" component="div">
            Well done on completing the first part of the Problem Solving
            Behaviour & Ability test. Click 'Start Diagnostic Test' to start the
            second part or 'Return to Dashboard' to exit and come back later.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              exitSitting();
              setDiagOpen(false);
              history.push("/dashboard");
            }}
          >
            Return to Dashboard
          </Button>
          <Button
            onClick={() => {
              setModuleSettings({
                ...moduleSettings,
                moduleType: "Diagnostic",
                currentSitting: null,
                currentQuestion: null,
                answers: [],
                flags: [],
                reviews: [],
                questions: [],
                reviewQuestions: [],
                marks: null,
                currentQuestionNumber: null,
                zetas: null,
                route: [],
                abilities: null,
                totalQuestionNumber: null,
                questionsPicked: null,
                strands: [],
                passages: [],
                isPassage: false,
                passageLeft: 0,
                isComprehension: false,
                numStart: 0,
                loadedPassages: {},
                selectedAnswer: "",
                open: false,
                openReview: false,
                initialAnswer: "",
                startTime: null,
                endTime: null,
                totalLogoutTime: null,
                countdown: true,
                incorrect: [],
                incorrectIndex: 0,
                diagTree: [],
                attempt: null,
                maxOrder: 1,
                isTrainingAnswerCorrect: false,
                isAssessmentAnswerCorrect: false,
                isResultShown: false,
                readOnly: false,
                currentPart: 1,
                lowest: [],
                limit: {},
              });

              setDiagOpen(false);
              history.push("/instructions");
            }}
          >
            Start Diagnostic Test
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ flexGrow: 1, height: "auto" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: "24px" }}>
            {!moduleSettings.moduleType && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
                sx={{ mr: "36px", ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            )}
            {user &&
            ((JSON.parse(user).student_region &&
              JSON.parse(user).student_region === 8) ||
              (JSON.parse(user).region_id &&
                JSON.parse(user).region_id === 8)) ? (
              <img
                src={altlogo}
                alt="SolverEdge Logo"
                style={{
                  marginRight: "32px",
                  height: "60px",
                  maxWidth: "100px",
                }}
              />
            ) : (
              <img
                src={logo}
                alt="NSCC Logo"
                style={{
                  marginRight: "32px",
                  height: "60px",
                  maxWidth: "100px",
                }}
              />
            )}

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {moduleSettings.currentQuestion?.id &&
              (moduleSettings.moduleType === "Placement" ||
                moduleSettings.moduleType === "Test" ||
                moduleSettings.moduleType === "Assessment")
                ? `Question ${moduleSettings.currentQuestionNumber} of ${moduleSettings.selectedModule.totalQuestions}`
                : ""}
              {!moduleSettings.currentQuestion &&
                "SolverEdge Problem Solving Platform"}
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {moduleSettings.currentQuestion?.id
                ? `Question ID: ${moduleSettings.currentQuestion?.originalQuestionId}`
                : ""}
            </Typography>
            {timer &&
              moduleSettings.moduleType !== "Training" &&
              moduleSettings.moduleType !== "Interactive" && (
                <Box
                  sx={{
                    backgroundColor: "black",
                    mr: 8,
                    padding: 1,
                    pl: 2,
                    pr: 2,
                    width: "10vw",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h4" sx={{ color: "white" }}>
                    <Countdown
                      date={timeLeft}
                      renderer={renderer}
                      onComplete={() => {
                        if (
                          timer &&
                          moduleSettings?.moduleType !== "Training" &&
                          moduleSettings?.moduleType !== "Interactive"
                        )
                          setTimeUp(true);
                      }}
                    />
                  </Typography>
                </Box>
              )}
            {timer &&
              (moduleSettings.moduleType === "Training" ||
                moduleSettings.moduleType === "Interactive") &&
              location.pathname !== "/instructions" && (
                <MyStopwatch timer={timer} />
              )}
            <>
              <IconButton
                sx={{ color: "white" }}
                onClick={() => setBugOpen(true)}
              >
                <BugReportIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <UserDialog />
            </>
          </Toolbar>
        </AppBar>
        {!moduleSettings.moduleType && (
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List sx={{ border: "none" }}>
              <MenuItems />
            </List>
          </Drawer>
        )}
        <Box
          component="main"
          sx={{
            backgroundImage:
              "linear-gradient(to right, #a8efeb, #5eb7df, #7ebadf)",
            flexGrow: 1,
            height: "100%",
            position: "relative",
            maxHeight: "100vh",
            overflow: "auto",            
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
