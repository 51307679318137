import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthenticatedContext } from "../App";
import * as yup from "yup";
import { GET_STUDENT_DETAILS } from "../graphql/queries";
import { UPDATE_STUDENT } from "../graphql/mutations";

const StudentUpdateDetails = () => {
  const value = React.useContext(AuthenticatedContext);
  const schema = yup
    .object({
      givenname: yup.string().max(45).required("Required"),
      surname: yup.string().max(45).required("Required"),
      email: yup.string().max(45),
      phone: yup.string().max(45),
    })
    .required();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      givenname: "",
      surname: "",
      email: "",
      phone: "",
    },
  });

  const location = useLocation();
  const { id } = useParams();

  const [getStudentDetails] = useLazyQuery(GET_STUDENT_DETAILS, {
    onCompleted: (returnStudentDetails) => {
      setValue("givenname", returnStudentDetails.getStudentDetails.givenname);
      setValue("surname", returnStudentDetails.getStudentDetails.surname);
      setValue("email", returnStudentDetails.getStudentDetails.email);
      setValue("phone", returnStudentDetails.getStudentDetails.phone);
    },
    fetchPolicy: "no-cache",
  });

  const [updateStudent] = useMutation(UPDATE_STUDENT, {
    onError: (error) => {
      console.log(error);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (location.pathname === `/updatedetails`) {
      getStudentDetails({
        variables: { id: Number(JSON.parse(value.user).student_id) },
      });
    } else if (location.pathname === `/studentmanagement/${id}`) {
      getStudentDetails({ variables: { id: Number(id) } });
    }
  }, [id, location.pathname, value.user]);

  const onSubmit = ({ surname, givenname, email, phone }) => {
    updateStudent({
      variables: {
        ...(location.pathname === `/updatedetails`
          ? { id: Number(JSON.parse(value.user).student_id) }
          : { id: Number(id) }),
        surname,
        givenname,
        email,
        phone,
      },
    });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="givenname"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.givenname ? true : false}
              >
                <InputLabel>Given Name</InputLabel>
                <OutlinedInput
                  inputProps={{ readOnly: true }}
                  {...field}
                  label="Given Name"
                />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.givenname?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="surname"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={errors.surname ? true : false}
              >
                <InputLabel>Surname</InputLabel>
                <OutlinedInput
                  inputProps={{ readOnly: true }}
                  {...field}
                  label="Surname"
                />
                <FormHelperText sx={{ color: "primary.main" }}>
                  {errors.surname?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        { value?.userRole !== "Teacher" ? 
        (
          <>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    error={errors.email ? true : false}
                  >
                    <InputLabel>Email</InputLabel>
                    <OutlinedInput
                      inputProps={{ readOnly: true }}
                      {...field}
                      label="Email"
                    />
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.email?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    error={errors.phone ? true : false}
                  >
                    <InputLabel>Phone</InputLabel>
                    <OutlinedInput
                      inputProps={{ readOnly: true }}
                      {...field}
                      label="Phone"
                    />
                    <FormHelperText sx={{ color: "primary.main" }}>
                      {errors.phone?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={6}></Grid>
          </>
        ) : <></>}
      </Grid>
    </Box>
  );
};

export default StudentUpdateDetails;
