import React, { useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { DataGridPro } from "@mui/x-data-grid-pro";

const ResultTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        field: "questionNumber",
        headerName: "Question Number",
        flex: 1,
        hide: false,
        filterable: false,
        sortable: false,
      },
      {
        field: "result",
        headerName: "Result",
        flex: 1,
        hide: false,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          if (params?.row.result) {
            return <CheckIcon />;
          } else {
            return <CloseIcon />;
          }
        },
      },
      {
        field: "topic",
        headerName: "Topic",
        flex: 4,
        hide: false,
        filterable: false,
        sortable: false,
      },
    ],
    []
  );

  return (
    <DataGridPro
      rows={data}
      columns={columns}
      pageSize={20}
      disableSelectionOnClick
      autoHeight
      hideFooter
    />
  );
};

export default ResultTable;
