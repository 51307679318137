import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GET_MY_STAFF_DETAILS } from "../graphql/queries";

const StaffUpdateDetails = () => {
  const schema = yup
    .object({
      staffGivenname: yup.string().max(45).required("Required"),
      staffSurname: yup.string().max(45).required("Required"),
    })
    .required();
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      staffGivenname: "",
      staffSurname: "",
    },
  });

  const [setStaffDetails] = useState({});

  const [getMyStaffDetails] = useLazyQuery(GET_MY_STAFF_DETAILS, {
    onCompleted: (returnStaffDetails) => {
      setStaffDetails(returnStaffDetails.getMyStaffDetails);
      setValue(
        "staffGivenname",
        returnStaffDetails.getMyStaffDetails.staffGivenname
      );
      setValue(
        "staffSurname",
        returnStaffDetails.getMyStaffDetails.staffSurname
      );
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    getMyStaffDetails();
  }, []);

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name="staffGivenname"
          control={control}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={errors.staffGivenname ? true : false}
            >
              <InputLabel>Staff Given Name</InputLabel>
              <OutlinedInput {...field} label="Staff Given Name" />
              <FormHelperText sx={{ color: "primary.main" }}>
                {errors.staffGivenname?.message}
              </FormHelperText>
            </FormControl>
          )}
        ></Controller>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="staffSurname"
          control={control}
          render={({ field }) => (
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={errors.staffSurname ? true : false}
            >
              <InputLabel>Staff Surname</InputLabel>
              <OutlinedInput {...field} label="Staff Surname" />
              <FormHelperText sx={{ color: "primary.main" }}>
                {errors.staffSurname?.message}
              </FormHelperText>
            </FormControl>
          )}
        ></Controller>
      </Grid>
    </>
  );
};

export default StaffUpdateDetails;
