import React, { useState } from "react";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import {
  Alert,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Split } from "@geoffcox/react-splitter";
import parse from "html-react-parser";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const MultipleInlineSelectType = ({
  currentQuestion: {
    Question,
    Answer1,
    Answer2,
    Answer3,
    Answer4,
    Answer5,
    Answer6,
    Answer7,
    Answer8,
    Answer9,
    Answer10,
    CorrectAnswer,
    Hint,
    VideoSrc,
    PassageSrc,
    Solution,
  },
}) => {
  const splitterColors = {
    color: "#e0e0e0",
    hover: "#bdbdbd",
  };

  const questionSplit = () => Question.split("[split]");

  const [chosenAnswers, setChosenAnswers] = useState({
    Answer1: "",
    Answer2: "",
    Answer3: "",
    Answer4: "",
    Answer5: "",
    Answer6: "",
    Answer7: "",
    Answer8: "",
    Answer9: "",
    Answer10: "",
  });
  const handleSelectChange = ({ target }) => {
    setChosenAnswers({ ...chosenAnswers, [target.name]: target.value });
  };

  //Get answers value then compares to CorrectAnswer
  // console.log(
  //   Object.values(chosenAnswers).filter(Boolean).toString()===CorrectAnswer
  // );

  return (
    <Split
      minPrimarySize="15%"
      minSecondarySize="15%"
      initialPrimarySize="50%"
      resetOnDoubleClick
      splitterSize="48px"
      defaultSplitterColors={splitterColors}
    >
      <div>
        {VideoSrc && (
          <>
            <ReactPlayer
              url={VideoSrc}
              controls={true}
              width="95%"
              height="95%"
            />
            <ReactAudioPlayer src={VideoSrc} controls />
          </>
        )}
        {PassageSrc && (
          <Typography variant="h6">{parse(PassageSrc)}</Typography>
        )}
        {!PassageSrc && !VideoSrc && Question && (
          <Typography variant="h6">{parse(questionSplit()[0])}</Typography>
        )}
      </div>
      <>
        {(PassageSrc || VideoSrc) && Question && (
          <Typography variant="h6">{parse(questionSplit()[0])}</Typography>
        )}
        <Stack direction="row" spacing={2}>
          <Typography variant="h6">
            {parse(questionSplit().length > 1 && questionSplit()[1])}
          </Typography>
          {Answer1 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer1"
                value={chosenAnswers.Answer1}
                onChange={handleSelectChange}
              >
                {Answer1.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Typography variant="h6">
            {questionSplit().length > 2 && parse(questionSplit()[2])}
          </Typography>
          {Answer2 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer2"
                value={chosenAnswers.Answer2}
                onChange={handleSelectChange}
              >
                {Answer2.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 3 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 3 && parse(questionSplit()[3])}
          </Typography>
          {Answer3 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer3"
                value={chosenAnswers.Answer3}
                onChange={handleSelectChange}
              >
                {Answer3.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 4 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 4 && parse(questionSplit()[4])}
          </Typography>
          {Answer4 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer4"
                value={chosenAnswers.Answer4}
                onChange={handleSelectChange}
              >
                {Answer4.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 5 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 5 && parse(questionSplit()[5])}
          </Typography>
          {Answer5 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer5"
                value={chosenAnswers.Answer5}
                onChange={handleSelectChange}
              >
                {Answer5.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 6 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 6 && parse(questionSplit()[6])}
          </Typography>
          {Answer6 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer6"
                value={chosenAnswers.Answer6}
                onChange={handleSelectChange}
              >
                {Answer6.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 7 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 7 && parse(questionSplit()[7])}
          </Typography>
          {Answer7 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer7"
                value={chosenAnswers.Answer7}
                onChange={handleSelectChange}
              >
                {Answer7.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 8 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 8 && parse(questionSplit()[8])}
          </Typography>
          {Answer8 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer8"
                value={chosenAnswers.Answer8}
                onChange={handleSelectChange}
              >
                {Answer8.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 9 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 9 && parse(questionSplit()[9])}
          </Typography>
          {Answer9 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer9"
                value={chosenAnswers.Answer9}
                onChange={handleSelectChange}
              >
                {Answer9.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: questionSplit().length > 10 ? 2 : 0 }}
        >
          <Typography variant="h6">
            {questionSplit().length > 10 && parse(questionSplit()[10])}
          </Typography>
          {Answer10 && (
            <FormControl sx={{ flexGrow: 1 }}>
              <Select
                name="Answer10"
                value={chosenAnswers.Answer10}
                onChange={handleSelectChange}
              >
                {Answer10.split(",").map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
        {CorrectAnswer && (
          <Alert severity="success" sx={{ mt: 3 }}>
            <div style={{ marginBottom: "8px" }}>
              <strong>Correct answer: {parse(CorrectAnswer)}</strong>
            </div>
          </Alert>
        )}
        {Hint && (
          <Alert
            severity="info"
            iconMapping={{
              info: <WbIncandescentIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Hint: <br></br>
            </strong>
            {parse(Hint)}
          </Alert>
        )}
        {Solution && (
          <Alert
            severity="warning"
            iconMapping={{
              warning: <RadioButtonCheckedIcon fontSize="inherit" />,
            }}
            sx={{ mt: 2 }}
          >
            <strong>
              Solution: <br></br>
            </strong>
            {parse(Solution)}
          </Alert>
        )}
      </>
    </Split>
  );
};

export default MultipleInlineSelectType;
