import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  GET_FORMATTED_PASSAGE,
  GET_FORMATTED_QUESTION,
} from "../graphql/queries";
import { Box, Button, Grid, Typography } from "@mui/material";
import MultipleCheckBoxType from "./questionType/MultipleCheckBoxType";
import MultipleChoiceType from "./questionType/MultipleChoiceType";
import DragDropIntoBoxType from "./questionType/DragDropIntoBoxType";
import MultipleInlineSelectType from "./questionType/MultipleInlineSelectType";
import MultipleTrueOrFalseType from "./questionType/MultipleTrueOrFalseType";
import SingleInlineSelectType from "./questionType/SingleInlineSelectType";
import WordExplanation from "./questionType/WordExplanation";
import WordExplanationV2 from "./questionType/WordExplanationV2";
import EmbedType from "./questionType/EmbedType";
import InputBoxType from "./questionType/InputBoxType";
import SortOrderType from "./questionType/SortOrderType";
import HorizontalSortType from "./questionType/HorizontalSortType";
import InlineSelectType from "./questionType/InlineSelectType";
import InputBoxFractionType from "./questionType/InputBoxFractionType";
import InputBoxMixedFractionType from "./questionType/InputBoxMixedFractionType";
import MultipleInputBoxType from "./questionType/MultipleInputBoxType";

const ClassQuestionView = ({ questionData }) => {
  const [questions, setQuestions] = useState([]);
  const [qColours, setQColours] = useState([]);
  const [passages, setPassages] = useState({});
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(0);

  const [getFormattedPassage, { passageLoading }] = useLazyQuery(
    GET_FORMATTED_PASSAGE,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [getFormattedQuestion, { questionLoading }] = useLazyQuery(
    GET_FORMATTED_QUESTION,
    {
      fetchPolicy: "no-cache",
    }
  );

  const handleClick = async (questionNumber) => {
    if (!questions[questionNumber - 1].question) {
      const apiCall = await getFormattedQuestion({
        variables: {
          question_id: questions[questionNumber - 1].id,
          is_review: true,
        },
      });
      let formattedQuestion = apiCall.data.getFormattedQuestion;
      if (formattedQuestion.passageId) {
        if (!passages[formattedQuestion.passageId]) {
          const passage = await getFormattedPassage({
            variables: {
              passage_id: formattedQuestion.passageId,
            },
          });
          const newPassages = {
            ...passages,
            [formattedQuestion.passageId]: passage.data.getFormattedPassage,
          };
          setPassages(newPassages);
          formattedQuestion.src = passage.data.getFormattedPassage;
        } else {
          formattedQuestion.src = passages[formattedQuestion.passageId];
        }
      }
      const copyQuestions = [...questions];
      copyQuestions.splice(questionNumber - 1, 1, {
        ...questions[questionNumber - 1],
        ...formattedQuestion,
      });
      setQuestions(copyQuestions);
    }
    setSelectedQuestionNumber(questionNumber);
  };
  useEffect(() => {
    if (qColours.length === 0)
      setQColours(questions.map((q) => getButtonColor(q)));
  }, [qColours.length, questions]);

  const getQuestionType = (currentQuestion) => {
    switch (currentQuestion?.questionType) {
      case "MultipleChoiceType":
        return (
          <MultipleChoiceType
            currentQuestion={currentQuestion}
            isReview={true}
            readOnly={true}
          />
        );
      case "DragDropIntoBoxType":
        return (
          <DragDropIntoBoxType
            currentQuestion={currentQuestion}
            readOnly={true}
          />
        );
      case "SortOrderType":
        return (
          <SortOrderType currentQuestion={currentQuestion} readOnly={true} />
        );
      case "HorizontalSortType":
        return (
          <HorizontalSortType
            currentQuestion={currentQuestion}
            readOnly={true}
          />
        );
      case "InlineSelectType":
        return (
          <InlineSelectType currentQuestion={currentQuestion} readOnly={true} />
        );
      case "MultipleCheckBoxType":
        return (
          <MultipleCheckBoxType
            currentQuestion={currentQuestion}
            readOnly={true}
          />
        );
      // case 7:
      //   return Answer7;
      // case 8:
      //   return Answer8;
      // case 9:
      //   return Answer9;
      case "InputBoxType":
        return (
          <InputBoxType
            currentQuestion={currentQuestion}
            isReview={true}
            readOnly={true}
          />
        );
      case "MultipleTrueOrFalseType":
        return (
          <MultipleTrueOrFalseType
            currentQuestion={currentQuestion}
            readOnly={true}
          />
        );
      case "MultipleInlineSelectType":
        return (
          <MultipleInlineSelectType
            currentQuestion={currentQuestion}
            readOnly={true}
          />
        );
      case "SingleInlineSelectType":
        return (
          <SingleInlineSelectType
            currentQuestion={currentQuestion}
            readOnly={true}
          />
        );
      case "WordExplanation":
        return <WordExplanation currentQuestion={currentQuestion} />;
      case "WordExplanationV2":
        return <WordExplanationV2 currentQuestion={currentQuestion} />;
      case "EmbedType":
        return <EmbedType currentQuestion={currentQuestion} />;
      case "InputBoxFractionType":
        return (
          <InputBoxFractionType
            currentQuestion={currentQuestion}
            isReview={true}
            readOnly={true}
          />
        );
      case "InputBoxMixedFractionType":
        return (
          <InputBoxMixedFractionType
            currentQuestion={currentQuestion}
            isReview={true}
            readOnly={true}
          />
        );
      case "MultipleInputBoxType":
        return (
          <MultipleInputBoxType
            currentQuestion={currentQuestion}
            isReview={true}
            readOnly={true}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setQuestions(questionData);
  }, [questionData]);

  const getButtonColor = (question) => {
    if (question.percentCorrect > 70) {
      return "success";
    } else if (question.percentCorrect <= 40) {
      return "error";
    } else {
      return "orange";
    }
  };

  const getButtons = () => {
    return questions.map((q) => {
      const color = qColours[q.questionNumber - 1];
      return color === "orange" ? (
        <Grid item xs={1}>
          <Button
            variant="contained"
            key={q.questionNumber}
            disabled={questionLoading || passageLoading}
            sx={{
              mb: 2,
              mx: 1,
              backgroundColor: "orange",
              minWidth: "100%",
            }}
            onClick={() => handleClick(q.questionNumber)}
          >
            {q.questionNumber}
            <br />({q.percentCorrect}%)
          </Button>
        </Grid>
      ) : (
        <Grid item xs={1}>
          <Button
            variant="contained"
            key={q.questionNumber}
            disabled={questionLoading || passageLoading}
            sx={{
              mb: 2,
              mx: 1,
              minWidth: "100%",
            }}
            color={color}
            onClick={() => handleClick(q.questionNumber)}
          >
            {q.questionNumber}
            <br />({q.percentCorrect}%)
          </Button>
        </Grid>
      );
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={1}>
          {getButtons()}
        </Grid>
      </Box>
      {selectedQuestionNumber > 0 && questions?.length !== 0 ? (
        <>
          <Typography variant="h6">
            Question {selectedQuestionNumber}
          </Typography>
          <Box>{getQuestionType(questions[selectedQuestionNumber - 1])}</Box>
        </>
      ) : (
        <Box />
      )}
    </Box>
  );
};

export default ClassQuestionView;
