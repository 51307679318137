import { Container, Paper, Typography } from "@mui/material";
import React from "react";

import { Box } from "@mui/system";

const ProgressReport = () => {
  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Progress Report
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProgressReport;
