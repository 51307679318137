import { Typography, Stack } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import sampleRadar from "../assets/sample radar.PNG";

const RadarReportInstructions = () => {
  return (
    <Box sx={{ margin: 3 }}>
      <Typography variant="h6" component="div">
        Interpreting the 'Your Achievement' Spider Chart:
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <img
          src={sampleRadar}
          alt="showing an example graph of the reading component"
          style={{ width: "30%", height: "30%" }}
        />
        <Box sx={{ pl: "11vw" }}>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            The 'Your Achievement' chart depicts your child's relative
            performance across several problem solving behaviours:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Reading Speed & Analysis
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Mathematical Ability
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Strategy & Method
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                General Knowledge & Reasoning
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Speed & Accuracy
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                Perseverance
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
            The further the distance from the centre of the spider diagram means
            the better the performance of your child in that particular problem
            solving behaviour compared to other students.
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default RadarReportInstructions;
