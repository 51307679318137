import { Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import { PreferenceContext, AuthenticatedContext } from "../App";
import { GET_STUDENTS } from "../graphql/queries";
import { ContainerPaper } from "../components";

const StudentManagement = () => {
  const { preferences, setPreferences, defaults } =
    React.useContext(PreferenceContext);
  const { setLoading } = useContext(AuthenticatedContext);
  const stuMgmtPref = useMemo(() => {
    return { ...preferences.studentManagement };
  }, [preferences.studentManagement]);
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(stuMgmtPref.pageSize || 10);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);

  const [getStudents, { studentsLoading }] = useLazyQuery(GET_STUDENTS, {
    onCompleted: (students) => {
      if (students?.getStudents.students.length === 0) {
        setLoading(true);
      } else {
        setLoading(false);
        setRows(students.getStudents.students);
      }

      setRowCount(students.getStudents.total);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    setLoading(true);
    getStudents({
      variables: {
        skip: page * pageSize,
        take: pageSize,
        filters: stuMgmtPref?.filters || defaults.studentManagement.filters,
        sort: stuMgmtPref?.sort || defaults.studentManagement.sort,
      },
    });
  }, [
    defaults.studentManagement.filters,
    defaults.studentManagement.sort,
    page,
    setLoading,
    pageSize,
    stuMgmtPref?.filters,
    stuMgmtPref?.sort,
  ]);

  const handlePageSizeChange = (newPageSize) => {
    stuMgmtPref.pageSize = newPageSize;
    setPageSize(newPageSize);
    setPreferences({
      ...preferences,
      studentManagement: {
        ...stuMgmtPref,
      },
    });
    if (page === 0) {
      getStudents({
        variables: {
          skip: page * newPageSize,
          take: newPageSize,
          filters: stuMgmtPref.filters,
          sort: stuMgmtPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleColumnWidthChange = (params) => {
    stuMgmtPref.widths[params.colDef.field] = params.width;
    setPreferences({
      ...preferences,
      studentManagement: {
        ...stuMgmtPref,
      },
    });
  };

  const handleColumnOrderChange = (params) => {
    stuMgmtPref.columns.splice(params.oldIndex, 1);
    stuMgmtPref.columns.splice(params.targetIndex, 0, params.field);
    setPreferences({
      ...preferences,
      studentManagement: {
        ...stuMgmtPref,
      },
    });
  };

  const handleColumnVisibilityModelChange = (model) => {
    stuMgmtPref.visible = { ...model };
    setPreferences({
      ...preferences,
      studentManagement: {
        ...stuMgmtPref,
      },
    });
  };

  const handleSortModelChange = (model) => {
    stuMgmtPref.sort = [...model];

    setPreferences({
      ...preferences,
      studentManagement: {
        ...stuMgmtPref,
      },
    });
    if (page === 0) {
      getStudents({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: stuMgmtPref.filters,
          sort: [...model],
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleFilterModelChange = (model) => {
    stuMgmtPref.filters = { ...model };

    setPreferences({
      ...preferences,
      studentManagement: {
        ...stuMgmtPref,
      },
    });
    if (page === 0) {
      getStudents({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filters: { ...model },
          sort: stuMgmtPref.sort,
        },
      });
    } else {
      setPage(0);
    }
  };

  const viewStudent = useCallback(
    (params) => () =>
      history.push(`/studentmanagement/${params.row.id}`, { data: params.row }),
    [history]
  );

  function getFullName(params) {
    return `${params.row.givenname || ""} ${params.row.surname || ""}`;
  }

  function getIsActive(params) {
    return params.row.isActivated ? "Y" : "N";
  }

  function getGrade(params) {
    return params.row.gradeId ? "Year " + params.row.gradeId : "";
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true, // fix Uncaught TypeError: Failed to execute 'contains' on 'Node'
        headerName: "Actions",
        width: stuMgmtPref?.widths?.actions,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getApplyQuickFilterFn: undefined,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            onClick={viewStudent(params)}
          />,
        ],
      },
      {
        field: "login",
        headerName: "Student Number",
        width: stuMgmtPref?.widths?.login,
        headerAlign: "center",
        align: "center",
        hide: !stuMgmtPref?.visible?.login,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "name",
        headerName: "Name",
        width: stuMgmtPref?.widths?.name,
        headerAlign: "center",
        align: "center",
        hide: !stuMgmtPref?.visible?.name,
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getFullName,
      },
      {
        field: "campus",
        headerName: "Campus",
        width: stuMgmtPref?.widths?.campus,
        headerAlign: "center",
        align: "center",
        hide: !stuMgmtPref?.visible?.campus,
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "gradeId",
        headerName: "Grade",
        width: stuMgmtPref?.widths?.gradeId,
        headerAlign: "center",
        align: "center",
        hide: !stuMgmtPref?.visible?.gradeId,
        getApplyQuickFilterFn: undefined,
        valueGetter: getGrade,
      },
      {
        field: "isActivated",
        headerName: "Is Active",
        width: stuMgmtPref?.widths?.isActivated,
        headerAlign: "center",
        align: "center",
        hide: !stuMgmtPref?.visible?.isActivated,
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsActive,
        type: "boolean",
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: stuMgmtPref?.widths?.createdAt,
        headerAlign: "center",
        hide: !stuMgmtPref?.visible?.createdAt,
        type: "date",
        getApplyQuickFilterFn: undefined,
        filterable: false,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
        align: "center",
      },
    ],
    [viewStudent, stuMgmtPref]
  );
  columns.sort(
    (a, b) =>
      stuMgmtPref?.columns?.indexOf(a.field) -
      stuMgmtPref?.columns?.indexOf(b.field)
  );

  return (
    <ContainerPaper>
      <Box
        sx={{
          display: "flex",
          mb: 3,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
          Student Management
        </Typography>
      </Box>
      <DataGridPro
        rows={rows}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSize={stuMgmtPref.pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50, 100, 300]}
        disableSelectionOnClick
        autoHeight
        loading={studentsLoading}
        onColumnWidthChange={handleColumnWidthChange}
        onColumnOrderChange={handleColumnOrderChange}
        pagination
        pinnedColumns={{ left: ["actions"] }}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        filterModel={stuMgmtPref.filters}
        sortModel={stuMgmtPref.sort}
        page={page}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        rowCount={rowCount}
        density="compact"
      />
    </ContainerPaper>
  );
};

export default StudentManagement;
