import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import AddUser from "../components/AddUser";
import { ContainerPaper } from "../components";
import { GET_REGIONS, GET_STAFFS } from "../graphql/queries";
import { format } from "date-fns";

const UserManagement = () => {
  const [getStaffs, { loading: usersLoading }] = useLazyQuery(GET_STAFFS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setRows(data.getStaffs);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { data: regions, loading: regionsLoading } = useQuery(GET_REGIONS, {
    fetchPolicy: "cache-and-network",
  });

  const [rows, setRows] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);

  const handlePageSizeChange = (newPageSize) => setPageSize(newPageSize);

  const handleClose = () => setOpen(false);

  const callStaffs = useCallback(() => {
    getStaffs();
  }, []);

  useEffect(() => {
    callStaffs();
  }, []);

  function getFullName(params) {
    return `${params.row.givenname || ""} ${params.row.surname || ""}`;
  }

  function getRole(params) {
    return `${params.row.Role?.name || ""}`;
  }

  function getRegion(params) {
    return `${params.row.Region?.name || ""}`;
  }

  function getCentres(params) {
    const names = params.row.Centres?.map((c) => c.Centre?.name);
    if (names?.length > 0) {
      return names.join(", ");
    } else {
      return "";
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "Staff ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 3,
      valueGetter: getFullName,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "easyEmail",
      headerName: "Email",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      valueGetter: getRole,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      valueGetter: getRegion,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "campuses",
      headerName: "Centres",
      flex: 3,
      valueGetter: getCentres,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 2,
      headerAlign: "center",
      type: "dateTime",
      getApplyQuickFilterFn: undefined,
      filterable: false,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(value, "dd/MM/yyyy HH:mm:ss");
        }
      },
      align: "center",
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 2,
      headerAlign: "center",
      type: "dateTime",
      getApplyQuickFilterFn: undefined,
      filterable: false,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(value, "dd/MM/yyyy HH:mm:ss");
        }
      },
      align: "center",
    },
  ];

  const roles = [
    {
      name: "Admin",
      roleId: 1,
      authorize: [1],
    },
    {
      name: "Education Consultant",
      roleId: 5,
      authorize: [1, 5],
    },
    {
      name: "Teacher",
      roleId: 6,
      authorize: [1, 5, 9],
    },
    {
      name: "Business Management",
      roleId: 9,
      authorize: [1],
    },
    {
      name: "Campus Director",
      roleId: 4,
      authorize: [1, 5, 4],
    },
  ];

  return (
    <ContainerPaper>
      <Box
        sx={{
          display: "flex",
          mb: 3,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
          User Management
        </Typography>
      </Box>

      <AddUser
        open={open}
        handleClose={handleClose}
        regions={regions?.getRegions}
        roles={roles}
        getStaffs={getStaffs}
      />
      {rows && (
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]} // Options for rows per page
          pagination // Enable pagination
          disableSelectionOnClick
          autoHeight
          loading={usersLoading || regionsLoading}
        />
      )}
    </ContainerPaper>
  );
};

export default UserManagement;
