import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { format } from "date-fns";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { GET_DASHBOARD, GET_UNREGISTERED_TRAININGS } from "../graphql/queries";
import { ModuleContext, StudentMonitoringContext } from "../App";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ErrorIcon from "@mui/icons-material/Error";
import SentimentNeutralSharpIcon from "@mui/icons-material/SentimentNeutralSharp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SelectStudentTraining from "../components/SelectStudentTraining";
import TrainingReport from "../components/TrainingReport";

const IndividualStudentMonitor = () => {
  const { studentMonitoringData } = useContext(StudentMonitoringContext);
  const history = useHistory();
  const { moduleSettings, setModuleSettings, exitSitting } = useContext(ModuleContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);
  const [trainingDialogOpen, setTrainingDialogOpen] = useState(false);
  const [setTrainings] = useState([]);
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);

  const [getDashboard] = useLazyQuery(GET_DASHBOARD);

  const [getUnregisteredTrainings] = useLazyQuery(GET_UNREGISTERED_TRAININGS, {
    onCompleted: (trainings) => {
      if (trainings?.getUnregisteredTrainings.length > 0) {
        setTrainings(trainings.getUnregisteredTrainings);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  // useEffect(() => {
  //   if (selectedRow && resultDialogOpen) {
  //     getTrainingAttempts({
  //       variables: {
  //         student_id: studentMonitoringData.id,
  //         ...selectedRow.type === "Training" ? { training_id: selectedRow.moduleID } : { training_id: selectedRow.trainingId },
  //         training_type: selectedRow.type
  //       }
  //     })
  //   }
  // }, [selectedRow, resultDialogOpen]);

  useEffect(() => {
    exitSitting();
    if (!studentMonitoringData) return enqueueSnackbar("No student params data", { variant: "warning" });
    let rows = [];
    if (studentMonitoringData.Trainings) {
      for (const i of studentMonitoringData.Trainings) {
        if (i.TrainingSittings.length > 0) {
          for (const j of i.TrainingSittings) {
            const row = {
              id: Math.random().toString(16).slice(2),
              moduleID: i.id,
              name: i.name,
              ...(i.isInteractive
                ? { type: "Activity" }
                : { type: "Training" }),
              status: j.isCompleted ? "Completed" : "In Progress",
              ...(i.isInteractive
                ? { progress: `${j.upTo}/${i.totalQuestions}` }
                : {
                    progress: `${j.upTo}/${
                      i.totalQuestions +
                      i.totalQuestions -
                      i.firstGroupQuestions
                    }`,
                  }),
              score:
                j.isCompleted && !i.isInteractive
                  ? !i.assessmentId
                    ? Number(j.scoreSection1)
                    : j.scoreSection2
                    ? (Number(j.scoreSection1) +
                        Number(j.scoreSection2) +
                        Number(j.scoreSection3)) /
                      3
                    : (Number(j.scoreSection1) + Number(j.scoreSection3)) / 2
                  : "",
              attempts: i.trainingAttempt,
              completionDate: j.completedDate,
              hidden: i.order + 1 || 0,
              ...(i.isInteractive ? { dueDate: i.dueDate } : {}),
              ...(i.isInteractive
                ? { trainingTotalQuestions: i.totalQuestions }
                : {
                    trainingTotalQuestions:
                      2 * i.totalQuestions - i.firstGroupQuestions,
                  }),
              upTo: j.upTo
            };
            rows = [...rows, row];
          }
        } else {
          const row = {
            id: Math.random().toString(16).slice(2),
            moduleID: i.id,
            name: i.name,
            ...(i.isInteractive ? { type: "Activity" } : { type: "Training" }),
            status: "Not Started",
            hidden: i.order + 1 || 0,
            ...(i.isInteractive ? { dueDate: i.dueDate } : {}),
          };
          rows = [...rows, row];
        }
      }
    }
    if (studentMonitoringData.Assessments) {
      for (const i of studentMonitoringData.Assessments) {
        if (i.Sittings.length > 0) {
          for (const j of i.Sittings) {
            const training = studentMonitoringData.Trainings.find(
              (t) => t.assessmentId === i.id
            );
            const row = {
              id: Math.random().toString(16).slice(2),
              moduleID: i.id,
              name: i.name,
              type: "Assessment",
              status: j.isCompleted ? "Completed" : "In Progress",
              progress: `${j.upTo}/${i.totalQuestions}`,
              score: (j.score.split("/")[0] / j.score.split("/")[1]) * 100,
              attempts: studentMonitoringData.Trainings.find(
                (t) => t.assessmentId === i.id
              )?.assessmentAttempt,
              completionDate: j.completedDate,
              dueDate: i.dueDate,
              hidden: i.order + 1 || 0,
              trainingId: training?.id,
              trainingTotalQuestions:
                2 * training?.totalQuestions - training?.firstGroupQuestions,
              totalQuestions: i.totalQuestions,
            };
            rows = [...rows, row];
          }
        } else {
          const row = {
            id: Math.random().toString(16).slice(2),
            moduleID: i.id,
            name: i.name,
            type: "Assessment",
            status: "Not Started",
            dueDate: i.dueDate,
            hidden: i.order + 1 || 0,
            trainingId: studentMonitoringData.Trainings.find(
              (t) => t.assessmentId === i.id
            )?.id,
            trainingTotalQuestions: studentMonitoringData.Trainings.find(
              (t) => t.assessmentId === i.id
            )?.totalQuestions,
            totalQuestions: i.totalQuestions,
          };;
          rows = [...rows, row];
        }
      }
    }
    function compare(a, b) {
      if (a.hidden === b.hidden) {
        if (
          a.type === "Activity" &&
          (b.type === "Training" || b.type === "Assessment")
        ) {
          return 1;
        } else if (b.type === "Activity" && (a.type === "Training" || a.type === "Assessment")) {
          return -1;
        }
        if (a.name === b.name) {
          if (a.type === "Training") {
            return -1;
          } else {
            return 1;
          }
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return a.hidden - b.hidden;
      }
    }
    setRows(rows.sort(compare));
    getUnregisteredTrainings({
      variables: {
        student_id: Number(id),
      },
    });
  }, [id, studentMonitoringData]);

  const viewTestDetail = useCallback(
    (params) => async () => {
      if (studentMonitoringData && params) {
        const respond = await getDashboard({
          variables: {
            user_id: studentMonitoringData?.id,
            role: "Student",
          },
        });
        if (!respond)
          return enqueueSnackbar("Can not get student sitting data", {
            variant: "warning",
          });

        if (params.row.type === "Training") {
          const findTrainingModule =
            respond?.data?.getDashboard?.trainings?.find(
              (i) => i.id === params?.row?.moduleID
            );
          if (!findTrainingModule)
            return enqueueSnackbar("no module found", { variant: "warning" });

          setModuleSettings({
            ...moduleSettings,
            selectedModule: findTrainingModule,
            moduleType: "Training",
          });

          history.push("/training-question");
        } else {
          const findAssessmentModule =
            respond?.data?.getDashboard?.assessments?.find(
              (i) => i.id === params?.row?.moduleID
            );
          if (!findAssessmentModule)
            return enqueueSnackbar("no module found", { variant: "warning" });

          setModuleSettings({
            ...moduleSettings,
            selectedModule: findAssessmentModule,
            moduleType: "Assessment",
          });

          history.push("/assessment-question");
        }
      }
    },
    [
      enqueueSnackbar,
      getDashboard,
      history,
      moduleSettings,
      setModuleSettings,
      studentMonitoringData,
    ]
  );

  // useEffect(() => {
  //   console.log(selectedRow);
  // }, [selectedRow]);

  const disableButton = (params) => {
    if (params.row.type === "Training") {
      if (params.row.status === "Not Started") {
        return true;
      } else if (params.row.status === "In Progress") {
        if (params.row.upTo && Number(params.row.upTo) > 1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else if (params.row.type === "Assessment" && params.row.status === "Completed") {
      return false;
    } else {
      return true;
    }
  }
  const columns = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        disableClickEventBubbling: true,
        headerName: "Actions",
        width: 110,
        headerAlign: "center",
        disableReorder: true,
        hideable: false,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            color="primary"
            disabled={disableButton(params)}
            onClick={viewTestDetail(params)}
          />,
          <GridActionsCellItem
            icon={<AssessmentIcon />}
            label="Result"
            color="primary"
            onClick={() => {
              setSelectedRow(params.row);
              setResultDialogOpen(true);
            }}
            disabled={
              params.row.attempts === 0 ||
              !params.row.attempts ||
              (params.row.type !== "Assessment" && params.row.assessmentId)
            }
          />,
        ],
      },
      {
        field: "id",
        headerName: "ID",
        width: 100,
        headerAlign: "center",
        align: "center",
        hide: true,
      },
      {
        field: "moduleID",
        headerName: "Module ID",
        width: 100,
        headerAlign: "center",
        align: "center",
        hide: true,
      },
      {
        field: "name",
        headerName: "Training Name",
        width: 200,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "type",
        headerName: "Type",
        width: 140,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => {
          if (value === "Training") {
            return (
              <Chip
                variant="filled"
                label="Training"
                size="small"
                color="info"
                sx={{ py: 1.8, px: 0.8, fontWeight: 500 }}
              />
            );
          } else if (value === "Assessment") {
            return (
              <Chip
                variant="filled"
                label="Assessment"
                size="small"
                color="warning"
                sx={{ py: 1.8, px: 0.8, fontWeight: 500 }}
              />
            );
          } else if (value === "Activity") {
            return (
              <Chip
                variant="filled"
                label="Activity"
                size="small"
                color="success"
                sx={{ py: 1.8, px: 0.8, fontWeight: 500 }}
              />
            );
          }
        },
      },
      {
        field: "hidden",
        headerName: "Week",
        width: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "status",
        headerName: "Status",
        width: 140,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => {
          if (value === "Completed") {
            return (
              <Chip
                variant="outlined"
                label="Completed"
                size="small"
                color="success"
                sx={{ py: 1.8, px: 0.8, fontWeight: 500 }}
              />
            );
          } else if (value === "In Progress") {
            return (
              <Chip
                variant="outlined"
                label="In Progress"
                size="small"
                color="info"
                sx={{ py: 1.8, px: 0.8, fontWeight: 500 }}
              />
            );
          } else if (value === "Not Started") {
            return (
              <Chip
                variant="outlined"
                label="Not Started"
                size="small"
                color="warning"
                sx={{ py: 1.8, px: 0.8, fontWeight: 500 }}
              />
            );
          } else {
            return (
              <Chip
                variant="outlined"
                label="Locked"
                size="small"
                color="error"
                sx={{ py: 1.8, px: 0.8, fontWeight: 500 }}
              />
            );
          }
        },
      },
      {
        field: "progress",
        headerName: "Progress",
        width: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "score",
        headerName: "Score",
        width: 140,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => {
          if (!value) return;
          if (value?.toFixed(0) <= 30) {
            return (
              <Chip
                variant="filled"
                icon={<ErrorIcon />}
                label={value.toFixed(0) + "%"}
                size="small"
                color="error"
                sx={{ py: 1.8, px: 0.8, fontSize: 14, fontWeight: 500 }}
              />
            );
          } else if (value?.toFixed(0) > 30 && value?.toFixed(0) <= 60) {
            return (
              <Chip
                variant="filled"
                icon={<SentimentNeutralSharpIcon />}
                label={value.toFixed(0) + "%"}
                size="small"
                color="warning"
                sx={{ py: 1.8, px: 0.8, fontSize: 14, fontWeight: 500 }}
              />
            );
          } else {
            return (
              <Chip
                variant="filled"
                icon={<EmojiEmotionsIcon />}
                label={value.toFixed(0) + "%"}
                size="small"
                color="success"
                sx={{ py: 1.8, px: 0.8, fontSize: 14, fontWeight: 500 }}
              />
            );
          }
        },
      },
      {
        field: "attempts",
        headerName: "Attempts",
        width: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "completionDate",
        headerName: "Completion Date",
        width: 160,
        headerAlign: "center",
        align: "center",
        type: "dateTime",
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
      },
      {
        field: "dueDate",
        headerName: "Due Date",
        width: 160,
        headerAlign: "center",
        align: "center",
        type: "dateTime",
        hide: true,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(value, "dd/MM/yyyy");
          }
        },
      },
      // {
      //   field: "isAllocated",
      //   headerName: "Is Allocated",
      //   width: classMgmtPref?.widths?.isAllocated,
      //   headerAlign: "center",
      //   align: "center",
      //   hide: !classMgmtPref?.visible?.isAllocated,
      //   renderCell: (params) => {
      //     if (params.row.isAllocated) {
      //       return <CheckIcon color="primary" />;
      //     } else {
      //       return <CloseIcon color="primary" />;
      //     }
      //   },
      // },
    ],
    [viewTestDetail, setResultDialogOpen]
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6" sx={{ mb: 0 }}>
            Student Monitoring
            <br />{" "}
            {studentMonitoringData?.givenname +
              " " +
              studentMonitoringData?.surname +
              " - " +
              studentMonitoringData?.login}
          </Typography>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box />
          <Button variant="contained" onClick={() => setTrainingDialogOpen(true)}>Add Training</Button>
        </Box> */}
        <Dialog
          maxWidth="md"
          open={trainingDialogOpen}
          onClose={() => setTrainingDialogOpen(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <DialogTitle align="center">Add Training</DialogTitle>
          <DialogContent>
            <SelectStudentTraining student_id={studentMonitoringData?.id} />
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={() => setTrainingDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen
          open={resultDialogOpen}
          onClose={() => {
            setSelectedRow(null);
            setResultDialogOpen(false);
          }}
        >
          <TrainingReport
            openFn={setResultDialogOpen}
            student_id={studentMonitoringData.id}
            training_module={selectedRow?.trainingId || selectedRow?.moduleID}
            name={selectedRow?.name}
            totalTraining={selectedRow?.trainingTotalQuestions}
            totalAssessment={selectedRow?.totalQuestions}
          />
        </Dialog>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          pagination={true}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          pinnedColumns={{ left: ["actions"] }}
        />
        <Button
          variant="contained"
          startIcon={<ArrowBackIosIcon />}
          size="small"
          onClick={() => history.push("/classmanagementteacher")}
          sx={{ mt: 2.5 }}
        >
          Back
        </Button>
      </Paper>
    </Container>
  );
};

export default IndividualStudentMonitor;
