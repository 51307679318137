import { Grid, Typography, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useLazyQuery } from "@apollo/client";
import { GET_REPORT } from "../graphql/queries";
import logo from "../assets/NSCC_logo.png";
import altlogo from "../assets/SolverEDGE.svg";
import { useLocation, useParams } from "react-router-dom";
import RadarGraph from "../components/graphs/RadarGraph";
import ScatterGraph from "../components/graphs/ScatterGraph";
import BarGraph from "../components/graphs/BarGraph";
import RadarReportInstructions from "../components/RadarReportInstructions";
import ReadingReportInstructions from "../components/ReadingReportInstructions";
import BarReportInstructions from "../components/BarReportInstructions";
import { AuthenticatedContext } from "../App";

const PlacementReport = () => {
  const [placementData, setPlacementData] = useState(null);
  const [overall, setOverall] = useState([]);
  const [todayDate, setTodayDate] = useState("");
  const [lComment, setLComment] = useState("");
  const [iComment, setIComment] = useState("");
  const [trainings, setTrainings] = useState([]);
  const [setPayment] = useState("");
  const [givenname, setGivenname] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState(0);

  const { user } = useContext(AuthenticatedContext);

  const location = useLocation();
  const { program, id } = useParams();

  const [getReport] = useLazyQuery(GET_REPORT, {
    onCompleted: (report) => {
      // const reportData = report.getReport;
      // if (reportData.studentRanks) {
      //   let newRankData = [...reportData.studentRanks?.strands];
      //   newRankData.push({
      //     name: "Overall",
      //     score: reportData.studentRanks?.Overall,
      //   });
      //   setRankData(newRankData);
      // }
      // setQuestionData(reportData.questionData);
      // setTestName(reportData.testName);
      // setPassageData(reportData.passageData);
      if (report.getReport.trainings)
        setTrainings(report.getReport.trainings.split("|"));
      let sortedResults = {};
      const results = report.getReport.ps_results;
      const readingResults = results.filter((r) => r.psType === "Reading");

      let readingComponents = {};
      function reverseCompareScore(a, b) {
        return b.score - a.score;
      }
      const iTimes = readingResults
        .filter((r) => r.strand === "Inferential Analysis|Time")
        .sort(reverseCompareScore);
      const lTimes = readingResults
        .filter((r) => r.strand === "Literal Analysis|Time")
        .sort(reverseCompareScore);
      const iMarks = readingResults
        .filter((r) => r.strand === "Inferential Analysis|Mark")
        .sort(reverseCompareScore);
      const lMarks = readingResults
        .filter((r) => r.strand === "Literal Analysis|Mark")
        .sort(reverseCompareScore);

      const iTimeRank =
        (iTimes.findIndex((i) => i.studentId === report.getReport.Student.id) +
          1) /
        iTimes.length;
      const lTimeRank =
        (lTimes.findIndex((l) => l.studentId === report.getReport.Student.id) +
          1) /
        lTimes.length;
      const iMarkRank =
        (iMarks.findIndex((i) => i.studentId === report.getReport.Student.id) +
          1) /
        iMarks.length;
      const lMarkRank =
        (lMarks.findIndex((l) => l.studentId === report.getReport.Student.id) +
          1) /
        lMarks.length;

      if (iTimeRank < 0.2) {
        readingComponents["iTime"] = 1.5;
      } else if (iTimeRank <= 0.8) {
        readingComponents["iTime"] = 1;
      } else {
        readingComponents["iTime"] = 0.5;
      }
      if (lTimeRank < 0.2) {
        readingComponents["lTime"] = 2.5;
      } else if (lTimeRank <= 0.8) {
        readingComponents["lTime"] = 1.5;
      } else {
        readingComponents["lTime"] = 0.5;
      }
      if (iMarkRank < 0.2) {
        readingComponents["iMark"] = 3.5;
      } else if (iMarkRank <= 0.8) {
        readingComponents["iMark"] = 2;
      } else {
        readingComponents["iMark"] = 0.5;
      }
      if (lMarkRank < 0.2) {
        readingComponents["lMark"] = 2.5;
      } else if (lMarkRank <= 0.8) {
        readingComponents["lMark"] = 1.5;
      } else {
        readingComponents["lMark"] = 0.5;
      }
      readingComponents["inferential"] =
        readingComponents["iTime"] + readingComponents["iMark"];
      readingComponents["literal"] =
        readingComponents["lTime"] + readingComponents["lMark"];
      readingComponents["total"] =
        readingComponents["inferential"] + readingComponents["literal"];

      switch (readingComponents["literal"]) {
        case 1:
          setLComment(
            "Reading speed is slow relative to other students; difficulty experienced answering literal style of questions."
          );
          break;
        case 2:
          if (readingComponents["lTime"] === 0.5) {
            setLComment(
              "Reading speed is slow relative to other students; able to answer literal style of questions."
            );
          } else {
            setLComment(
              "Good reading speed relative to other students; difficulty experienced answering literal style of questions."
            );
          }
          break;
        case 3:
          switch (readingComponents["lTime"]) {
            case 0.5:
              setLComment(
                "Reading speed is slow relative to other students; confident to answer literal style of questions."
              );
              break;
            case 1.5:
              setLComment(
                "Good reading speed relative to other students; able to answer literal style of questions."
              );
              break;
            case 2.5:
              setLComment(
                "Fast reading speed relative to other students; difficulty experienced answering literal style of questions."
              );
              break;
            default:
              setLComment("Unexpected reading time value.");
          }
          break;
        case 4:
          if (readingComponents["lTime"] === 1.5) {
            setLComment(
              "Good reading speed relative to other students; confident to answer literal style of questions."
            );
          } else {
            setLComment(
              "Fast reading speed relative to other students; able to answer literal style of questions."
            );
          }
          break;
        case 5:
          setLComment(
            "Fast reading speed relative to other students; confident to answer literal style of questions."
          );
          break;
        default:
          setLComment("Unexpected literal value.");
      }

      switch (readingComponents["inferential"]) {
        case 1:
          setIComment(
            "Reading speed is slow relative to other students; difficulty experienced answering inferential style of questions."
          );
          break;
        case 1.5:
          setIComment(
            "Good reading speed relative to other students; difficulty experienced answering inferential style of questions."
          );
          break;
        case 2:
          setIComment(
            "Fast reading speed relative to other students; difficulty experienced answering inferential style of questions."
          );
          break;
        case 2.5:
          setIComment(
            "Reading speed is slow relative to other students; able to answer inferential style of questions."
          );
          break;
        case 3:
          setIComment(
            "Good reading speed relative to other students; able to answer inferential style of questions."
          );
          break;
        case 3.5:
          setIComment(
            "Fast reading speed relative to other students; able to answer inferential style of questions."
          );
          break;
        case 4:
          setIComment(
            "Reading speed is slow relative to other students; confident to answer inferential style of questions."
          );
          break;
        case 4.5:
          setIComment(
            "Good reading speed relative to other students; confident to answer inferential style of questions."
          );
          break;
        case 5:
          setIComment(
            "Fast reading speed relative to other students; confident to answer inferential style of questions."
          );
          break;
        default:
          setIComment("Unexpected inferential value.");
      }

      const radarData = [
        {
          subject: "Reading Speed & Analysis",
          A: 5 + readingComponents["total"],
        },
      ];
      const mathResults = results
        .filter((r) => r.psType === "Mathematics")
        .map((r) => {
          if (r.strand === "Overall") {
            radarData.push({
              subject: "Mathematical Ability",
              A: Math.floor(r.score / 10) + 5,
            });
          }
          return {
            ...r,
            name: r.strand,
            unused: 100,
          };
        });
      const msResults = results
        .filter((r) => r.psType === "Method & Strategy")
        .map((r) => {
          if (r.strand === "Overall") {
            radarData.push({
              subject: "Method & Strategy",
              A: Math.floor(r.score / 10) + 5,
            });
          }
          return {
            ...r,
            name: r.strand,
            unused: 100,
          };
        });
      const gkResults = results
        .filter((r) => r.psType === "General Knowledge")
        .map((r) => {
          if (r.strand === "General Knowledge") {
            radarData.push({
              subject: "General Knowledge & Reasoning",
              A: Math.floor(r.score / 10) + 5,
            });
          }
          return {
            ...r,
            name: r.strand,
            unused: 200,
          };
        });
      let saResults = results
        .filter((r) => r.psType === "Speed & Accuracy")
        .map((r) => {
          if (r.strand === "Overall") {
            radarData.push({
              subject: "Speed & Accuracy",
              A: Math.floor(r.score / 10) + 5,
            });
          }
          return {
            ...r,
            name: r.strand,
            unused: 100,
          };
        });
      const fcResults = results
        .filter((r) => r.psType === "Focus & Concentration")
        .map((r) => {
          // if (r.strand === "Overall") {
          //   radarData.push({
          //     subject: "Focus & Perseverance",
          //     A: Math.floor(r.score / 10) + 5
          //   });
          // }
          radarData.push({
            subject: "Perseverance",
            A: Math.floor(r.score / 10) + 5,
          });
          return {
            ...r,
            name: r.strand,
            unused: 100,
          };
        });

      let infSittingData = {};
      let litSittingData = {};
      let isDefault = iTimes.find(
        (i) => i.studentId === report.getReport.Student.id
      ).isDefault;
      readingResults.forEach((r) => {
        const strand = r.strand.split("|");
        if (strand[0] === "Literal Analysis") {
          if (r.studentId in litSittingData) {
            if (strand[1] === "Mark") {
              litSittingData[r.studentId].correct = r.score;
            } else {
              litSittingData[r.studentId].speed = r.score;
            }
          } else {
            litSittingData[r.studentId] = {
              studentId: r.studentId,
            };
            if (strand[1] === "Mark") {
              litSittingData[r.studentId].correct = r.score;
            } else {
              litSittingData[r.studentId].speed = r.score;
            }
          }
        } else {
          if (r.studentId in infSittingData) {
            if (strand[1] === "Mark") {
              infSittingData[r.studentId].correct = r.score;
            } else {
              infSittingData[r.studentId].speed = r.score;
            }
          } else {
            infSittingData[r.studentId] = {
              studentId: r.studentId,
            };
            if (strand[1] === "Mark") {
              infSittingData[r.studentId].correct = r.score;
            } else {
              infSittingData[r.studentId].speed = r.score;
            }
          }
        }
      });
      sortedResults.Reading = {
        passageData: [
          {
            name: "Literal Analysis",
            sittingData: [...Object.values(litSittingData)],
          },
          {
            name: "Inferential Analysis",
            sittingData: [...Object.values(infSittingData)],
          },
        ],
      };
      function compare(a, b) {
        if (a.strand === "Overall") {
          return 1;
        } else if (b.strand === "Overall") {
          return -1;
        } else {
          return a.strand - b.strand;
        }
      }

      function reverseCompare(a, b) {
        if (a.strand === "Overall") {
          return 1;
        } else if (b.strand === "Overall") {
          return -1;
        } else {
          return b.strand - a.strand;
        }
      }
      sortedResults.Mathematics = [...mathResults.sort(compare)];
      sortedResults["Method & Strategy"] = [...msResults.sort(compare)];
      sortedResults["General Knowledge"] = [...gkResults];
      sortedResults["Speed & Accuracy"] = [...saResults.sort(reverseCompare)];
      sortedResults["Focus & Concentration"] = [...fcResults.sort(compare)];
      setPlacementData({ ...sortedResults, isDefault });
      setOverall(radarData);
      // setReportName(report.getReport.testName);
      setPayment(
        `https://problemsolving.north-shore.com.au/payment?student_number=${report.getReport.Student.login}`
      );
      setGivenname(report.getReport.Student.givenname);
      setStudentName(
        `${report.getReport.Student.givenname} ${report.getReport.Student.surname}`
      );
      setStudentId(report.getReport.Student.id);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (location.pathname === `/report/${program}`) {
      getReport({
        variables: {
          program_id: Number(program),
          student_id: Number(JSON.parse(user).student_id),
        },
      });
    } else if (location.pathname === `/report/${program}/${id}`) {
      getReport({
        variables: {
          program_id: Number(program),
          student_id: Number(id),
        },
      });
    }

    const today = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    setTodayDate(
      `${today.getDate()} ${months[today.getMonth()]} ${today.getFullYear()}`
    );
  }, [id, location.pathname, program, user]);

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  return (
    <>
      <Box sx={{ margin: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          {user &&
          ((JSON.parse(user).student_region &&
            JSON.parse(user).student_region === 8) ||
            (JSON.parse(user).region_id &&
              JSON.parse(user).region_id === 8)) ? (
            <img
              src={altlogo}
              alt="SolverEdge Logo"
              style={{ marginRight: "32px", height: "60px", maxWidth: "100px" }}
            />
          ) : (
            <img
              src={logo}
              alt="NSCC Logo"
              style={{ marginRight: "32px", height: "60px", maxWidth: "100px" }}
            />
          )}
          <Typography variant="h4" component="div">
            Problem Solving Assessment Report
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          <Typography variant="h5" component="div">
            {todayDate}
          </Typography>
        </Stack>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          Dear {toTitleCase(studentName)},
        </Typography>
        <br />
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          Thank you for sitting the Problem Solving Assessment with SolverEdge.
          This report is best viewed on desktop computer/laptop or device with
          screen size of 10 inches or more. If the achievement scale below does
          not load correctly, we recommend that you download the PDF report onto
          a desktop computer/laptop and open it using PDF reader software.
        </Typography>
        <br />
        <br />
        <br />
        <Typography variant="h5" component="div">
          Your Achievement
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          <RadarGraph data={overall} type="average" />
          <Box />
        </Stack>
        <br />
        {trainings.length > 0 &&
        user &&
        ((JSON.parse(user).student_region &&
          JSON.parse(user).student_region === 8) ||
          (JSON.parse(user).region_id && JSON.parse(user).region_id === 8)) ? (
          <>
            <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
              {toTitleCase(givenname)}
              {toTitleCase(givenname).slice(-1) === "s" ? "'" : "'s"} problem
              solving level could be raised through enrolling in our Problem
              Solving training which will comprise of the following training
              modules:
            </Typography>
            <Typography variant="body1" component="div" sx={{ fontSize: 16 }}>
              <ul>
                {trainings.sort().map((t) => (
                  <li>{t}</li>
                ))}
              </ul>
            </Typography>
          </>
        ) : (
          <></>
        )}

        {/* <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          Each module will include a short training video and exercises.
        </Typography>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          To enrol in the ALL of the above for a highly subsidised fee of $90, click the button below:
        </Typography>
        <br />
        <Button disabled={!payment} variant="contained" onClick={() => window.open(payment, "_blank")}>Find Out More About Our Problem Solving Training</Button>
        <br />
        <br />
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          You have also been selected for a special offer, where you can trial a small section of our Problem Solving Ability Training until 21 April 2024 for FREE.
        </Typography>
        <Button variant="contained" onClick={() => window.open("https://problemsolving.north-shore.com.au/studenttraining", "_blank")}>Trial Now</Button>
        <br /> */}
        <br />
        <Typography
          variant="body1"
          component="div"
          sx={{ pageBreakBefore: "always", fontSize: 20 }}
        >
          In the following pages, you will find more detailed analysis.
        </Typography>
        <br />
        <Typography variant="h5" component="div">
          1. Reading Speed & Analysis
        </Typography>
        <br />
        {placementData != null && (
          <Grid container spacing={2}>
            {placementData.Reading.passageData.map((p) => {
              return (
                <Grid item xs={6}>
                  <ScatterGraph
                    passageData={p}
                    student_id={studentId}
                    isDefault={placementData.isDefault}
                    student_name={toTitleCase(givenname)}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <br />
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Reading Speed:</strong> The speed at which a student can read
          and process texts presented to them. If reading speed is slow, the
          student may struggle to be able to answer questions within the given
          amount of time. If reading speed is too fast, the student may struggle
          to effectively process and obtain a proper understanding of the texts.
        </Typography>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Literal Analysis:</strong> The ability of a student to process
          and understand facts and details in a text. Questions involving
          literal analysis will require students to recall information from the
          text in order to be able to answer the question.
        </Typography>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Inferential Analysis:</strong> The ability of a student to
          interpret information in a text and make reasonable conclusions.
          Questions involving inferential analysis will require students to go
          beyond understanding the facts and details of the text and infer the
          meaning behind them.
        </Typography>
        {!placementData?.isDefault && (
          <>
            <br />
            <br />
            <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
              Your child's performance and behaviour in the Reading Assessment
              can be summarised as follows:
            </Typography>
            <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
              <strong>Literal Analysis:</strong>
            </Typography>
            <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
              {lComment}
            </Typography>
            <br />
            <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
              <strong>Inferential Analysis:</strong>
            </Typography>
            <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
              {iComment}
            </Typography>
          </>
        )}
        <br />
        <Typography
          variant="h5"
          component="div"
          sx={{ pageBreakBefore: "always" }}
        >
          2. Mathematical Ability
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          {placementData != null && (
            <BarGraph
              data={placementData["Mathematics"]}
              student_name={toTitleCase(givenname)}
            />
          )}
          <Box />
        </Stack>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Mathematical Ability:</strong> The student's ability to do
          Mathematics questions.
        </Typography>
        <br />
        <Typography
          variant="h5"
          component="div"
          sx={{ pageBreakBefore: "always" }}
        >
          3. Method & Strategy
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          {placementData != null && (
            <BarGraph
              data={placementData["Method & Strategy"]}
              student_name={toTitleCase(givenname)}
            />
          )}
          <Box />
        </Stack>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Method & Strategy:</strong> The use of various strategies to
          solve challenging problem questions. The more strategies with which
          students are familiar, the better their problem solving capabilities.
        </Typography>
        <br />
        <Typography
          variant="h5"
          component="div"
          sx={{ pageBreakBefore: "always" }}
        >
          4. General Knowledge & Reasoning
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          {placementData != null && (
            <BarGraph
              data={placementData["General Knowledge"]}
              student_name={toTitleCase(givenname)}
            />
          )}
          <Box />
        </Stack>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Common Knowledge:</strong> The extent of knowledge based on
          facts relating to daily life.
        </Typography>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Reasoning:</strong> The ability of a student to use logic and
          reason to make conclusions.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" component="div">
          5. Speed & Accuracy
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          {placementData != null && (
            <BarGraph
              data={placementData["Speed & Accuracy"]}
              student_name={toTitleCase(givenname)}
            />
          )}
          <Box />
        </Stack>
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Speed & Accuracy:</strong> The ability of a student to perform
          basic calculations and process questions quickly as well as
          accurately. Questions in Speed & Accuracy involve simple calculation
          questions or short problems that typically do not require many steps
          to solve. The questions may involve basic but not in-depth conceptual
          knowledge.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" component="div">
          6. Perseverance
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box />
          {placementData != null && (
            <BarGraph
              data={placementData["Focus & Concentration"]}
              student_name={toTitleCase(givenname)}
            />
          )}
          <Box />
        </Stack>
        {/* <Typography variant="body1" component="div">
          <strong>Focus:</strong> The ability of a student to maintain concentration on a task. Students with good focus will stay on one task before moving onto the next task.
        </Typography> */}
        <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
          <strong>Perseverance:</strong> The quality of continuing to work
          towards a goal despite facing obstacles or difficulties. Students who
          are high in perseverance have the ability to stay focused and
          committed to resolving challenging questions, even when they seem
          impossible.
        </Typography>
        <br />
        <Typography
          variant="h4"
          component="div"
          sx={{ pageBreakBefore: "always" }}
        >
          Guide to Understanding Your Result
        </Typography>
        <RadarReportInstructions />
        <ReadingReportInstructions />
        <Box sx={{ pageBreakAfter: "always" }} />
        <BarReportInstructions />
      </Box>
    </>
  );
};

export default PlacementReport;